import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Text } from 'yw-ui';

import BonusCard from '../BonusCard';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  TITLE: t('settings:additionally'),
});

const AdditionallySection = () => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  return (
    <div className={ styles.container }>
      <Text type='bold_20'>{LABELS.TITLE}</Text>
      <BonusCard />
    </div>
  );
};

export default AdditionallySection;
