import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';

import { dayjsObject, getDayjs, isValidDateObject } from '../../bi/utils/formatDate.ts';

import {
  IHotelSearchStore, IInitSearchParams,
} from '../../bi/models/hotelSearch/hotelSearchTypes.ts';
import { searchFormIsValid } from '@/app/bi/utils/hotelsSearch.ts';
import { IPrepareHotelStaticAutocompleteItem, searchHotelsApi } from '@/app/bi/api/searchHotelsApi.ts';

const initialState: IHotelSearchStore = {
  region: {
    label: '',
    selected: null,
    isRegion: false,
  },
  travellersCount: 1, // кол-во гостей
  adult: 1, // тип размещения
  bedType: 1, // тип кровати
  checkin: getDayjs().add(1, 'd').startOf('d'),
  checkout: getDayjs().clone().add(2, 'd'),
  checkinMinDate: getDayjs().startOf('d'),
  checkoutMinDate: getDayjs().startOf('d').add(1, 'd'),
  customCheckin: null,
  customCheckout: null,
  isValid: false,
  roomCount: 1,
};

const parseTime = (string: string) => {
  const [hour, minute] = string.split(':');

  return { hour: Number(hour), minute: Number(minute) };
};

const createNewState = (
  startDate?: string | Dayjs,
  endDate?: string | Dayjs,
  startTime?: string | null,
  endTime?: string | null,
) => {
  let currentDate = getDayjs().add(1, 'd').startOf('d');
  let checkoutDefaultDate = currentDate.clone().add(2, 'd');

  let preparedCheckin = null;
  let preparedCheckout = null;

  if (startDate) {
    currentDate = typeof startDate === 'string' ? dayjsObject(startDate).startOf('d') : startDate;
  }

  if (endDate) {
    checkoutDefaultDate = typeof endDate === 'string' ? dayjsObject(endDate).startOf('d') : endDate;
  }

  if (startTime) {
    const parseTimeStartObj = parseTime(startTime as string);
    preparedCheckin = currentDate.clone().add(parseTimeStartObj.hour, 'h').add(parseTimeStartObj.minute, 'minute');
  }

  if (endTime) {
    const parseTimeEndObj = parseTime(endTime as string);
    preparedCheckout = checkoutDefaultDate.clone().add(parseTimeEndObj.hour, 'h').add(parseTimeEndObj.minute, 'minute');
  }

  return {
    checkin: currentDate,
    checkout: checkoutDefaultDate,
    customCheckin: preparedCheckin,
    customCheckout: preparedCheckout,
  };
};

export const searchHotelsSlice = createSlice({
  name: 'searchHotels',
  initialState,
  reducers: {
    initSearch(state, action: PayloadAction<IInitSearchParams>) {
      const {
        hotelId,
        checkInDate,
        checkOutDate,
        travellersCount,
        regionName,
        guestsCount,
        checkInTime,
        checkOutTime,
        roomCount,
      } = action.payload;

      // @ts-ignore
      state.region = {
        label: regionName || state.region.label,
        selected: {
          id: hotelId,
          name: regionName || state.region.label,
          parentName: '',
          isRegion: false,
        },
      };

      const {
        checkin,
        checkout,
        customCheckin,
        customCheckout,
      } = createNewState(checkInDate, checkOutDate, checkInTime, checkOutTime);

      state.checkin = checkin;
      state.checkout = checkout;
      state.customCheckin = customCheckin;
      state.customCheckout = customCheckout;
      state.travellersCount = travellersCount;
      state.adult = guestsCount;
      state.roomCount = roomCount;

      state.isValid = searchFormIsValid({
        region: state.region,
        checkin: state.checkin,
        checkout: state.checkout,
        customCheckin: state.customCheckin,
        customCheckout: state.customCheckout,
      });
    },
    setCheckinDate(state, action: PayloadAction<Dayjs | null>) {
      state.checkoutMinDate = action.payload ? action.payload.clone().add(1, 'd') : null;
      state.checkin = action.payload ? action.payload.clone().set('hour', 0).set('minute', 0) : null;

      if (state.checkin && state.checkin.isSameOrAfter(state.checkout, 'day')) {
        state.customCheckout = state.customCheckout
          ? state.checkin.clone().add(1, 'd')
            .set('hour', state.customCheckout.get('hour'))
            .set('minute', state.customCheckout.get('minute'))
          : null;
        state.checkout = state.checkin.clone().add(1, 'd');
      }

      state.isValid = searchFormIsValid({
        region: state.region,
        checkin: state.checkin,
        checkout: state.checkout,
        customCheckin: state.customCheckin,
        customCheckout: state.customCheckout,
      });
    },
    setCheckoutDate(state, action: PayloadAction<Dayjs | null>) {
      state.checkout = action.payload
        ? action.payload.clone().set('hour', 0).set('minute', 0)
        : null;

      state.isValid = searchFormIsValid({
        region: state.region,
        checkin: state.checkin,
        checkout: state.checkout,
        customCheckin: state.customCheckin,
        customCheckout: state.customCheckout,
      });
    },
    setCustomCheckinDate(state, action: PayloadAction<Dayjs | null>) {
      state.customCheckin = (action.payload && isValidDateObject(action.payload))
        ? action.payload.clone()
        : action.payload;
    },
    setCustomCheckoutDate(state, action: PayloadAction<Dayjs | null>) {
      state.customCheckout = (action.payload && isValidDateObject(action.payload))
        ? action.payload.clone()
        : action.payload;
    },
    setRegion(state, action: PayloadAction<IPrepareHotelStaticAutocompleteItem>) {
      const { name, isRegion } = action.payload;

      state.region = {
        label: name,
        selected: action.payload,
        isRegion,
      };
      state.isValid = searchFormIsValid({
        region: state.region,
        checkin: state.checkin,
        checkout: state.checkout,
        customCheckin: state.customCheckin,
        customCheckout: state.customCheckout,
      });
    },
    updateLabel(state, action: PayloadAction<string>) {
      state.region.label = action.payload;
    },
    setAdult(state, action: PayloadAction<number>) {
      state.adult = action.payload;
    },
    setTravellersCount(state, action: PayloadAction<number>) {
      state.travellersCount = action.payload;
      state.adult = action.payload === 1 ? 1 : state.adult;
    },
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      searchHotelsApi.endpoints.getSearchRegionBySearchId.matchFulfilled,
      (state, { payload }) => {
        const {
          checkInDate,
          checkOutDate,
          checkInTime,
          checkOutTime,
          guests,
          regionId,
          roomCount,
        } = payload.searchProviderRequest;

        const {
          checkin,
          checkout,
          customCheckin,
          customCheckout,
        } = createNewState(checkInDate, checkOutDate, checkInTime, checkOutTime);

        state.travellersCount = guests;
        state.region = {
          label: payload.regionName,
          selected: {
            id: regionId,
            name: payload.regionName,
            parentName: '',
            isRegion: true,
          },
          isRegion: true,
        };
        state.checkin = checkin;
        state.checkout = checkout;
        state.customCheckin = customCheckin;
        state.customCheckout = customCheckout;
        state.adult = roomCount;

        state.isValid = searchFormIsValid({
          region: state.region,
          checkin: state.checkin,
          checkout: state.checkout,
          customCheckin: state.customCheckin,
          customCheckout: state.customCheckout,
        });
      },
    );
  },
});

export default searchHotelsSlice.reducer;
