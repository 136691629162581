import React from 'react';
import { Text } from 'yw-ui';

import splitWithoutRemovingSeparator from '../../../../../bi/utils/splitWithoutRemovingSeparator.js';

import styles from './styles.module.scss';

const renderSuggestionTitle = (title: string) => (
  <Text type='SEMIBOLD_16'>{ title }</Text>
);

const renderSuggestionItem = (name: string, region: string) => (
  <div className={ styles.item }>
    <Text className={ styles.name }>{ name }</Text>
    <Text className={ styles.hint }>{ region }</Text>
  </div>
);

const highlightWordStart = (string: string, query: string) => {
  const parts = splitWithoutRemovingSeparator(string, query, true);

  return [
    <Text
      key={ Math.random() * Math.random() }
      type='SEMIBOLD_16'
      className={ styles.highlight }
    >
      { parts[0] }
    </Text>,
    ...parts.slice(1),
  ];
};

interface HotelSuggestionProps {
  title?: string,
  name?: string,
  parentName?: string,
  query: string;
  isNested: boolean;
}

const HotelSuggestion = ({
  title = '',
  name = '',
  parentName = '',
  query,
  isNested,
}: HotelSuggestionProps) => {
  if (title && isNested) return renderSuggestionTitle(title);

  if (!query) return renderSuggestionItem(name, parentName);

  const queryWords = splitWithoutRemovingSeparator(query);
  const nameWords = splitWithoutRemovingSeparator(name);

  // @ts-ignore
  const highlightedName = [];
  nameWords.forEach((nameWord) => {
    let match = null;
    queryWords.some((queryWord) => {
      if (nameWord.toLowerCase().startsWith(queryWord.toLowerCase())) {
        match = queryWord;

        return true;
      }

      return false;
    });

    const highlightedNameWord = match
      ? highlightWordStart(nameWord, match)
      : nameWord;

    highlightedName.push(highlightedNameWord);
  });

  // @ts-ignore
  return renderSuggestionItem(highlightedName, parentName);
};

export { HotelSuggestion };
