import React from 'react';
import { Text } from 'yw-ui';

import styles from './styles.module.scss';

interface AirlineSearchMenuSuggestItemProps {
  city: string | string[];
  name: string | string[];
  code: string | string[];
}

const AirlineSearchMenuSuggestItem = ({
  city,
  name,
  code,
}: AirlineSearchMenuSuggestItemProps) => (
  <div className={ styles.suggest }>
    <div className={ styles.city }>
      <Text>{ city }</Text>
      <Text>{ code }</Text>
    </div>
    <Text type='NORMAL_12'>{ name }</Text>
  </div>
);

export { AirlineSearchMenuSuggestItem };
