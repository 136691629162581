import React, { useState } from 'react';
import { Button, Dialog, IconButton, Input, RadioButton, Suggest, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { FieldLabel } from '@/app/components/FieldLabel';
import { AviaPolicies } from '@/app/pages/TravelPolicy/components/BlockPoliciesAirline/components/AviaPolicies';

import { INIT_ROUTE_AIRLINE, INIT_TRAVEL_POLICIES } from '@/app/bi/constants/travelPolicy.ts';

import {
  ERestrictionActionAirline,
  IRoutes,
} from '@/app/bi/models/travelPolicies.ts';

import styles from './index.module.scss';

const LABELS = {
  ADD: getText('common:add'),
  SAVE: getText('common:save'),
  UNDO: getText('common:undo'),
  BUY_HOTELS_TURKEY_APPROVAL: getText('settings:travelPolicies.buyHotelsTurkeyApproval'),
  EXCEPTIONS: getText('settings:travelPolicies.exceptions'),
  TO: getText('settings:travelPolicies.to'),
  FROM: getText('settings:travelPolicies.from'),
  CITY: getText('settings:travelPolicies.city'),
  COUNTRY: getText('settings:travelPolicies.country'),
  NEXT: getText('settings:travelPolicies.next'),
  ADD_SPECIAL_CONDITIONS_ACCOMMODATION: getText('settings:travelPolicies.addSpecialConditionsAccommodation'),
  SPECIFIED_COUNTRIES: getText('settings:travelPolicies.specifiedCountries'),
  SPECIFIED_CITY: getText('settings:travelPolicies.specifiedCities'),
  ONLY_SPECIFIED_DESTINATIONS: getText('settings:travelPolicies.onlySpecifiedDestinations'),
  PURCHASE_WITHOUT_APPROVAL_ALL: getText('settings:travelPolicies.purchaseWithoutApprovalAll'),
  LONGER: getText('settings:travelPolicies.longer'),
  HOURS: getText('settings:travelPolicies.hours'),
  BUY_TICKET_WITHOUT_APPROVAL: getText('settings:travelPolicies.buyTicketsWithoutApproval'),
  AND_BACK: getText('settings:travelPolicies.andBack'),
};

interface DialogExceptionsAviaProps {
  isShow: boolean;
  isCreate: boolean;
  onCloseDialog(): void;
}

export const DialogExceptionsAvia = ({
  isShow,
  isCreate,
  onCloseDialog,
}: DialogExceptionsAviaProps) => {
  const [
    restrictionOnDirection,
    setRestrictionOnDirection,
  ] = useState(INIT_TRAVEL_POLICIES.airline.restrictionOnDirections);

  const [
    switchView,
    setSwitchView,
  ] = useState(false);

  const {
    onlySpecifiedDestinations,
    onlyTurkey,
    routes,
  } = restrictionOnDirection;
  const actionButton = switchView ? LABELS.SAVE : LABELS.NEXT;

  const handleAddRoute = () => {
    setRestrictionOnDirection({
      ...restrictionOnDirection,
      routes: [...routes, INIT_ROUTE_AIRLINE],
    });
  };

  const handleDeleteRoute = (indexRoute: number) => {
    const newRoutes = routes.filter((
      _,
      index,
    ) => index !== indexRoute);

    setRestrictionOnDirection({
      ...restrictionOnDirection,
      routes: newRoutes,
    });
  };

  const handleRestrictionChange = (action: ERestrictionActionAirline) => {
    setRestrictionOnDirection(({
      ...restrictionOnDirection,
      onlyTurkey: action === ERestrictionActionAirline.OnlyTurkey ? true : !onlyTurkey,
      onlySpecifiedDestinations: action === ERestrictionActionAirline.OnlySpecifiedDestinations ? true : !onlySpecifiedDestinations,
    }));
  };

  const handleOnlyTurkey = () => handleRestrictionChange(ERestrictionActionAirline.OnlyTurkey);

  const handleOnlySpecifiedDestinations = () => handleRestrictionChange(
    ERestrictionActionAirline.OnlySpecifiedDestinations,
  );

  const handleChange = () => {

  };

  const handleSelect = () => {

  };

  const handleActionButton = () => {
    if (!switchView) {
      setSwitchView(true);
    } else {
      onCloseDialog();
    }
  };

  const renderItemRoute = ({ from, to }: IRoutes, index: number) => (
    <div key={ `routes_${index}` } className={ styles.container_field_routes }>
      <div className={ styles.item_route }>
        <FieldLabel text={ LABELS.FROM } isRequired/>
        <Suggest
          preventTab={ false }
          theme='border'
          withLabel={ false }
          value={ from.label }
          items={ [
            {
              value: 1,
              label: 'Станбул',
            },
          ] }
          onChange={ handleChange }
          onSelect={ handleSelect }
          placeholder={ LABELS.FROM }
        />
      </div>
      <div className={ styles.item_route }>
        <FieldLabel text={ LABELS.TO } isRequired/>
        <Suggest
          preventTab={ false }
          theme='border'
          withLabel={ false }
          value={ to.label }
          items={ [
            {
              value: 1,
              label: 'Москва',
            },
          ] }
          onChange={ handleChange }
          onSelect={ handleSelect }
          placeholder={ LABELS.TO }
        />
      </div>
      <Text className={ styles.accompanying_text } type='NORMAL_14'>
        {LABELS.AND_BACK}
        {routes.length > 1 && (
          <IconButton
            iconType='closeOff'
            iconColor='gray4'
            size={ 16 }
            onClick={ () => handleDeleteRoute(index) }
          />
        )}
      </Text>
    </div>
  );

  const renderContentSelectElem = () => onlySpecifiedDestinations && !switchView && (
    <div className={ styles.routes }>
      {routes?.map((route, index) => (
        renderItemRoute(route, index)
      ))}
      <IconButton
        iconType='add'
        iconColor='blue1'
        disabled={ routes.length >= 10 }
        onClick={ handleAddRoute }
      >
        {LABELS.ADD}
      </IconButton>
    </div>
  );

  const renderContent = () => (switchView ? (
    <>
      <Text type='NORMAL_16'>
        {LABELS.BUY_TICKET_WITHOUT_APPROVAL}
      </Text>
      <div>
        <AviaPolicies isCreate={ isCreate } />
      </div>
    </>
  ) : (
    <>
      <Text type='NORMAL_16'>
        {LABELS.PURCHASE_WITHOUT_APPROVAL_ALL}
      </Text>
      <div className={ styles.content_container } >
        <RadioButton checked={ onlyTurkey } onChange={ handleOnlyTurkey }>
          <div className={ styles.time_limit }>
            <Text type='NORMAL_16'>{LABELS.LONGER}</Text>
            <Input
              value={ '50' }
              className={ styles.input }
              onChange={ () => {} }
            />
            <Text type='NORMAL_16'>{LABELS.HOURS}</Text>
          </div>
        </RadioButton>
        <RadioButton checked={ onlySpecifiedDestinations } onChange={ handleOnlySpecifiedDestinations }>
          {LABELS.ONLY_SPECIFIED_DESTINATIONS}
        </RadioButton>
      </div>
    </>
  ));

  return (
    <Dialog
      className={ styles.dialog }
      showClosing
      show={ isShow }
      onChange={ onCloseDialog }
    >
      <Text type='bold_20'>{LABELS.EXCEPTIONS}</Text>
      {renderContent()}
      {renderContentSelectElem()}
      <div className={ styles.buttons_interactive } onClick={ handleActionButton }>
        <Button type='secondary'>
          {actionButton}
        </Button>
        <Button type='text' onClick={ onCloseDialog }>
          {LABELS.UNDO}
        </Button>
      </div>
    </Dialog>
  );
};
