import React, { useMemo } from 'react';
import { MultiSelect } from 'yw-ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { TFunction } from 'i18next';

import styles from '../../index.module.scss';

const createLabels = (t: TFunction) => ({
  FLIGHT_NUMBERS: t('air:result.filters.flightNumbers'),
});

interface FlightsNumbersProps {
  flightNumbers: string[],
  values: string[],
  onChange(checkedCities: string[]): void;
}

const FlightsNumbersFilter = ({
  flightNumbers,
  values,
  onChange,
}: FlightsNumbersProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const handleChange = (value: string[]) => onChange(value);

  const list = flightNumbers.map((item) => ({
    label: item,
    value: item,
  }));

  return (
    <div className={ clsx([styles.item, styles.flight_numbers]) }>
      <MultiSelect
        withLabel
        className={ styles.multi_select_wrap }
        placeholder={ LABELS.FLIGHT_NUMBERS }
        list={ list }
        values={ values }
        search
        searchPlaceholder={ LABELS.FLIGHT_NUMBERS }
        onChange={ handleChange }
      />
    </div>
  );
};

export { FlightsNumbersFilter };
