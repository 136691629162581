import React, { useState } from 'react';
import { Button, Dialog, IconButton, RadioButton, Suggest, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { FieldLabel } from '@/app/components/FieldLabel';
import {
  HotelPolicies,
} from '@/app/pages/TravelPolicy/components/BlockPoliciesHotel/components/HotelPolicies/HotelPolicies.tsx';

import { INIT_COUNTRIES_HOTEL, INIT_TRAVEL_POLICIES } from '@/app/bi/constants/travelPolicy.ts';

import {
  ERestrictionActionHotel,
  IRestriction,
} from '@/app/bi/models/travelPolicies.ts';

import styles from './index.module.scss';

const LABELS = {
  ADD: getText('common:add'),
  SAVE: getText('common:save'),
  UNDO: getText('common:undo'),
  BUY_HOTELS_TURKEY_APPROVAL: getText('settings:travelPolicies.buyHotelsTurkeyApproval'),
  EXCEPTIONS: getText('settings:travelPolicies.exceptions'),
  FROM: getText('settings:travelPolicies.from'),
  CITY: getText('settings:travelPolicies.city'),
  COUNTRY: getText('settings:travelPolicies.country'),
  NEXT: getText('settings:travelPolicies.next'),
  ADD_SPECIAL_CONDITIONS_ACCOMMODATION: getText('settings:travelPolicies.addSpecialConditionsAccommodation'),
  SPECIFIED_COUNTRIES: getText('settings:travelPolicies.specifiedCountries'),
  SPECIFIED_CITY: getText('settings:travelPolicies.specifiedCities'),
};

interface DialogExceptionsHotelProps {
  isShow: boolean;
  isCreate: boolean;
  onCloseDialog(): void;
}

export const DialogExceptionsHotel = ({
  isShow,
  isCreate,
  onCloseDialog,
}: DialogExceptionsHotelProps) => {
  const [
    restrictionOnDirection,
    setRestrictionOnDirection,
  ] = useState(INIT_TRAVEL_POLICIES.hotel.restrictionOnDirections);

  const [
    switchView,
    setSwitchView,
  ] = useState(false);

  const {
    onlySpecifiedCities,
    selectCountry,
    cities,
    countries,
  } = restrictionOnDirection;
  const actionButton = switchView ? LABELS.SAVE : LABELS.NEXT;

  const handleAddCity = () => setRestrictionOnDirection({
    ...restrictionOnDirection,
    cities: [...cities, INIT_COUNTRIES_HOTEL],
  });

  const handleAddCountries = () => setRestrictionOnDirection({
    ...restrictionOnDirection,
    countries: [...countries, INIT_COUNTRIES_HOTEL],
  });

  const handleDeleteCity = (indexRoute: number) => {
    const newRoutes = cities.filter((
      _,
      index,
    ) => index !== indexRoute);

    setRestrictionOnDirection({
      ...restrictionOnDirection,
      cities: newRoutes,
    });
  };

  const handleRestrictionChange = (action: ERestrictionActionHotel) => {
    setRestrictionOnDirection(({
      ...restrictionOnDirection,
      selectCountry: action === ERestrictionActionHotel.SelectCountry ? true : !selectCountry,
      onlySpecifiedCities: action === ERestrictionActionHotel.OnlySpecifiedCities ? true : !onlySpecifiedCities,
    }));
  };

  const handleSelectCountry = () => handleRestrictionChange(ERestrictionActionHotel.SelectCountry);

  const handleOnlySpecifiedDestinations = () => handleRestrictionChange(
    ERestrictionActionHotel.OnlySpecifiedCities,
  );

  const handleChange = () => {

  };

  const handleSelect = () => {

  };

  const handleActionButton = () => {
    if (!switchView) {
      setSwitchView(true);
    } else {
      onCloseDialog();
    }
  };

  const renderItem = ({ name }: IRestriction, index: number) => (
    <div key={ `routes_${index}` } className={ styles.container_cities }>
      <div className={ styles.item_city }>
        <FieldLabel text={ selectCountry ? LABELS.COUNTRY : LABELS.CITY } isRequired/>
        <Suggest
          preventTab={ false }
          theme='border'
          withLabel={ false }
          value={ name }
          items={ [
            {
              value: 1,
              label: 'Станбул',
            },
          ] }
          onChange={ handleChange }
          onSelect={ handleSelect }
          placeholder={ LABELS.FROM }
        />
      </div>
      {cities.length > 1 && (
        <div className={ styles.button_close }>
          <IconButton iconType='closeOff' size={ 16 } onClick={ () => handleDeleteCity(index) } />
        </div>
      )}
    </div>
  );

  const renderContentSelectElem = () => {
    const array = selectCountry ? countries : cities;
    const switchHandler = selectCountry ? handleAddCountries : handleAddCity;

    return (
      <div className={ styles.cities }>
        {array?.map((city, index) => (
          renderItem(city, index)
        ))}
        <IconButton
          iconType='add'
          iconColor='blue1'
          disabled={ cities.length >= 10 }
          onClick={ switchHandler }
        >
          {LABELS.ADD}
        </IconButton>
      </div>
    );
  };

  const renderContent = () => (switchView ? (
    <>
      <Text type='NORMAL_16'>
        {LABELS.BUY_HOTELS_TURKEY_APPROVAL}
      </Text>
      <HotelPolicies isCreate={ isCreate } />
    </>
  ) : (
    <>
      <Text type='NORMAL_16'>
        {LABELS.ADD_SPECIAL_CONDITIONS_ACCOMMODATION}
      </Text>
      <div className={ styles.content_container } >
        <RadioButton checked={ selectCountry } onChange={ handleSelectCountry }>
          {LABELS.SPECIFIED_COUNTRIES}
        </RadioButton>
        <RadioButton checked={ onlySpecifiedCities } onChange={ handleOnlySpecifiedDestinations }>
          {LABELS.SPECIFIED_CITY}
        </RadioButton>
      </div>
      {renderContentSelectElem()}
    </>
  ));

  return (
    <Dialog
      className={ styles.dialog }
      showClosing
      show={ isShow }
      onChange={ onCloseDialog }
    >
      <Text type='bold_20'>{LABELS.EXCEPTIONS}</Text>
      {renderContent()}
      <div className={ styles.buttons_interactive }>
        <Button type='secondary' onClick={ handleActionButton }>
          {actionButton}
        </Button>
        <Button type='text' onClick={ onCloseDialog }>
          {LABELS.UNDO}
        </Button>
      </div>
    </Dialog>
  );
};
