import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Text, LinkButton } from 'yw-ui';

import { getText } from '@/i18n';

import { authApi } from '@/app/bi/api/authApi.ts';

import { LoginDialogWrapper } from '../../components/LoginDialogWrapper';

import { isValidateEmail } from '@/app/bi/utils/login.ts';

import ROUTES from '@/app/bi/constants/routes.ts';

import styles from './index.module.scss';

const getValidationRestore = (email: string) => {
  let validEmail = '';
  if (email !== '') {
    validEmail = isValidateEmail(email) ? '' : LABELS.EMAIL_ERROR;
  } else {
    validEmail = LABELS.EMAIL_EMPTY_ERROR;
  }

  const isValid = validEmail === '';

  return { validEmail, isValid };
};

const LABELS = {
  RESTORE_PASSWORD: getText('auth:restorePassword'),
  RESET: getText('auth:reset'),
  EMAIL: getText('auth:email'),
  NOT_REGISTERED: getText('auth:notRegistered'),
  RESET_PASSWORD_ERROR: getText('auth:resetPasswordError'),
  EMAIL_ERROR: getText('auth:emailError'),
  EMAIL_EMPTY_ERROR: getText('auth:emailEmptyError'),
};

const RestorePassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [validation, setValidation] = useState({
    email: '',
  });

  const [restorePassword, { isLoading }] = authApi.useRestorePasswordMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const { validEmail, isValid } = getValidationRestore(email);
    setValidation({ email: validEmail });

    if (isValid) {
      try {
        await restorePassword({ email }).unwrap();

        navigate(ROUTES.CHECK_EMAIL);
      } catch (err) {
        setValidation({
          email: '',
        });
      }
    }
  };

  const errorHtml = validation.email && (
    <Text type='NORMAL_12' className={ styles['error-message'] }>
      {validation.email}
    </Text>
  );

  const renderContent = () => (
    <LoginDialogWrapper title={ LABELS.RESTORE_PASSWORD }>
      <form
        className={ styles.form }
        onSubmit={ handleSubmit }
      >
        <div className={ styles.row }>
          <div className={ styles.group }>
            <Text className={ styles.label } type='NORMAL_14'>
              { LABELS.EMAIL }
            </Text>
            <Input
              type='email'
              value={ email }
              placeholder={ LABELS.EMAIL }
              onChange={ (value) => setEmail(value) }
            />
            { errorHtml }
          </div>
        </div>
        <div className={ styles.row }>
          <Button
            className={ styles.submit }
            formType='submit'
            type='primary'
            loading={ isLoading }
            disabled={ isLoading }
          >
            { LABELS.RESET }
          </Button>
          <div className={ styles['restore-password'] }>
            <LinkButton color='light-blue-4' type='NORMAL_14'>
              { LABELS.NOT_REGISTERED }
            </LinkButton>
          </div>
        </div>
      </form>
    </LoginDialogWrapper>
  );

  return renderContent();
};

export { RestorePassword };
