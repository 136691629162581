import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Datepicker, IconButton, Text } from 'yw-ui';
import { TextType } from 'yw-ui/src/components/Text/types.ts';

import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { currentLng } from '@/i18n';

import { useAppDispatch } from '@/app/store/hooks/redux.ts';
import { searchAirlineSlice } from '@/app/store/redusers/SearchAirlineSlice.ts';
import { useLazyAirlineAutocompleteQuery } from '@/app/bi/api/searchAirlineApi.ts';

import { AirlineSearchMenuSuggestItem } from './components/SuggestItem';
import { SelectedRoutes } from './components/SelectedRoutes';

import splitWithoutRemovingSeparator from '../../../../../bi/utils/splitWithoutRemovingSeparator';
import { isSameDate } from '@/app/bi/utils/formatDate.ts';
import { debounce } from '@/app/bi/utils/debounce.ts';
import { formatDefaultSuggests } from '@/app/bi/utils/airlineSearch.ts';

import DIRECTIONS from '../../../../../bi/constants/directions';
import { THROTTLE_DELAY_AUTOCOMPLETE } from '@/app/bi/constants/airlineSearch.ts';

import { EMenuType, OptsType } from '@/app/bi/models/airlineTypes.ts';
import {
  ISuggestion,
  IRoutes,
} from '@/app/bi/models/airlineSearchTypes.ts';

import styles from './styles.module.scss';

const createLabels = (t: TFunction) => ({
  DATE_TO: t('components:menu.air.row.back'),
  CITY_FROM: t('components:menu.air.row.cityFrom'),
  CITY_TO: t('components:menu.air.row.cityTo'),
  DATE_FROM: t('components:menu.air.row.to'),
});

const highlightWordStart = (string: string, query: string, typeText: TextType = 'SEMIBOLD_16') => {
  const parts = splitWithoutRemovingSeparator(string, query, true);
  const keyElem = Math.random() * Math.random();

  return [
    <Text
      type={ typeText }
      className={ styles.highlight }
      key={ keyElem }
    >
      {parts[0]}
    </Text>,
    ...parts.slice(1),
  ];
};

const getWordSuggest = (name: string, query: string[], typeText?: TextType) => splitWithoutRemovingSeparator(name)
  // @ts-ignore
  .reduce((acc, word) => {
    const foundQueryWord = query.find((queryWord) => word.toLowerCase().startsWith(queryWord.toLowerCase()));

    if (foundQueryWord) {
      return [...acc, ...highlightWordStart(word, foundQueryWord, typeText)];
    }

    return [...acc, word];
  }, []);

interface AirlineSearchRouteProps {
  ind: number;
  route: IRoutes;
  typeMenu?: EMenuType;
  isComplex: boolean;
  theme?: 'dark' | 'light';
  isChangeAirTrip?: boolean;
  numberOfRoutes?: number;
}

const AirlineSearchRoute = ({
  ind,
  route,
  isComplex,
  typeMenu = EMenuType.MainMenu,
  theme = 'light',
  isChangeAirTrip = false,
  numberOfRoutes = 0,
}: AirlineSearchRouteProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const {
    removeSearchRoute,
    selectSuggest,
    setFlipCity,
    setThereDate,
    setBackDate,
    updateLabel,
  } = searchAirlineSlice.actions;
  const dispatch = useAppDispatch();

  const {
    from,
    to,
    date,
    dateBack,
    minDate,
  } = route;

  const suggestFromRef = useRef<HTMLInputElement | null>(null);
  const suggestToRef = useRef<HTMLInputElement | null>(null);
  const suggestFormRef = useRef<HTMLDivElement | null>(null);

  const [dateOpen, setDateOpen] = useState(false);
  const [dateBackOpen, setDateBackOpen] = useState(false);

  const [
    triggerFrom,
    { data: dataFrom = [], isFetching: isFetchingFrom },
  ] = useLazyAirlineAutocompleteQuery();

  const [
    triggerTo,
    { data: dataTo, isFetching: isFetchingTo },
  ] = useLazyAirlineAutocompleteQuery();

  const throttleFrom = useCallback(
    debounce(triggerFrom, THROTTLE_DELAY_AUTOCOMPLETE),
    [triggerFrom],
  );

  const throttleTo = useCallback(
    debounce(triggerTo, THROTTLE_DELAY_AUTOCOMPLETE),
    [triggerFrom],
  );

  const rowStyle = [styles.row];
  const inputStyles = [styles.input];

  const handleFlipFromTo = (key: string) => {
    dispatch(setFlipCity(parseInt(key, 10)));
  };

  const handleRemoveRoute = (routeIndex: number) => {
    dispatch(removeSearchRoute(routeIndex));
  };

  const getSuggestFrom = (field: string, key: number, query: string) => {
    // @ts-ignore
    dispatch(updateLabel({ idx: key, field, query }));
    throttleFrom({ locale: currentLng, query });
  };

  const getSuggestTo = (field: string, key: number, query: string) => {
    // @ts-ignore
    dispatch(updateLabel({ idx: key, field, query }));
    throttleTo({ locale: currentLng, query });
  };

  const handleOpenDate = (value: boolean) => setDateOpen(value);

  const handleOpenDateBack = (value: boolean) => setDateBackOpen(value);

  const handleSuggestSelected = (
    field: string,
    suggest: ISuggestion,
  ) => {
    if (suggest !== null) {
      // @ts-ignore
      const previousSelected = route[field as keyof IRoutes]?.label;
      const newSelected = `${suggest.city} (${suggest.code})`;
      // @ts-ignore
      dispatch(selectSuggest({ ind, field, value: suggest }));

      if (field === DIRECTIONS.FROM && newSelected !== previousSelected) {
        suggestToRef?.current?.focus();
      }
      if (field === DIRECTIONS.TO && newSelected !== previousSelected) {
        handleOpenDate(true);
      }
    }
  };

  const handleSuggestFromSelected = (suggest: ISuggestion) => {
    if (to.selected?.code !== suggest.code) {
      handleSuggestSelected(DIRECTIONS.FROM, suggest);
    }
  };

  const handleSuggestToSelected = (
    suggest: ISuggestion,
  ) => {
    if (from.selected?.code !== suggest.code) {
      handleSuggestSelected(DIRECTIONS.TO, suggest);
    }
  };

  const handleChangeFromDate = (index: number, value: Dayjs | null) => {
    dispatch(setThereDate({ value, index }));

    if (!isSameDate(value, route.date)) {
      handleOpenDateBack(true);
    }
  };

  const handleDatePickerBlur = () => {
    if (!route || !route.from.selected || !route.to.selected) {
      suggestFormRef?.current?.focus();
    }
  };

  const handleChangeBackDate = (index: number, value: Dayjs) => {
    dispatch(setBackDate({ value, index }));
  };

  const renderSuggestion = ({ city, name, code }: ISuggestion, query: string) => {
    if (!query) {
      return <AirlineSearchMenuSuggestItem city={ city } name={ name } code={ code } />;
    }

    const querySplit = splitWithoutRemovingSeparator(query);
    const newCity = getWordSuggest(city, querySplit);
    const newName = getWordSuggest(name, querySplit, 'SEMIBOLD_12');
    const newCode = getWordSuggest(code, querySplit);

    return <AirlineSearchMenuSuggestItem city={ newCity } name={ newName } code={ newCode } />;
  };

  const renderDateBack = () => (
    <div className={ styles.date }>
      <Datepicker
        closeOnTabOut
        withLabel
        isCleansing
        theme={ theme }
        open={ dateBackOpen }
        placeholder={ LABELS.DATE_TO }
        inputTheme='open'
        value={ dateBack }
        min={ date || minDate }
        onChange={ (value: Dayjs) => handleChangeBackDate(ind, value) }
        wrapperClassName={ styles.wrapper }
        inputClassName={ inputStyles.join(' ') }
        onBlur={ handleDatePickerBlur }
        isDuration
        durationDates={ [date as Dayjs, dateBack as Dayjs | null] }
      />
    </div>
  );

  const renderRemoveRote = () => {
    const clearBtnHtml = ind !== 0 && (
      <IconButton
        iconType='closeOff'
        size={ 16 }
        iconColor='blue1'
        onClick={ () => handleRemoveRoute(ind) }
      />
    );

    return <div className={ styles.clear }>{clearBtnHtml}</div>;
  };

  const renderRemoveRoteChangeAirTrip = () => {
    const iconHtml = (
      <IconButton
        iconType='closeOn'
        iconColor='blue1'
        onClick={ () => handleRemoveRoute(ind) }
      />
    );

    if (numberOfRoutes !== 1) {
      return <div className={ styles.clear_change }>{iconHtml}</div>;
    }

    return null;
  };

  const removeRoteHtml = () => {
    if (isComplex && !isChangeAirTrip) {
      return renderRemoveRote();
    }

    if (isComplex && isChangeAirTrip) {
      return renderRemoveRoteChangeAirTrip();
    }

    return null;
  };

  const dateBackHtml = !isComplex && renderDateBack();

  if (theme) {
    rowStyle.push(styles[theme]);
  }

  if (ind !== 0) {
    rowStyle.push(styles.border);
  }

  if (isChangeAirTrip) {
    inputStyles.push(styles.input_change_air_trip);
  }

  const opts: OptsType = {
    from: {
      value: from.label,
      items: dataFrom?.length ? formatDefaultSuggests(dataFrom, 'from', route) : [],
      placeholder: LABELS.CITY_FROM,
      ref: suggestFromRef,
      loading: isFetchingFrom,
      onSelect: handleSuggestFromSelected,
      onChange: (query) => getSuggestFrom(DIRECTIONS.FROM, ind, query),
    },
    to: {
      value: to.label,
      items: dataTo?.length ? formatDefaultSuggests(dataTo, 'to', route) : [],
      placeholder: LABELS.CITY_TO,
      ref: suggestToRef,
      loading: isFetchingTo,
      onSelect: handleSuggestToSelected,
      onChange: (query) => getSuggestTo(DIRECTIONS.TO, ind, query),
    },
  };

  return (
    <div className={ rowStyle.join(' ') } ref={ suggestFormRef }>
      <SelectedRoutes
        opts={ opts }
        theme={ theme }
        typeMenu={ typeMenu }
        keyExtractor={ ({ code }) => code }
        nestedType='column'
        renderItem={ renderSuggestion }
        // @ts-ignore
        onRevert={ () => handleFlipFromTo(ind) }
      />
      <div className={ styles.date }>
        <Datepicker
          closeOnTabOut
          withLabel
          theme={ theme }
          open={ dateOpen }
          placeholder={ LABELS.DATE_FROM }
          inputTheme='open'
          value={ date }
          min={ minDate }
          onChange={ (value: Dayjs) => handleChangeFromDate(ind, value) }
          onBlur={ handleDatePickerBlur }
          wrapperClassName={ styles.wrapper }
          inputClassName={ inputStyles.join(' ') }
          isDuration
          durationDates={ [date as Dayjs, dateBack] }
        />
      </div>
      {dateBackHtml}
      <div className={ styles.clear_button }>
        {removeRoteHtml()}
      </div>
    </div>
  );
};

export { AirlineSearchRoute };
