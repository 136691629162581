import React from 'react';
import { Tooltip, Text, IconButton, Select } from 'yw-ui';
import MediaQuery from 'react-responsive';
import { changeLanguage, currentLng, getText } from '@/i18n';

import { ItemWrap } from '../ItemWrap';

import { ELanguages, languagesItems } from '@/i18n/locales/consts';

import { useLogoutMutation } from '@/app/bi/api/authApi.ts';

import { authSlice } from '@/app/store/redusers/AuthSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';

import { useSetLocaleMutation } from '@/app/bi/api/appApi.ts';
import { appSlice } from '@/app/store/redusers/AppSlice.ts';

import { ywApiPrivate } from '@/app/bi/api';

import styles from './index.module.scss';

const LABELS = {
  CART: getText('components:header.items.cart'),
  LOGOUT: getText('components:header.logout'),
};

const RightHeaderItems = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { logout } = authSlice.actions;
  const { resetStore } = appSlice.actions;
  const [setLocale, { isLoading }] = useSetLocaleMutation();
  const [logoutRes] = useLogoutMutation();

  const {
    headers: {
      cartItemsCount,
    },
  } = useAppSelector((state) => state.appSlice);

  const handleChangeLanguage = async (value: ELanguages) => {
    try {
      await setLocale(value).unwrap();
      changeLanguage(value);
    } catch (e) {}
  };

  const renderLocale = () => (
    <div className={ styles.item }>
      <MediaQuery minWidth={ 750 }>
        <Select
          theme='open-blue'
          className={ styles.select }
          items={ languagesItems }
          value={ currentLng }
          onChange={ handleChangeLanguage }
          loading={ isLoading }
        />
      </MediaQuery>
    </div>
  );

  const renderCart = () => {
    const label = (
      <MediaQuery minWidth={ 750 }>{LABELS.CART}</MediaQuery>
    );

    return (
      <div className={ styles.item }>
        <ItemWrap
          subItem
          count={ cartItemsCount }
          href='/cart'
          getIcon={ () => 'cart' }
          label={ label }
        />
      </div>
    );
  };

  const handleLogout = async () => {
    try {
      await logoutRes().unwrap();
    } finally {
      dispatch(resetStore());
      dispatch(logout());
      dispatch(ywApiPrivate.util.resetApiState());
    }
  };

  const renderContent = () => (
    <div className={ styles.content }>
      <Text type='NORMAL_14_130' className={ `${styles.label} ${styles.text}` }>
        {LABELS.LOGOUT}
      </Text>
      <Text className={ styles.text } type='SEMIBOLD_14'>
        {user?.email}
      </Text>
    </div>
  );

  return (
    <nav className={ styles.wrapper }>
      {renderLocale()}
      {renderCart()}
      <div className={ styles.with_border }>
        <Tooltip
          className={ styles.logout_wrapper }
          renderContent={ renderContent }
          position='bottom'
        >
          <IconButton
            className={ styles.text }
            onClick={ handleLogout }
            iconType='exit'
          >
            {LABELS.LOGOUT}
          </IconButton>
        </Tooltip>
      </div>
    </nav>
  );
};

export { RightHeaderItems };
