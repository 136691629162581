import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Text } from 'yw-ui';

import { TAB_INFO_SETTINGS } from '@/app/bi/constants/settings.ts';

import styles from './index.module.scss';

const Tabs = () => {
  const location = useLocation();

  const getTextColor = (path: string) => (location.pathname.includes(path) ? 'blue-1' : 'gray');

  const getTestWeight = (path: string) => (location.pathname.includes(path) ? 'bold_16' : 'NORMAL_16');

  const renderLink = () => TAB_INFO_SETTINGS.map(({ label, path }) => (
    <NavLink
      key={ path }
      to={ path }
      className={ ({ isActive }) => `${styles.tab} ${isActive ? styles.active : ''}` }
    >
      <Text
        type={ getTestWeight(path) }
        color={ getTextColor(path) }
        className={ styles.title }
      >
        {label}
      </Text>
    </NavLink>
  ));

  return (
    <div className={ styles.tabs }>
      {renderLink()}
    </div>
  );
};

export { Tabs };
