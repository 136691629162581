import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'yw-ui';

import { TFunction } from 'i18next';

import { HotelGallery } from '@/app/pages/HotelResult/components/Hotel/components/RoomGroup/components/HotelGallery';

import {
  IHotelSearchImageResponse,
  IHotelSearchRoomGroupResponse,
} from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  MORE_INFO: t('hotels:hotelResult.item.roomGroup.moreInfo'),
  MORE_ABOUT_OF_HOTEL: t('hotels:hotelResult.moreAboutOfHotel'),
});

interface DetailsProps {
  room: IHotelSearchRoomGroupResponse;
  allImageLoadFailed: boolean;
  Images: IHotelSearchImageResponse[];
  loadedImgs: IHotelSearchImageResponse[];
  details: boolean;
  onGallerySlideLeft(): void;
  onGallerySlideRight(): void;
  handleShowRoomDetails(): void;
}

const Details = ({
  room,
  allImageLoadFailed,
  Images,
  loadedImgs,
  details,
  onGallerySlideLeft,
  onGallerySlideRight,
  handleShowRoomDetails,
}: DetailsProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const { name, description } = room;

  return (
    <div className={ styles.details_wrapper }>
      <div className={ styles.header }>
        <Text type='bold_24'>{ name }</Text>
      </div>
      <div className={ styles.wrap_gallery }>
        <div className={ styles.gallery }>
          <HotelGallery
            autoPlay
            allImageLoadFailed={ allImageLoadFailed }
            Images={ Images }
            loadedImgs={ loadedImgs }
            details={ details }
            onGallerySlideLeft={ onGallerySlideLeft }
            onGallerySlideRight={ onGallerySlideRight }
            handleShowRoomDetails={ handleShowRoomDetails }
          />
        </div>
      </div>
      <div className={ styles.description }>
        <Text type='bold_16' color='gray-7'>{LABELS.MORE_INFO}</Text>
        { description && (
          <Text type='NORMAL_16_140'>
            <div dangerouslySetInnerHTML={ { __html: description } } />
          </Text>
        ) }
      </div>
    </div>
  );
};

export { Details };
