import React from 'react';
import { Icon, Text, Tooltip } from 'yw-ui';

import { getText } from '@/i18n';

import { TechnicalStopTooltip } from '@/app/components/AirlineTechnicalStop/components/Tooltip';
import { AirItemSegment } from '../AirItemSegment';

import { dateWithoutDayjs, formatDate } from '@/app/bi/utils/formatDate.ts';
import { secondsToLabel } from '@/app/bi/utils/time.ts';
import toDecline from '@/app/bi/utils/toDecline.ts';
import {
  getMappedSegments,
  getTerminals,
  isArrivalChanged,
  isDepartureChanged,
  segmentsArrivalCity,
} from '@/app/pages/Cart/utils';

import { TRANSFER } from '@/app/bi/constants/airline.ts';
import { DATE_FORMATS, PATTERN } from '@/app/bi/constants/dateFormats.ts';

import { IRoute } from '@/app/bi/models/common';

import styles from './index.module.scss';

const LABELS = {
  IN_CITY: getText('components:airlineSearchItem.airItem.inCity'),
  IN_CITIES: getText('components:airlineSearchItem.airItem.inCities'),
  ATTENTION: getText('components:airlineSearchItem.airItem.tooltip.attention'),
  CHANGING_AIRPORT: getText('components:airlineSearchItem.airItem.tooltip.changingAirport'),
  WITHOUT_TRANSFERS: getText('components:airlineSearchItem.airItem.withoutTransfers'),
};

interface IAirItemProps<T extends IRoute> {
  route: T;
  duration: number;
}

const AirItem = <T extends IRoute>({ route, duration }: IAirItemProps<T>) => {
  const { segments } = route;
  const wrappedStyles = [styles.wrapper];
  const firstSegment = segments[0];
  const lastSegment = segments[segments.length - 1];
  const changeCount = segments.length - 1;

  const routeDurationHtml = secondsToLabel(duration, true);

  const sumChangeDuration = segments
    .map((segment) => segment.transferDuration)
    .reduce((acc, current) => acc + current);

  const flightNumbers = segments.map((segment) =>
    `${segment.marketingAirlineCode} ${segment.flightNumber}`,
  ).join(', ');

  const filterAirlines = segments.filter((segment) => segment.arrivalAirportCode === firstSegment.marketingAirlineCode);

  const airlineName = firstSegment.marketingAirlineName;

  const airlineNameWithNumbers = segments.length === 1 || (segments.length === 2 && filterAirlines.length === 2)
    ? `${airlineName} (${flightNumbers})`
    : flightNumbers;

  const technicalStopWrappedStyles = firstSegment.technicalStop?.length
    ? styles.duration_with_technical_stop
    : styles.duration;

  const renderSegments = () => {
    const segmentsHtml = segments.map((segment, index) => {
      const { arrival, departure } = getTerminals(segment, index, segments);

      const departureChanged = isDepartureChanged(index, segment, segments);
      const arrivalChanged = isArrivalChanged(index, segment, segments);

      return (
        <AirItemSegment
          key={ `segment_${segment.id}` }
          segment={ segment }
          departureChanged={ departureChanged }
          arrivalChanged={ arrivalChanged }
          arrivalTerminal={ arrival }
          departureTerminal={ departure }
          opts={ getMappedSegments(segment) }
          transferPlace={ segments[0].transferDuration === 0 ? 'top' : 'bottom' }
        />
      );
    });

    return (
      <div className={ styles.segments }>
        { segmentsHtml }
      </div>
    );
  };

  const renderTooltip = () => {
    const text = infoCity.length === 1
      ? ` ${LABELS.IN_CITY} ${infoCity[0]}`
      : ` ${LABELS.IN_CITIES} ${infoCity.join(', ')}`;

    const tooltip = () => (
      <Text
        type='NORMAL_14_130'
        color='white'
        className={ styles.tooltip_box }
      >
        <div>{ LABELS.ATTENTION }</div>
        <div>
          { LABELS.CHANGING_AIRPORT }
          { text }
        </div>
      </Text>
    );

    return (
      <Tooltip className={ styles.transfer_icon } renderContent={ tooltip }>
        <Icon type='warning' />
      </Tooltip>
    );
  };

  const renderCity = (city: string, airportId: string) => (
    <Text className={ styles.city }>{`${city}, ${airportId}`}</Text>
  );

  const renderTransfer = () => {
    const questionHtml = !!infoCity.length && renderTooltip();

    if (changeCount) {
      return (
        <div className={ styles.transfer }>
          <Text
            type='NORMAL_14'
            color={ infoCity.length ? 'red' : 'gray' }
          >
            {`${changeCount} ${toDecline(changeCount, TRANSFER)} (${secondsToLabel(sumChangeDuration)})`}
          </Text>
          { questionHtml }
        </div>
      );
    }

    return !firstSegment.technicalStop?.length && (
      <Text type='NORMAL_14' color='gray'>
        { LABELS.WITHOUT_TRANSFERS }
      </Text>
    );
  };

  const renderTechnicalStopTooltip = () => !!firstSegment.technicalStop?.length
    && (
      <TechnicalStopTooltip
        changeCount={ changeCount }
        route={ route }
        className={ styles.technical_title }
      />
    );

  const infoCity = segmentsArrivalCity(segments);
  const detailsHtml = renderSegments();

  const departure = renderCity(firstSegment.departureCity, firstSegment.departureAirportCode);
  const arrival = renderCity(lastSegment.arrivalCity, lastSegment.arrivalAirportCode);

  return (
    <div className={ wrappedStyles.join(' ') }>
      <div className={ styles.wrapper_info }>
        <div className={ styles.info }>
          <div className={ styles.col_departure }>
            <Text type='bold_22'>
              { dateWithoutDayjs(firstSegment.departureDate, DATE_FORMATS.TIME) }
            </Text>
            { departure }
            <Text type='NORMAL_14' color='gray'>
              {formatDate(firstSegment.departureDate, PATTERN.DATE_WITH_WEEK_DAY)}
            </Text>
          </div>
          <div className={ styles.col_airline }>
            <Text type='NORMAL_14' color='gray'>
              { airlineNameWithNumbers }
            </Text>
            <Text type='SEMIBOLD_16' className={ technicalStopWrappedStyles }>
              { routeDurationHtml }
            </Text>
            { renderTransfer() }
            { renderTechnicalStopTooltip() }
          </div>
          <div className={ styles.col_arrival }>
            <Text type='bold_22'>
              { dateWithoutDayjs(lastSegment.arrivalDate, DATE_FORMATS.TIME) }
            </Text>
            { arrival }
            <Text type='NORMAL_14' color='gray'>
              {formatDate(firstSegment.arrivalDate, PATTERN.DATE_WITH_WEEK_DAY)}
            </Text>
          </div>
        </div>
        { detailsHtml }
      </div>
    </div>
  );
};

export { AirItem };
