import React, { RefObject, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkButton, Text } from 'yw-ui';

import { TFunction } from 'i18next';

import { useAppSelector } from '@/app/store/hooks/redux';

import { HotelCheckInCheckOutBlock } from '@/app/components/HotelCheckInCheckOutBlock';
import { ShowOnMapButton } from '@/app/components/ShowOnMapButton';
import { ImageGallery } from '@/app/components/ImageGallery';
import { Hotel } from '../Hotel';
import { HotelNameBlock } from '../HotelNameBlock';

import textAbbreviation from '@/app/bi/utils/textAbbreviation.ts';

import { EPositionThumbnails } from '@/app/bi/models/hotelsTypes.ts';
import { IHotelSearchResponse } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './index.module.scss';

const SCROLL_OFFSET_TOP = 150;
const SLIDE_INTERVAL = 4000;
const ABBREVIATION_LENGTH = 300;

const createLabels = (t: TFunction) => ({
  MORE_ABOUT_HOTEL: t('hotels:hotelResult.moreAboutOfHotel'),
  ADDRESS: t('hotels:hotelResult.address'),
  DISTANCE_TO_CENTER: (city: string, distance: string) =>
    t('hotels:hotelResult.distanceToCenter', { city, distance }),
});

interface HotelInformationProps {
  data: IHotelSearchResponse;
  handleOpenDatePickerFrom: () => void;
  handleOpenDatePickerTo: () => void;
  buttonsRefs: RefObject<HTMLButtonElement>[];
}

export const HotelInformation = ({
  data,
  buttonsRefs,
  handleOpenDatePickerFrom,
  handleOpenDatePickerTo,
}: HotelInformationProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const moreAboutHotelRef: RefObject<HTMLDivElement | null> = useRef(null);
  const searchHotelsReducer = useAppSelector((state) => state.searchHotelsReducer);

  const { roomCount } = searchHotelsReducer;

  const {
    item: {
      checkin: hotelCheckin,
      checkout: hotelCheckout,
      stars,
      images,
      city,
      distanceToCenter,
    },
    item,
  } = data;

  const scrollToAmenities = () => {
    if (moreAboutHotelRef.current) {
      window.scroll({
        top: moreAboutHotelRef.current.offsetTop - SCROLL_OFFSET_TOP,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const renderGallery = () => {
    const imgsList = images.map(({ originUrl, thumbnailUrl }) => ({
      original: originUrl,
      thumbnail: thumbnailUrl,
    }));

    return (
      <ImageGallery
        autoPlay
        positionThumbnails={ EPositionThumbnails.Left }
        items={ imgsList }
        slideInterval={ SLIDE_INTERVAL }
      />
    );
  };

  const descriptionStr = item.description
    ? textAbbreviation(item?.description.substring(item?.description.indexOf('<p>')), ABBREVIATION_LENGTH)
    : '';

  return (
    <div className={ styles.page }>
      <HotelNameBlock
        stars={ stars }
        name={ item.name }
        rating={ item.rating }
        ratingType={ item.ratingType }
      />
      <div className={ styles.static }>
        <div className={ styles.gallery }>
          {renderGallery()}
        </div>
        <div className={ styles.info }>
          <div className={ styles.hotel_address }>
            <Text type='bold_16' color='gray-7'>{LABELS.ADDRESS}</Text>
            <Text type='NORMAL_14' color='gray-7'>{item.address}</Text>
            <Text type='NORMAL_14' className={ styles.distance }>
              {LABELS.DISTANCE_TO_CENTER(city, distanceToCenter.toFixed(2))}
            </Text>
            <ShowOnMapButton onClick={ () => { } }/>
          </div>
          <div className={ styles.description }>
            <Text type='bold_16' color='gray-7'>{LABELS.MORE_ABOUT_HOTEL}</Text>
            <div
              className={ styles.text }
              dangerouslySetInnerHTML={ { __html: descriptionStr } }
            />
          </div>
          <LinkButton onClick={ scrollToAmenities } color='light-blue-4'>
            {LABELS.MORE_ABOUT_HOTEL}
          </LinkButton>
        </div>
      </div>
      <HotelCheckInCheckOutBlock
        checkInTime={ hotelCheckin }
        checkOutTime={ hotelCheckout }
      />
      <div className={ styles.rates }>
        <Hotel
          hotelInfo={ item }
          buttonsRefs={ buttonsRefs }
          onOpenDatePickerFrom={ handleOpenDatePickerFrom }
          onOpenDatePickerTo={ handleOpenDatePickerTo }
          moreAboutHotelRef={ moreAboutHotelRef }
          roomCount={ Number(roomCount) }
        />
      </div>
    </div>
  );
};
