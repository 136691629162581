import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Icon, Text } from 'yw-ui';

import { IconType } from 'yw-ui/src/components/Icon/types.ts';
import { getText } from '@/i18n';

import ROUTES from '@/app/bi/constants/routes.ts';

import styles from './index.module.scss';

const LABELS = {
  AIR: getText('homePage:menuItems.air'),
  HOTELS: getText('homePage:menuItems.hotel'),
  TRANSFER: getText('homePage:menuItems.transfer'),
};

interface IItemTab {
  label: string;
  icon: IconType;
  path: string;
}

const TAB_ITEMS: IItemTab[] = [
  {
    label: LABELS.AIR,
    icon: 'air',
    path: ROUTES.SEARCH.AIR,
  },
  {
    label: LABELS.HOTELS,
    icon: 'hotel',
    path: ROUTES.SEARCH.HOTEL,
  },
  {
    label: LABELS.TRANSFER,
    icon: 'transport',
    path: ROUTES.SEARCH.TRANSFER,
  },
];

interface ITab {
  label: string;
  isActiveTab: boolean;
  icon: IconType;
  path: string;
}

const Tab = ({
  label,
  icon,
  isActiveTab,
  path,
}: ITab) => {
  const colorIcon = isActiveTab ? 'blue1' : 'gray5';
  const colorText = isActiveTab ? 'blue-1' : 'gray-5';

  return (
    <NavLink
      to={ path }
      className={ ({ isActive }) =>
        `${styles.tab} ${(isActive || isActiveTab) ? styles.active : ''}`
      }
    >
      <Icon
        type={ icon }
        size={ 24 }
        color={ colorIcon }
      />
      <Text type='NORMAL_16' color={ colorText }>
        {label}
      </Text>
    </NavLink>
  );
};

const Tabs = () => {
  const { pathname } = useLocation();

  const renderTabs = () => TAB_ITEMS.map(({
    label,
    icon,
    path,
  }) => {
    const isActiveTab = pathname.includes(path)
        || (pathname === ROUTES.HOMEPAGE && path === ROUTES.SEARCH.AIR);

    return (
      <Tab
        key={ `tab_${path}` }
        label={ label }
        path={ path }
        icon={ icon }
        isActiveTab={ isActiveTab }
      />
    );
  });

  return (
    <nav className={ styles.tabs }>
      {renderTabs()}
    </nav>
  );
};

export { Tabs };
