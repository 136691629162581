import React, { ReactNode, RefObject, useMemo, useState } from 'react';
import { Text, Button, NoResults } from 'yw-ui';
import { useTranslation } from 'react-i18next';

import { TFunction } from 'i18next';

import { sideSlice } from '@/app/store/redusers/SideSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';

import Filters from './components/Filters';
import { RoomGroup } from './components/RoomGroup';
import { HotelServices } from '@/app/components/HotelServices';

import { DATE_FORMATS } from '@/app/bi/constants/dateFormats.ts';

import { IHotelSearchItemResponse } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  AVAILABLE_ROOMS: t('hotels:hotelResult.item.availableRooms'),
  ADD_EARLY_IN: t('hotels:hotelResult.item.addEarlyIn'),
  LATE_OUT: t('hotels:hotelResult.item.lateOut'),
  ADD_LATE_OUT: t('hotels:hotelResult.item.addLateOut'),
  NOT_FOUND_HOTELS: t('hotels:hotelResult.item.notFoundHotel'),
  EARLY_IN: (date: string) => t('hotels:hotelResult.item.earlyIn', { date }),
  LATE_OUT_WITH_DATE: (date: string) => t('hotels:hotelResult.item.lateOutWithDate', { date }),
});

export interface IHotelProps {
  buttonsRefs: RefObject<HTMLButtonElement>[];
  hotelInfo: IHotelSearchItemResponse;
  onOpenDatePickerFrom(): void;
  onOpenDatePickerTo(): void;
  moreAboutHotelRef: RefObject<HTMLDivElement | null>;
  roomCount: number;
}

const Hotel = ({
  buttonsRefs,
  hotelInfo,
  onOpenDatePickerFrom,
  onOpenDatePickerTo,
  moreAboutHotelRef,
  roomCount,
}: IHotelProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const {
    checkin,
    checkout,
    customCheckin,
    customCheckout,
  } = useAppSelector((state) => state.searchHotelsReducer);
  const { setShow, setRenderFn } = sideSlice.actions;
  const dispatch = useAppDispatch();

  const currency = useAppSelector((state) => state.appSlice).headers.balance.currency;
  const [currencyCode, setCurrencyCode] = useState(currency);

  const showRoomDetails = (renderFn: () => ReactNode) => {
    dispatch(setShow(true));
    dispatch(setRenderFn(renderFn));
  };

  const renderRoomGroup = () => {
    if (!hotelInfo?.roomGroups.length) {
      return (
        <div className={ styles.not_found }>
          <NoResults
            className={ styles.not_found }
            renderContent={ () => (
              <div className={ styles.content_wrapper }>
                <Text type='bold_24'>
                  { LABELS.NOT_FOUND_HOTELS }
                </Text>
              </div>
            ) }
          />
        </div>
      );
    }

    const searchSettings = {
      customCheckin,
      customCheckout,
      daysCount: checkout?.diff(checkin, 'days'),
      checkin,
      checkout,
    };

    return hotelInfo?.roomGroups.map((room, idx) => (
      <RoomGroup
        key={ `${room.name}-${room.roomType}-${idx}` }
        room={ room }
        currencyCode={ currencyCode }
        // @ts-ignore
        searchSettings={ searchSettings }
        onShowRoomDetails={ showRoomDetails }
        roomCount={ roomCount }
      />
    ));
  };

  const renderFilters = () => (
    <Filters
      currencyCode={ currencyCode }
      updateCurrencyCode={ setCurrencyCode }
    />
  );

  const renderCustomTimeSelect = () => {
    const textTo = customCheckin
      ? LABELS.EARLY_IN(customCheckin.format(DATE_FORMATS.TIME))
      : LABELS.ADD_EARLY_IN;

    let textFrom;

    if (customCheckout) {
      textFrom = LABELS.LATE_OUT_WITH_DATE(customCheckout.format(DATE_FORMATS.TIME));
    } else {
      textFrom = customCheckin ? LABELS.ADD_LATE_OUT : LABELS.LATE_OUT;
    }

    return (
      <div className={ styles.custom_time_wrapper }>
        <Button
          type='link'
          className={ styles.item }
          onClick={ onOpenDatePickerFrom }
          ref={ buttonsRefs[0] }
        >
          { textTo }
        </Button>
        <div className={ `${styles.divider} ${styles.item}` } />
        <Button
          type='link'
          className={ styles.item }
          onClick={ onOpenDatePickerTo }
          ref={ buttonsRefs[1] }
        >
          { textFrom }
        </Button>
      </div>
    );
  };

  const renderHeader = () => (
    <div className={ styles.title }>
      <Text type='bold_24' color='gray-7'>
        { LABELS.AVAILABLE_ROOMS }
      </Text>
      { renderCustomTimeSelect() }
    </div>
  );

  return (
    <div className={ styles.wrapper }>
      { renderHeader() }
      { renderFilters() }
      <div className={ styles.rates }>
        { renderRoomGroup() }
      </div>
      <div
        className={ styles.amenities }
        // @ts-ignore
        ref={ moreAboutHotelRef }
      >
        <HotelServices info={ hotelInfo } />
      </div>
    </div>
  );
};

export { Hotel };
