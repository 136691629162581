import React, { useMemo } from 'react';
import { Button, Icon, Price, Text } from 'yw-ui';
import { useTranslation } from 'react-i18next';

import type { TFunction } from 'i18next';

import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy';

import { ICheckoutData } from '@/app/bi/models/booking.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  TITLE: t('components:totalBlock.title'),
  PURCHASE: t('components:totalBlock.purchase'),
  TOTAL: t('components:totalBlock.total'),
  PAY: t('components:totalBlock.pay'),
  HOTELS: t('components:totalBlock.types.hotel'),
  AIRLINE: t('components:totalBlock.types.air'),
  ATTENTION: t('components:totalBlock.attention'),
});

interface TotalBlockProps {
  hotelsPrice: number;
  airlinesPrice: number;
  totalPrice: number;
  currency: ECurrencyCode;
  showHotelsPrice: boolean;
  showAirlinesPrice: boolean;
  disabledButton?: boolean;
  isCart?: boolean;
  loading?: boolean;
  checkoutData?: ICheckoutData;
  onClick():void;
}

const TotalBlock = ({
  hotelsPrice,
  airlinesPrice,
  totalPrice,
  currency,
  showHotelsPrice,
  showAirlinesPrice,
  disabledButton = false,
  isCart = false,
  loading = false,
  checkoutData = {
    canBook: false,
    errors: [],
  },
  onClick,
}: TotalBlockProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const buttonTitle = isCart ? LABELS.PURCHASE : LABELS.PAY;

  const renderBookErrorMsg = () => {
    if (isCart || checkoutData?.canBook) return null;

    return (
      <div className={ styles.book_unavailable }>
        <Icon type='warning' className={ styles.icon }/>
        <div className={ styles.messages }>
          {checkoutData.errors.map((message, index) => (
            <Text
              key={ `checkout-error-${index}}` }
              color='red'
              type='NORMAL_14'
              className={ styles.message }
            >
              {message}
            </Text>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_24' color='gray-7'>{LABELS.TITLE}</Text>
      <div className={ styles.content }>
        <div className={ styles.trip_items }>
          {showAirlinesPrice && (
            <div className={ styles.row }>
              <Text type='NORMAL_16' color='gray-7'>
                {LABELS.AIRLINE}
              </Text>
              {/* TODO Исправить валюту */}
              <Price
                value={ airlinesPrice }
                type='bold_16'
                typeCurrency='bold_16'
                marginSmall
                color='gray-7'
                className={ styles.price }
                costType={ CURRENCY_SYMBOLS[currency] }
              />
            </div>
          )}
          {showHotelsPrice && (
            <div className={ styles.row }>
              <Text type='NORMAL_16' color='gray-7'>{LABELS.HOTELS}</Text>
              {/* TODO Исправить валюту */}
              <Price
                value={ hotelsPrice }
                type='bold_16'
                typeCurrency='bold_16'
                marginSmall
                color='gray-7'
                costType={ CURRENCY_SYMBOLS[currency] }
              />
            </div>
          )}
        </div>
        <div className={ styles.row }>
          <Text type='bold_24'>{LABELS.TOTAL}</Text>
          {/* TODO Исправить валюту */}
          <Price
            value={ totalPrice }
            type='bold_18'
            typeCurrency='bold_18'
            marginSmall
            color='gray-7'
            costType={ CURRENCY_SYMBOLS[currency] }
          />
        </div>
      </div>
      <Button
        type='primary'
        size='large'
        disabled={ disabledButton }
        onClick={ onClick }
        loading={ loading }
      >
        { buttonTitle }
      </Button>
      {!isCart && checkoutData?.canBook && (
        <Text type='NORMAL_14' color='light-blue-4' >{
          LABELS.ATTENTION}
        </Text>
      )}
      {renderBookErrorMsg()}
    </div>
  );
};

export { TotalBlock };
