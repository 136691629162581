import React, { useMemo } from 'react';
import { Text, Tooltip } from 'yw-ui';
import clsx from 'clsx';

import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { TechnicalStopText } from '@/app/components/AirlineTechnicalStop/components/Text';

import { secondsToLabel } from '@/app/bi/utils/time.ts';
import { getImageLogo, IMappedSegments } from '@/app/bi/utils/airline.ts';

import { Segment } from '@/app/bi/models/airlineTypes.ts';
import { IInfoTerminal } from '@/app/bi/models/airline.ts';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  TRANSFER: t('components:airlineItemSegment.transfer'),
  ATTENTION: t('components:airlineItemSegment.attention'),
  INFO_AIRPORT: t('components:airlineItemSegment.infoAirport'),
  INFO_TERMINAL: t('components:airlineItemSegment.infoTerminal'),
  TERMINAL: t('components:airlineItemSegment.terminal'),
  AIRLINE: (name: string) => t('components:airlineItemSegment.airline', { name }),
});

interface AirlineItemSegmentProps {
  fromTrip?: boolean,
  segment: Segment,
  arrivalChanged?: boolean,
  departureChanged?: boolean,
  arrivalTerminal: IInfoTerminal,
  departureTerminal: IInfoTerminal,
  opts: IMappedSegments,
}

const AirlineItemSegment = ({
  segment,
  opts,
  arrivalTerminal,
  departureTerminal,
  fromTrip = false,
  arrivalChanged = false,
  departureChanged = false,
}: AirlineItemSegmentProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const {
    transferDuration,
    arrivalCity,
    departureCity,
    operatingAirline,
    departureAirport,
    flightNumber,
    aircraftName,
    arrivalAirport,
    technicalStop,
    marketingAirline,
  } = segment;

  const {
    airlineId,
    departureAirportId,
    arrivalAirportId,
    departureTime,
    arrivalTime,
    departureDate,
    arrivalDate,
    departureWeek,
    arrivalWeek,
  } = opts;

  const renderTooltip = (info: string, city: string) => (
    <Text
      color='white'
      type='NORMAL_14_130'
      className={ styles.tooltip_box }
    >
      <div>{ LABELS.ATTENTION }</div>
      <div>
        { info }
        { city }
      </div>
    </Text>
  );

  const renderCity = (city: string, id: string | null, changed: boolean) => {
    const color = changed ? 'red' : 'default';

    return (
      <Tooltip show={ changed } renderContent={ () => renderTooltip(LABELS.INFO_AIRPORT, city) }>
        <Text type='SEMIBOLD_16' color={ color }>
          { city }
          { id }
        </Text>
      </Tooltip>
    );
  };

  const renderTerminal = (terminal: string, changed: boolean, city: string) => {
    if (!terminal || terminal.trim().length === 0) return null;

    const color = changed ? 'red' : 'default';

    return (
      <Tooltip
        show={ changed }
        renderContent={ () => renderTooltip(LABELS.INFO_TERMINAL, city) }
        className={ styles.airport_name }
      >
        <Text type='NORMAL_14' color={ color }>
          { LABELS.TERMINAL }
          {' '}
          { terminal }
        </Text>
      </Tooltip>
    );
  };

  const arrivalCityHtml = renderCity(arrivalCity, arrivalAirportId, arrivalChanged);
  const departureCityHtml = renderCity(departureCity, departureAirportId, departureChanged);

  const renderDifferentOperatingAirline = () => marketingAirline.code !== operatingAirline.code && (
    <div className={ styles.operating_airline }>
      <img
        className={ styles.logo }
        src={ getImageLogo(operatingAirline.code) }
        title={ operatingAirline.name }
        alt={ operatingAirline.name }
      />
      <Text
        type='NORMAL_14'
        color='gray'
      >
        {LABELS.AIRLINE(operatingAirline.name)}
      </Text>
    </div>
  );

  const renderChangeDuration = () => (transferDuration > 0 ? (
    <div className={ styles.change }>
      <Text
        type='NORMAL_14'
        color='gray-6'
        className={ styles.title }
      >
        { LABELS.TRANSFER }
      </Text>
      <Text
        type='NORMAL_14'
        color='gray-6'
        className={ styles.duration }
      >
        { secondsToLabel(transferDuration, true) }
      </Text>
    </div>
  ) : null);

  const arrivalTerminalHtml = renderTerminal(arrivalTerminal.terminal, arrivalTerminal.different, arrivalCity);
  const departureTerminalHtml = renderTerminal(departureTerminal.terminal, departureTerminal.different, departureCity);

  return (
    <div className={ clsx([styles.wrapped], { [styles.not_count]: fromTrip }) }>
      {renderChangeDuration()}
      <div className={ styles.row }>
        <div className={ styles.logo }>
          <img
            className={ styles.img }
            src={ getImageLogo(airlineId) }
            title={ marketingAirline.name }
            alt={ marketingAirline.name }
          />
        </div>
        <div className={ styles.item }>
          <div className={ styles.col_departure }>
            <Text>
              {departureDate}
              ,
              {' '}
              {departureWeek}
            </Text>
            <Text type='bold_22' className={ styles.time }>{ departureTime }</Text>
            { departureCityHtml }
            <Text
              type='NORMAL_14'
              className={ styles.airport_name }
            >
              { departureAirport.name }
            </Text>
            { departureTerminalHtml }
          </div>
          <div className={ styles.col_airline }>
            <Text
              type='NORMAL_14'
              color='gray'
              className={ styles.airline_name }
            >
              <span>{marketingAirline.name}</span>
              &ensp;
              <span>
                (
                {airlineId}
                {' '}
                {flightNumber}
                )
              </span>
            </Text>
            <Text
              type='NORMAL_14'
              color='gray'
            >
              { aircraftName }
            </Text>
            {' '}
          </div>
          <div className={ styles.col_arrival }>
            <Text>
              {arrivalDate}
              ,
              {' '}
              {arrivalWeek}
            </Text>
            <Text type='bold_22' className={ styles.time }>{ arrivalTime }</Text>
            { arrivalCityHtml }
            <Text
              type='NORMAL_14'
              className={ styles.airport_name }
            >
              { arrivalAirport.name }
            </Text>
            { arrivalTerminalHtml }
          </div>
        </div>
      </div>
      { !!technicalStop?.length && (
        <TechnicalStopText
          technicalStop={ technicalStop }
          className={ clsx([styles.row, styles.technical_stop]) }
        />
      ) }
      { renderDifferentOperatingAirline() }
    </div>
  );
};

export { AirlineItemSegment };
