import React from 'react';
import { PageLoader } from 'yw-ui';

import { getText } from '@/i18n';

import { useGetCompaniesQuery } from '@/app/bi/api/companyDetailsApi.ts';

import { Table } from '@/app/components/Table';
import { EmptyDataPanel } from '@/app/components/EmptyDataPanel';

import styles from '@/app/pages/Settings/pages/Employees/index.module.scss';

const LABELS = {
  NAME_COMPANY: getText('settings:companyDetailsTable.nameCompany'),
  SMALL_NAME_COMPANY: getText('settings:companyDetailsTable.smallNameCompany'),
  VKN: getText('settings:companyDetailsTable.vkn'),
  ADDRESS: getText('settings:companyDetailsTable.address'),
  SEO: getText('settings:companyDetailsTable.seo'),
  NO_TRIPS_FOUND: getText('settings:emptyData.noTripsFound'),
  CREATE_FIRST_TRIP: getText('settings:emptyData.createFirstTrip'),
  CREATE_TRIP: getText('settings:createTrip'),
};

const CompanyDetails = () => {
  const {
    data: responseData = [],
    isError,
    isLoading,
  } = useGetCompaniesQuery();

  const arrayColumn = [LABELS.NAME_COMPANY, LABELS.SMALL_NAME_COMPANY, LABELS.VKN, LABELS.ADDRESS, LABELS.SEO];

  const handleClickButtonEmptyPanel = () => {};

  const renderTableRows = () => responseData.map(({
    id,
    name,
    shortName,
    vkn,
    address,
    seo,
  }) => (
    <tr key={ id }>
      <td>{name}</td>
      <td>{shortName}</td>
      <td>{vkn}</td>
      <td>{address}</td>
      <td>{seo}</td>
    </tr>
  ));

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError) {
    return <div>Ошибка</div>;
  }

  if (!responseData.length) {
    return (
      <div className={ styles.emptyPanel }>
        <EmptyDataPanel
          title={ LABELS.NO_TRIPS_FOUND }
          subTitle={ LABELS.CREATE_FIRST_TRIP }
          buttonTitle={ LABELS.CREATE_TRIP }
          onChangeButton={ handleClickButtonEmptyPanel }
        />
      </div>
    );
  }

  return (
    <Table
      column={ arrayColumn }
      rows={ renderTableRows }
    />
  );
};

export { CompanyDetails };
