import React from 'react';
import { Button, NoResults, Text } from 'yw-ui';
import { useNavigate } from 'react-router-dom';

import { getText } from '@/i18n';

import ROUTES from '@/app/bi/constants/routes.ts';

import styles from './index.module.scss';

const LABELS = {
  EMPTY_CART: getText('cart:emptyCart.title'),
  ADD_SERVICES_TO_CART: getText('cart:emptyCart.addServicesToCart'),
  ADD_SERVICES: getText('cart:emptyCart.addServices'),
};
const EmptyCart = () => {
  const navigate = useNavigate();

  const goToSearchPage = () => navigate(ROUTES.HOMEPAGE);

  return (
    <div className={ styles.wrapper }>
      <NoResults
        className={ styles.no_results }
        renderContent={ () => (
          <div className={ styles.content }>
            <Text type='bold_18' fontWeight={ 700 }>
              {LABELS.EMPTY_CART}
            </Text>
            <Text type='NORMAL_16' className={ styles.subtext }>
              {LABELS.ADD_SERVICES_TO_CART}
            </Text>
            <Button type='outline' onClick={ goToSearchPage }>
              {LABELS.ADD_SERVICES}
            </Button>
          </div>
        ) }
      />
    </div>
  );
};
export { EmptyCart };
