import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Collapse, Input } from 'yw-ui';
import { getText } from '@/i18n';

import {
  FILTER_DEFAULT_COUNT_ITEMS,
} from '@/app/bi/constants/common.ts';

import { ICompany } from '@/app/bi/models/app.ts';

import styles from './index.module.scss';
import { sortedFilterCompanies } from '@/app/bi/utils/settings.ts';

const LABELS = {
  OPEN_MORE: getText('trips:openMore'),
  HIDDEN_MORE: getText('trips:hiddenMore'),
};

interface FilterEmployeeItemProps {
  title: string;
  data: ICompany[];
  companiesValues: number[];
  inputValue: string;
  placeholder: string;
  onChange(array: number[]): void;
  onChangeInput(value: string): void;
}

const CompanyFilterItem = ({
  title,
  data,
  companiesValues,
  inputValue,
  placeholder,
  onChange,
  onChangeInput,
}: FilterEmployeeItemProps) => {
  const [
    openList,
    setOpenList,
  ] = useState<boolean>(false);
  const [
    selectedCompanies,
    setSelectedCompanies,
  ] = useState<number[]>(companiesValues);

  const listViewLength = openList ? data.length : FILTER_DEFAULT_COUNT_ITEMS;

  const handleOpenList = () => setOpenList(!openList);

  const handleChange = (id: number) => {
    const index = selectedCompanies.indexOf(id);
    const newSelectedCompanies = [...selectedCompanies];

    if (index > -1) {
      newSelectedCompanies.splice(index, 1);
    } else {
      newSelectedCompanies.push(id);
    }

    setSelectedCompanies(newSelectedCompanies);
    onChange(newSelectedCompanies);
  };

  useEffect(() => {
    setSelectedCompanies(companiesValues);
  }, [companiesValues]);

  const renderButton = (
    { id, name }: ICompany,
    listLength: number,
    index: number,
    checked: boolean,
  ) => index < listLength && (
    <Checkbox
      className={ styles.checkbox }
      key={ id }
      value={ checked }
      onChange={ () => handleChange(id) }
      themes='dark'
    >
      {name}
    </Checkbox>
  );

  const renderArrayItems = () => {
    const countViewSelectElem = listViewLength + selectedCompanies.length;
    const sortArrayCompanies = sortedFilterCompanies(data, selectedCompanies);

    return sortArrayCompanies.map((item, index) => renderButton(
      item,
      countViewSelectElem,
      index,
      selectedCompanies.includes(item.id),
    ));
  };

  const renderButtonMore = () => data.length > FILTER_DEFAULT_COUNT_ITEMS && (
    <Button type='text' className={ styles.buttonMore } onClick={ handleOpenList }>
      {openList ? LABELS.HIDDEN_MORE : LABELS.OPEN_MORE}
    </Button>
  );

  return (
    <Collapse label={ title } moreOpen={ openList } className={ styles.container }>
      <div className={ styles.searchContainer }>
        <Input
          isCleansing
          className={ styles.search }
          value={ inputValue }
          placeholder={ placeholder }
          onChange={ (e) => onChangeInput(e) }
        />
      </div>
      {renderArrayItems()}
      {renderButtonMore()}
    </Collapse>
  );
};

export { CompanyFilterItem };
