import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import clsx from 'clsx';
import { Button, IconButton, ItemPanel, Price, Text, Tooltip } from 'yw-ui';

import FareDetails from '../FareDetails';
import FareItem from './components/FareItem';
import { AirItem } from './components/AirItem';
import { TravellersAmount } from './components/TravellersAmount';

import { getTextPolicy } from '@/app/bi/utils/travelPolicy.ts';
import { getPriceByCurrency } from './utils.ts';

import SYMBOLS from '../../bi/constants/symbols.ts';
import ANIMATION from '../../bi/constants/animation.ts';
import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy.ts';

import { Fares, Items } from '@/app/bi/models/airlineTypes.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  AVAILABLE: t('components:airlineSearchItem.available'),
  HEADING_TOOLTIP: (policyName: string) => t('components:travelPolicies.headingTooltip', { policyName }),
  HEADING_TOOLTIP_CONTENT: (policyName: string) => t('components:travelPolicies.headingTooltipContent', { policyName }),
  NOT_SPECIFIED: t('settings:travelPolicies.notSpecified'),
});

interface AirlineSearchItemProps {
  item: Items;
  travellers: number;
  isChangeAirTrip?: boolean;
  selectPolicy: { id: string, policyName: string, isActive: boolean };
  loadingPolicy: boolean;
  listTravelPolicies: ITravelPolicy[];
  onAddToCart(item: { item: Items, fareId: string, totalPrice: number }): void;
  currencyCode: ECurrencyCode;
}

const AirlineSearchItem = ({
  item,
  travellers,
  isChangeAirTrip = false,
  selectPolicy,
  loadingPolicy,
  listTravelPolicies,
  onAddToCart,
  currencyCode,
}: AirlineSearchItemProps) => {
  const {
    fares,
    routes,
  } = item;
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const [animationClass, setAnimationClass] = useState('');
  const [currentFareId, setCurrentFareId] = useState(fares[0].id);
  const [currentPrice, setCurrentPrice] = useState(fares[0].price.totalPrice);
  const [showDetails, setShowDetails] = useState(false);

  const classNameItemPanel = clsx([styles.item_panel], {
    [styles.error_policy]: !!item.travelPolicyValidationErrors.length,
  });

  useEffect(() => {
    const fareItem = fares.find((fare) => fare.id === currentFareId);

    if (fareItem) {
      const priceByCurrency = getPriceByCurrency(fareItem, currencyCode);

      if (priceByCurrency) {
        setCurrentPrice(priceByCurrency.totalPrice);
      }
    }
  }, [currencyCode]);

  const handleAnimationEnd = () => setAnimationClass('');

  const handleAddToCart = (totalPrice: number) => {
    onAddToCart({ item, fareId: currentFareId, totalPrice });
    setAnimationClass(ANIMATION.MOVE_TO_CART);
  };

  const handleShowSegments = () => setShowDetails(!showDetails);

  const handleFareClick = (fare: Fares) => {
    const { id } = fare;

    const priceByCurrency = getPriceByCurrency(fare, currencyCode);

    if (priceByCurrency) {
      setCurrentFareId(id);
      setCurrentPrice(priceByCurrency.totalPrice);
    }
  };

  const handleTypeItem = (faresAmount: number, show: boolean) => {
    if (faresAmount > 1 && !show) {
      return 'FlexibleFareHide';
    }
    if (faresAmount === 1 && !show) {
      return 'FixedFareHide';
    }

    return null;
  };

  const handleSeparateSymbol = (faresAmount: number, show: boolean) => {
    const typeItem = handleTypeItem(faresAmount, show);

    switch (typeItem) {
      case 'FlexibleFareHide':
        return SYMBOLS.COMMA;
      case 'FixedFareHide':
        return SYMBOLS.DOT;
      default:
        return null;
    }
  };

  const renderAirItem = () => routes.map((route, index) => (
    <AirItem
      key={ `airline_route_${route.id}_${index}` }
      lastChild={ index === (routes.length - 1) }
      route={ route }
      showDetails={ showDetails }
      onShowSegments={ handleShowSegments }
    />
  ));

  const renderFareItems = () => (
    <div className={ styles.fare }>
      {fares.map((fare, ind) => (
        <FareItem
          key={ `airline_fare_${ind}` }
          fare={ fare }
          currentFareId={ currentFareId }
          currentPrice={ currentPrice }
          isChangeAirTrip={ isChangeAirTrip }
          currencyCode={ currencyCode }
          onFareClick={ handleFareClick }
        />
      ))}
    </div>
  );

  const renderFaresDetails = () => {
    const findFares = fares.find((fare) => currentFareId === fare.id);
    const separateSymbol = handleSeparateSymbol(fares.length, showDetails);

    return !!findFares && (
      <Text type='NORMAL_14_130'>
        <FareDetails
          title={ showDetails }
          fare={ findFares }
          className={ !showDetails ? styles.fare_details : '' }
          separateSymbol={ separateSymbol }
        />
      </Text>
    );
  };

  const renderTravellersAmount = () => {
    if (travellers <= 1) return null;

    return (
      <TravellersAmount
        currencyCode={ currencyCode }
        travellers={ travellers }
        currentFareId={ currentFareId }
        fares={ fares }
      />
    );
  };

  const renderAvailableCount = () => {
    const availableCount = Math.min.apply(null, routes.map((route) => (
      Math.min.apply(null, route.segments.map((segment) => segment.availableSeats))
    )));
    const visible = !isChangeAirTrip;

    return visible && (
      <Text type='NORMAL_14' color='gray-7'>
        {`${LABELS.AVAILABLE} ${availableCount}`}
      </Text>
    );
  };

  const renderPriceOrChange = () => {
    const fareItem = fares.find((fare) => currentFareId === fare.id);

    if (!fareItem) {
      return null;
    }

    const priceByCurrency = getPriceByCurrency(fareItem, currencyCode);

    if (!priceByCurrency) {
      return null;
    }

    return (
      <div className={ styles.price }>
        <Button
          className={ styles.button }
          type='secondary'
          onClick={ () => handleAddToCart(priceByCurrency.totalPrice) }
        >
          <Price
            type='bold_20'
            typeCurrency='bold_20'
            color='white'
            value={ priceByCurrency.totalPrice }
            costType={ CURRENCY_SYMBOLS[currencyCode] }
            marginSmall
          />
        </Button>
        <div className={ styles.options } >
          { renderTravellersAmount() }
          { renderAvailableCount() }
        </div>
      </div>
    );
  };

  const manyFares = fares.length > 1;
  const faresHtml = manyFares && renderFareItems();

  const classNameFares = clsx(styles.wrapper_fare, {
    [styles.flexible]: (manyFares && !showDetails),
  });

  const typeIcon = showDetails ? 'upCircle' : 'downCircle';

  const renderTooltipContent = () => {
    const renderListTextErrors = () => item.travelPolicyValidationErrors?.map((
      textPolicy,
    ) => (
      <li key={ textPolicy }>
        <Text color='white' type='NORMAL_14'>
          {getTextPolicy(textPolicy, EServiceTypes.Airline, listTravelPolicies)}
        </Text>
      </li>
    ));

    return (
      <div className={ styles.tooltip_content }>
        <Text color='white' type='NORMAL_16'>
          {LABELS.HEADING_TOOLTIP_CONTENT(selectPolicy.policyName)}
        </Text>
        <ul className={ styles.list_policy_text }>
          {renderListTextErrors()}
        </ul>
      </div>
    );
  };

  const renderPolicyTooltip = () => (
    <Tooltip
      show
      position='bottom'
      renderContent={ renderTooltipContent }
    >
      <Text type='NORMAL_14' className={ styles.name_policy }>
        {LABELS.HEADING_TOOLTIP(selectPolicy.policyName)}
      </Text>
    </Tooltip>
  );

  const renderTravelPolicyError = () => {
    if (!item.travelPolicyValidationErrors.length || (selectPolicy.policyName === LABELS.NOT_SPECIFIED && !loadingPolicy)) return null;

    return (
      <div className={ styles.render_error_policy }>
        {renderPolicyTooltip()}
      </div>
    );
  };

  return (
    <ItemPanel
      animationClass={ animationClass }
      className={ classNameItemPanel }
      onAnimationEnd={ handleAnimationEnd }
      renderHeader={ renderTravelPolicyError }
    >
      <div className={ styles.wrapper_item }>
        <div className={ styles.item }>
          { renderAirItem() }
          <div className={ classNameFares }>
            { faresHtml }
            { renderFaresDetails() }
          </div>
          <div className={ styles.arrow }>
            <IconButton
              className={ styles.icon }
              iconType={ typeIcon }
              size={ 32 }
              iconColor='blue1'
              onClick={ handleShowSegments }
            />
          </div>
        </div>
        <div className={ styles.action }>
          { renderPriceOrChange() }
        </div>
      </div>
    </ItemPanel>
  );
};

export { AirlineSearchItem };
