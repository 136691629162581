import React, { forwardRef, ReactNode } from 'react';
import { BookMark, BookMarkProps, ItemLoader } from 'yw-ui';
import clsx from 'clsx';

import styles from './index.module.scss';

interface ItemPanelProps {
  loading: boolean;
  children: ReactNode;
  animationClass?: string;
  headerClass?: string;
  itemClass?: string;
  className?: string;
  bookmarks?: BookMarkProps[];
  warning?: boolean;
  renderHeader(): JSX.Element;
  onAnimationEnd?(): void;
}

const CartPanel = forwardRef<HTMLDivElement, ItemPanelProps>(({
  loading = false,
  children,
  animationClass = '',
  headerClass = '',
  itemClass = '',
  className = '',
  warning = false,
  renderHeader,
  bookmarks = [],
  onAnimationEnd = () => {},
}, ref) => {

  const bookMarkExists = !!bookmarks.length;

  const wrapClasses = clsx(
    styles.wrap,
    className,
    animationClass,
    loading && styles.loading,
    bookMarkExists && styles.bookmark_exists,
  );

  const headerClasses = clsx(
    styles.header,
    headerClass,
    bookMarkExists && styles.left_radius_none,
  );

  const itemClasses = clsx(
    styles.item,
    itemClass,
    warning && styles.warning,
    bookMarkExists && styles.left_radius_none,
  );

  const renderBookMarks = () => {
    if (!bookmarks.length) return null;

    return (
      <div className={ styles.bookmark_wrapper }>
        {bookmarks.map((bookmarkProps, index) => (
          <BookMark
            { ...bookmarkProps }
            key={ index }
          />
        ))}
      </div>
    );
  };

  const loadingHtml = loading && <ItemLoader />;

  return (
    <div
      className={ wrapClasses }
      onAnimationEnd={ onAnimationEnd }
      ref={ ref }
    >
      { renderBookMarks() }
      { loadingHtml }
      <div className={ headerClasses }>
        { renderHeader() }
      </div>
      <div className={ itemClasses }>
        { children }
      </div>
    </div>
  );
});

export { CartPanel };
