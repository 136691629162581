import React from 'react';
import { Dayjs } from 'dayjs';
import {
  Button,
  Collapse,
  Datepicker,
  Text,
} from 'yw-ui';

import { getText } from '@/i18n';

import { formatDate, getDayjs } from '@/app/bi/utils/formatDate';

import { PATTERN } from '@/app/bi/constants/dateFormats';

import { ITripDates } from '@/app/bi/models/tripsTypes.ts';

import styles from './index.module.scss';

const LABELS = {
  ANY_DATE: getText('trips:anyDate'),
  RESET_FILTER: getText('trips:resetFilter'),
  FROM: getText('trips:travelPeriodDate.from'),
  TO: getText('trips:travelPeriodDate.to'),
};

interface FilterTravelPeriodItemProps {
  title: string;
  tripDatesValues: ITripDates;
  onChangeDate(value: ITripDates): void;
  onResetFilter(): void;
}

const FilterTravelPeriodItem = ({
  title,
  tripDatesValues,
  onChangeDate,
  onResetFilter,
}: FilterTravelPeriodItemProps) => {
  const { DEFAULT_PATTERN_FILTER } = PATTERN;
  const placeholderDatePicker = formatDate(getDayjs(), DEFAULT_PATTERN_FILTER);

  const handleDateFrom = (value: Dayjs | null) => {
    onChangeDate({ min: value || tripDatesValues.min, max: tripDatesValues.max });
  };

  const handleDateTo = (value: Dayjs | null) => {
    onChangeDate({ max: value || tripDatesValues.max, min: tripDatesValues.min });
  };

  return (
    <Collapse label={ title }>
      <div className={ styles.date_container }>
        <div className={ styles.date_item }>
          <Text type='NORMAL_14' className={ styles.text }>
            {LABELS.FROM}
          </Text>
          <Datepicker
            value={ tripDatesValues.min }
            wrapperClassName={ styles.wrap_date_picker }
            inputClassName={ styles.input }
            format={ DEFAULT_PATTERN_FILTER }
            placeholder={ placeholderDatePicker }
            onChange={ handleDateFrom }
          />
        </div>
        <div className={ styles.date_item }>
          <Text type='NORMAL_14' className={ styles.text }>
            {LABELS.TO}
          </Text>
          <Datepicker
            value={ tripDatesValues.max }
            wrapperClassName={ styles.wrap_date_picker }
            inputClassName={ styles.input }
            format={ DEFAULT_PATTERN_FILTER }
            placeholder={ placeholderDatePicker }
            onChange={ handleDateTo }
          />
        </div>
      </div>
      <Button onClick={ onResetFilter } type='text' className={ styles.button_clear }>
        {LABELS.RESET_FILTER}
      </Button>
    </Collapse>
  );
};

export { FilterTravelPeriodItem };
