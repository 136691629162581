import React, { useEffect, useMemo, useState } from 'react';
import { Button, Text } from 'yw-ui';

import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';

import { useSendReasonErrorPolicyMutation } from '@/app/bi/api/cartApi.ts';
import { useGetTravelPoliciesQuery } from '@/app/bi/api/travelPoliciesApi.ts';

import { PolicyTooltip } from './components/PolicyTooltip';

import { getPoliciesNames } from '@/app/bi/utils/travelPolicy.ts';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import {
  ICartResponseAirlineItemTravelPolicyError,
  ICartResponseHotelItemTravelPolicyError,
} from '@/app/bi/models/cart.ts';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  SAVE: t('common:save'),
  REASON_TRAVEL_POLICY: t('cart:reasonTravelPolicy'),
  WRITE_REASON_TRAVEL_POLICY: t('cart:writeReasonTravelPolicy'),
});

interface ITravelPolicyErrorProps {
  tripItemId: string;
  serviceType: EServiceTypes;
  travelPolicyReason: string | null;
  travelPoliciesErrors: ICartResponseAirlineItemTravelPolicyError[] | ICartResponseHotelItemTravelPolicyError[];
}

export const TravelPolicyError = ({
  tripItemId,
  serviceType,
  travelPolicyReason,
  travelPoliciesErrors,
}: ITravelPolicyErrorProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const { data: listTravelPolicies = [] } = useGetTravelPoliciesQuery();
  const [addReasonPolicyError] = useSendReasonErrorPolicyMutation();

  const [
    reasonPolicyError,
    setReasonPolicyError,
  ] = useState(travelPolicyReason || '');
  const [
    initialReasonPolicyError,
    setInitialReasonPolicyError,
  ] = useState('');
  const [
    isFilledButtonReasonPolicy,
    setIsFilledButtonReasonPolicy,
  ] = useState(true);
  const namesErrorPolicies = getPoliciesNames(
    listTravelPolicies,
    travelPoliciesErrors,
  );

  useEffect(() => {
    if (travelPolicyReason) {
      setReasonPolicyError(travelPolicyReason);
      setInitialReasonPolicyError(travelPolicyReason);
      setIsFilledButtonReasonPolicy(true);
    }
  }, [travelPolicyReason]);

  const handleReasonPolicyError = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    setReasonPolicyError(value);
    setIsFilledButtonReasonPolicy(value === initialReasonPolicyError || value.trim() === '');
  };

  const handleReasonPolicyErrorSave = async () => {
    try {
      await addReasonPolicyError({
        tripItemId,
        serviceType,
        body: {
          reason: reasonPolicyError,
        },
      }).unwrap();

      setInitialReasonPolicyError(reasonPolicyError);
      setIsFilledButtonReasonPolicy(true);
    } catch (e) {
      console.log(e);
    }
  };

  const renderContent = () => {
    if (!travelPoliciesErrors.length) return null;

    return (
      <div className={ styles.policy_container }>
        <div className={ styles.tooltip_policy }>
          <PolicyTooltip
            serviceType={ serviceType }
            namesErrorPolicies={ namesErrorPolicies }
            listTravelPolicies={ listTravelPolicies }
            travelPoliciesErrors={ travelPoliciesErrors }
          />
        </div>
        <Text type='SEMIBOLD_16'>
          {LABELS.REASON_TRAVEL_POLICY}
        </Text>
        <div className={ styles.reason_container }>
          <textarea
            value={ reasonPolicyError }
            placeholder={ LABELS.WRITE_REASON_TRAVEL_POLICY }
            className={ styles.textarea }
            onChange={ handleReasonPolicyError }
          />
          <Button
            type='secondary'
            disabled={ isFilledButtonReasonPolicy }
            className={ styles.button_save }
            onClick={ handleReasonPolicyErrorSave }
          >
            {LABELS.SAVE}
          </Button>
        </div>
      </div>
    );
  };

  return renderContent();
};
