import React from 'react';
import { Icon, Price, Stars, Text } from 'yw-ui';

import ColumnLayout from '../../Layout/ColumnLayout';

import { dayjsObject } from '@/app/bi/utils/formatDate.ts';
import { formattedDateFromTo } from '@/app/bi/utils/trip.ts';
import { getSearchDatesDiff } from '@/app/bi/utils/hotels.ts';
import { getEmployeeFullName } from '@/app/bi/utils/employees.ts';

import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy';

import { IHotelTripItems } from '@/app/bi/models/tripsTypes.ts';

import styles from './index.module.scss';

interface CardHotelProps {
  data: IHotelTripItems;
  isCard?: boolean;
}

const CardHotel = ({
  data: {
    checkinDate,
    checkoutDate,
    actualVersion: {
      hotelName,
      hotelStars,
      hotelAddress,
      hotelEmployees,
      roomName,
      roomAmenities,
      cartPrice,
      currency,
    },
  },
  isCard = false,
}: CardHotelProps) => {
  const dateFromAndTo = formattedDateFromTo(checkinDate, checkoutDate);
  const countDaysLiving = getSearchDatesDiff(dayjsObject(checkinDate), dayjsObject(checkoutDate));

  const renderPersonInfo = () => hotelEmployees?.map((
    person,
    index,
  ) => {
    const { id, firstName, lastName } = person.employeeVersion;

    const fullName = getEmployeeFullName({ firstName, lastName });

    return (
      <li key={ id }>
        <span className={ styles.passengerName }>
          {`${index + 1}. ${fullName}`}
        </span>
      </li>
    );
  });

  return (
    <div className={ styles.card }>
      {isCard && <Icon type='serviceHotel' size={ 40 }/>}
      <div className={ styles.content }>
        <div className={ styles.tickedInfoContainer }>
          {hotelStars > 0 && (
            <div className={ styles.stars_container }>
              <Stars count={ hotelStars } size={ 16 }/>
            </div>
          )}
          <div className={ styles.travelContainer }>
            <div className={ styles.travelInfo }>
              <Text type='bold_20' color='gray-7'>
                {hotelName}
              </Text>
              <Text type='SEMIBOLD_16' color='gray-7'>
                {countDaysLiving}, {roomName}
              </Text>
            </div>
            <Price
              value={ cartPrice }
              color='gray-7'
              type='bold_18'
              typeCurrency='bold_18'
              costType={ CURRENCY_SYMBOLS[currency] }
              marginSmall
            />
          </div>
          <div className={ styles.routeInfo }>
            <Text type='NORMAL_16' color='gray-6'>
              {hotelAddress}
            </Text>
            <Text type='NORMAL_16' color='gray-6'>
              {dateFromAndTo}
            </Text>
          </div>
          {!!roomAmenities.length && <ColumnLayout items={ roomAmenities }/>}
        </div>
        <div className={ styles.divider }/>
        <ul className={ styles.passengers }>
          {renderPersonInfo()}
        </ul>
      </div>
    </div>
  );
};

export { CardHotel };
