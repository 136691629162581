import { useEffect, useRef, useState } from 'react';

import { currentLng } from '@/i18n';

import { useDebounce } from '@/app/hooks/useDebounce';
import { useAppSelector } from '@/app/store/hooks/redux.ts';
import { useLazySearchHotelsBySearchIdQuery } from '@/app/bi/api/searchHotelsApi';

import { IHotelFilters } from '@/app/bi/models/hotelSearch/hotelSearchTypes';

const FILTER_DEBOUNCE = 400;

export const useFilters = () => {
  const { data } = useAppSelector((state) => state.hotelSlice);

  const [breakfastEnabled, setBreakfastEnabled] = useState(!!data?.filter.breakfastEnabled);
  const [onlyOnline, setOnlyOnline] = useState(!!data?.filter.onlyOnline);
  const [refundable, setRefundable] = useState(!!data?.filter.refundable);

  return {
    breakfastEnabled,
    onlyOnline,
    refundable,
    toggleBreakfastEnabled: () => setBreakfastEnabled((prev) => !prev),
    toggleOnlyOnline: () => setOnlyOnline((prev) => !prev),
    toggleRefundable: () => setRefundable((prev) => !prev),
  };
};

export const useFilterHotels = (filters: IHotelFilters) => {
  const isEnabled = useRef(false);
  const { data } = useAppSelector((state) => state.hotelSlice);

  const { breakfastEnabled, onlyOnline, refundable } = filters;

  const debouncedIsBreakfastEnabled = useDebounce(breakfastEnabled, FILTER_DEBOUNCE);
  const debouncedIsOnlyOnline = useDebounce(onlyOnline, FILTER_DEBOUNCE);
  const debouncedIsRefundable = useDebounce(refundable, FILTER_DEBOUNCE);

  const [searchHotelsBySearchId] = useLazySearchHotelsBySearchIdQuery();

  useEffect(() => {
    if (isEnabled.current && data) {
      searchHotelsBySearchId({ locale: currentLng,
        searchId: data.id,
        body: {
          breakfastEnabled: debouncedIsBreakfastEnabled,
          onlyOnline: debouncedIsOnlyOnline,
          refundable: debouncedIsRefundable,
        },
      }).unwrap();
    } else {
      isEnabled.current = true;
    }
  }, [debouncedIsBreakfastEnabled, debouncedIsOnlyOnline, debouncedIsRefundable]);
};
