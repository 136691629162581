import React, { ReactNode } from 'react';
import { Icon, Button, IconType } from 'yw-ui';

import { getText } from '@/i18n';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

import styles from './index.module.scss';

const ICONS: { [key in EServiceTypes]?: string } = {
  [EServiceTypes.Airline]: 'serviceAir',
  [EServiceTypes.Hotel]: 'serviceHotel',
  [EServiceTypes.Transfer]: 'serviceTransfer',
};

const LABELS = {
  DEFAULT: getText('common:search'),
};

interface SearchSubMenuProps {
  children: ReactNode,
  onClick(): void,
  serviceType: EServiceTypes,
  buttonLabel?: string,
  disabled?: boolean,
  searchButton?: boolean,
}

const SearchSubMenu = ({
  children,
  serviceType,
  buttonLabel = LABELS.DEFAULT,
  disabled = false,
  searchButton = true,
  onClick,
}: SearchSubMenuProps) => {
  const icon = (
    <div className={ styles.icon }>
      <Icon
        circleColor='white'
        className={ styles.img }
        type={ ICONS[serviceType] as IconType }
      />
    </div>
  );

  const button = searchButton && (
    <Button
      className={ styles.button }
      type='primary'
      onClick={ onClick }
      disabled={ disabled }
    >
      { buttonLabel }
    </Button>
  );

  return (
    <div className={ styles.submenu }>
      <div className={ styles.container }>
        { icon }
        { children }
        { button }
      </div>
    </div>
  );
};

export { SearchSubMenu };
