import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, ItemLoader, PageLoader, Paginate, Tag, Text, Tooltip } from 'yw-ui';

import { getText, getTextArray } from '@/i18n';

import { useDebounce } from '@/app/hooks/useDebounce.ts';
import { useAppSelector } from '@/app/store/hooks/redux.ts';
import { useEmployeesAutocompleteQuery } from '@/app/bi/api/tripApi.ts';
import { useGetEmployeesFilterQuery } from '@/app/bi/api/employeesApi.ts';

import { FilterEmployees } from '@/app/pages/Settings/pages/Employees/components/EmployeeFilter';
import { Table } from '@/app/components/Table';
import { EmptyDataPanel } from '@/app/components/EmptyDataPanel';

import {
  DEFAULT_FILTERS_EMPLOYEES,
  getEmployeeFullName,
  getUpdateFilterStatusEmployee,
  deleteFilterStatusEmployee,
} from '@/app/bi/utils/employees.ts';
import toPlural from '@/app/bi/utils/toPlural';

import ROUTES from '@/app/bi/constants/routes.ts';
import { BUTTON_CLEAR } from '@/app/bi/constants/settings.ts';
import { INPUT_EMPLOYEE_DEBOUNCE_MS } from '@/app/bi/constants/trips.ts';

import {
  ICompany,
  IEmployeeFilter,
  IEmployeeFilterResponse, IEmployeeObj,
} from '@/app/bi/models/employees.ts';
import { ITagFilter } from '@/app/bi/models/shared.ts';

import styles from './index.module.scss';

const LABELS = {
  ORGANIZATION: getText('settings:organization'),
  ADD_EMPLOYEES: getText('settings:addEmployee'),
  SEARCH_EMPLOYEES: getTextArray('settings:employeesSearch'),
  FOUND_EMPLOYEES: getText('settings:search'),
  EMPLOYEE: getText('settings:employee'),
  PHONE: getText('settings:phone'),
  EMAIL: getText('settings:email'),
  EMPLOYEES_NOT_FOUND: getText('settings:employeesNotFound'),
  ADD_EMPLOYEES_SUB_TITLE: getText('settings:addEmployees'),
  ADD_EMPLOYEE: getText('settings:addEmployee'),
  SUBMIT: getText('settings:submit'),
};

const Employees = () => {
  const navigate = useNavigate();

  const {
    account: {
      companies: companyValues,
    },
  } = useAppSelector((stateApp) => stateApp.appSlice);

  const [
    filter,
    setFilters,
  ] = useState<IEmployeeFilter>(DEFAULT_FILTERS_EMPLOYEES);
  const [
    query,
    setQuery,
  ] = useState<string>('');
  const debouncedQueryValue = useDebounce(query, INPUT_EMPLOYEE_DEBOUNCE_MS);
  const [
    filterStatus,
    setFilterStatus,
  ] = useState<ITagFilter[]>([]);

  const {
    data: responseData = {} as IEmployeeFilterResponse,
    isLoading,
    isFetching,
  } = useGetEmployeesFilterQuery(filter);

  const {
    data: autocompleteEmployees = [],
  } = useEmployeesAutocompleteQuery(debouncedQueryValue);

  const arrayColumn = [LABELS.EMPLOYEE, LABELS.PHONE, LABELS.EMAIL, LABELS.ORGANIZATION];
  const itemCount = query.length ? autocompleteEmployees.length : responseData.itemsCount;

  useEffect(() => {
    const newFilterState = getUpdateFilterStatusEmployee(
      filter,
      companyValues,
      query,
    );

    setFilterStatus(newFilterState);
  }, [filter, query]);

  if (isLoading) {
    return <PageLoader />;
  }

  const handleDeleteFilterStatus = (value: ITagFilter) => {
    const newFilterState = deleteFilterStatusEmployee(
      value,
      query,
      filter,
    );

    setQuery(newFilterState.query);
    setFilters(newFilterState.filter);
  };

  const handleResetAllFilter = () => {
    setQuery('');
    setFilters(DEFAULT_FILTERS_EMPLOYEES);
  };

  const handleLinkEmployee = (id: string) => {
    navigate(`${ROUTES.SETTINGS.EMPLOYEE}/${id}`);
  };

  const handleLinkNewEmployee = () => navigate(ROUTES.SETTINGS.EMPLOYEE);

  const handleSelectPage = (pageNumber: number) => setFilters(({ ...filter, page: pageNumber }));

  const renderFilterStatus = () => {
    if (!filterStatus.length) {
      return null;
    }

    const formationLabel = (statusLabel: string): string => {
      const maxLengthStatusName = 50;

      return statusLabel.length > maxLengthStatusName ? `${statusLabel.substring(0, maxLengthStatusName)}...` : statusLabel;
    };

    return (
      <div className={ styles.filter_status_container }>
        {filterStatus.map((status, index) => (
          <Tag
            key={ `${status.label}_${index}` }
            label={ formationLabel(status.label) }
            onClick={ () => handleDeleteFilterStatus(status) }
          />
        ))}
        <Tag
          label={ BUTTON_CLEAR }
          deleteAll
          className={ styles.button_clear_filter }
          onClick={ handleResetAllFilter }
        />
      </div>
    );
  };

  const renderTooltipContent = (companies: ICompany[]): ReactNode => (
    <Text color='white' type='NORMAL_14' className={ styles.container_tooltip_content }>
      {companies.map((item) => (
        <div className={ styles.name_company } key={ item.id }>{item.name}</div>
      ))}
    </Text>
  );

  const renderTooltipHeader = (companies: ICompany[]) => companies.map(({ id, name }, index) => {
    const lastIndexElement = index < companies.length - 1;

    return (
      <span key={ id }>
        {name}{lastIndexElement ? ', ' : ''}
      </span>
    );
  });

  const renderTripNameTooltip = (companies: ICompany[]) => (
    <Tooltip
      show
      position='bottom'
      renderContent={ () => renderTooltipContent(companies) }
    >
      <div className={ styles.term_employee_company }>
        {renderTooltipHeader(companies)}
      </div>
    </Tooltip>
  );

  const renderTableRows = (employeesArray: IEmployeeObj[]) => employeesArray.map(({
    actualVersion,
    companies,
    id,
  }) => {
    if (isFetching) {
      return (
        <div key={ id } className={ styles.loading_table }>
          <ItemLoader />
        </div>
      );
    }

    const {
      firstName,
      lastName,
      phoneNumber,
      email,
    } = actualVersion;

    const fullName = getEmployeeFullName({
      firstName,
      lastName,
    });

    return (
      <tr className={ styles.table } key={ id } onClick={ () => handleLinkEmployee(id) }>
        <td className={ styles.name }>{fullName}</td>
        <td className={ styles.phone_number }>{phoneNumber}</td>
        <td className={ styles.email }>{email}</td>
        <td className={ styles.company }>
          <div className={ styles.render_companies }>
            {companies.length === 1 ? (
              <span>{companies[0].name}</span>
            ) : (
              renderTripNameTooltip(companies)
            )}
          </div>
        </td>
      </tr>
    );
  });

  const renderTable = () => {
    if (!itemCount) {
      return (
        <div className={ styles.empty_panel }>
          <EmptyDataPanel
            title={ LABELS.EMPLOYEES_NOT_FOUND }
            subTitle={ LABELS.ADD_EMPLOYEES_SUB_TITLE }
            buttonTitle={ LABELS.ADD_EMPLOYEE }
            onChangeButton={ handleLinkNewEmployee }
          />
        </div>
      );
    }

    return (
      <Table
        column={ arrayColumn }
        rows={ () => renderTableRows(query.length ? autocompleteEmployees : responseData.items) }
      />
    );
  };

  return (
    <div className={ styles.content_container }>
      <FilterEmployees
        filterValues={ filter }
        companyValues={ companyValues }
        inputValue={ query }
        setFilters={ setFilters }
        setInputValues={ setQuery }
        onChangeClearFilter={ handleResetAllFilter }
      />
      <div className={ styles.table_container }>
        <div className={ styles.wrap }>
          <div className={ styles.container_info_trips }>
            <div className={ styles.info_trip }>
              <div className={ styles.info_title }>
                <span className={ styles.count_employees }>
                  {itemCount} {toPlural(itemCount, LABELS.SEARCH_EMPLOYEES)} {LABELS.FOUND_EMPLOYEES}
                </span>
              </div>
            </div>
            <IconButton iconType='add' iconColor='blue1' onClick={ handleLinkNewEmployee }>
              {LABELS.ADD_EMPLOYEES}
            </IconButton>
          </div>
          {renderFilterStatus()}
        </div>
        {renderTable()}
        <Paginate
          page={ filter.page }
          total={ itemCount }
          itemsPerPage={ responseData.pageLimit }
          onChange={ handleSelectPage }
        />
      </div>
    </div>
  );
};

export { Employees };
