import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Dialog, Icon, Input, ItemLoader, Text } from 'yw-ui';

import { IconType } from 'yw-ui/components/Icon/types';
import { getText } from '@/i18n';

import { isValidateEmail } from '@/app/bi/utils/login.ts';

import { PLACEHOLDER_INPUT_SEND_EMAIL, SERVICE_ICON } from '@/app/bi/constants/trips.ts';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

import styles from './index.module.scss';

const LABELS = {
  SEND_TO_EMAIL: getText('trip:documents.sendToEmail'),
  DOWNLOAD: getText('trip:documents.download'),
  WRITE_EMAIL_EMPLOYEE: getText('trip:writeEmailEmployee'),
  INVALID_FORMAT_DATE: getText('common:invalidFormatData'),
  SEND: getText('common:send'),
  UNDO: getText('common:undo'),
};

interface TripCardLayoutProps {
  type: EServiceTypes;
  tickedId: string;
  ticketNumber?: string;
  isResponseId: boolean;
  inputValue: string;
  isSendEmail: boolean;
  modalActive: boolean;
  renderHeader: ReactNode;
  onDownloadTrip(ticketID: string, tripNumber: string, serviceType: EServiceTypes): void;
  onSendEmail(cardId: string, serviceType: EServiceTypes): void;
  onChangeInput(value: string): void;
  onChangeIsActiveModal(value: boolean): void;
  children: React.ReactNode;
}

const TripCardLayout = ({
  type,
  tickedId,
  ticketNumber = '',
  isResponseId,
  inputValue,
  isSendEmail,
  modalActive,
  renderHeader,
  onSendEmail,
  onDownloadTrip,
  onChangeInput,
  onChangeIsActiveModal,
  children,
}: TripCardLayoutProps) => {
  const [error, setError] = useState<string>('');

  const isSendButtonDisable = error !== '' || !inputValue.trim() || isSendEmail;

  useEffect(() => {
    setError('');
  }, [modalActive]);

  const handleOnBlur = (value: string) => {
    const emptyString = '';
    const isValid = isValidateEmail(value);

    setError(isValid ? emptyString : LABELS.INVALID_FORMAT_DATE);
  };

  const handleInputValue = (value: string) => {
    handleOnBlur(value);
    onChangeInput(value);
  };

  const handleButtonDownload = () => onDownloadTrip(tickedId, ticketNumber, type);

  const renderServicesIcon = (
    <div className={ styles.icon_wrapper }>
      <Icon type={ SERVICE_ICON[type] as IconType } size={ 24 }/>
    </div>
  );

  const renderButtonSendEmail = () => (
    <Button
      type='text'
      onClick={ () => onChangeIsActiveModal(true) }
      className={ styles.button_header }
    >
      {LABELS.SEND_TO_EMAIL}
    </Button>
  );

  const renderDialog = () => (
    <Dialog
      className={ styles.modal }
      show={ modalActive }
      showClosing
      onChange={ onChangeIsActiveModal }
    >
      <Text type='bold_20'>{LABELS.SEND_TO_EMAIL}</Text>
      <div className={ styles.content }>
        <Text type='NORMAL_16' className={ styles }>
          {LABELS.WRITE_EMAIL_EMPLOYEE}
        </Text>
        <Input
          type='email'
          disabled={ isSendEmail }
          value={ inputValue }
          onChange={ handleInputValue }
          placeholder={ PLACEHOLDER_INPUT_SEND_EMAIL }
          error={ error }
        />
      </div>
      <div className={ styles.button_container }>
        <Button
          type='secondary'
          loading={ isSendEmail }
          disabled={ isSendButtonDisable }
          onClick={ () => onSendEmail(tickedId, type) }
        >
          {LABELS.SEND}
        </Button>
        <Button type='text' onClick={ () => onChangeIsActiveModal(false) }>
          {LABELS.UNDO}
        </Button>
      </div>
    </Dialog>
  );

  const renderButtonDownload = () => (
    <Button
      type='text'
      className={ styles.button_download }
      onClick={ handleButtonDownload }
    >
      {isResponseId ? (
        <div className={ styles.loading_container }><ItemLoader /></div>
      ) : LABELS.DOWNLOAD}
    </Button>
  );

  return (
    <div className={ styles.container }>
      <div className={ styles.header_wrapper }>
        <div className={ styles.header }>
          <div className={ styles.title_container }>
            {renderServicesIcon}
            {renderHeader}
          </div>
          <div className={ styles.header_button_container }>
            {renderButtonSendEmail()}
            <div className={ styles.container_button_download }>
              {renderButtonDownload()}
            </div>
          </div>
        </div>
      </div>
      <div className={ styles.content_container }>
        {children}
      </div>
      {renderDialog()}
    </div>
  );
};

export { TripCardLayout };
