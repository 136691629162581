import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer, Marker } from '@googlemaps/markerclusterer';

import { MarkerState, MarkerStateDispatch } from './type';

export const useClusterer = () => {
  const map = useMap();

  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  return clusterer;
};

export const useMarkers = (): [MarkerState, MarkerStateDispatch] => {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});

  const clusterer = useClusterer();

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  return [markers, setMarkers];
};

export const useMarkerRef = () => {
  const [, setMarkers] = useMarkers();

  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((prev) => {
      if ((marker && prev[key]) || (!marker && !prev[key])) return prev;

      if (marker) {
        return { ...prev, [key]: marker };
      }
      const { [key]: _, ...newMarkers } = prev;

      return newMarkers;
    });
  }, []);

  return setMarkerRef;
};
