import React, {
  useState, useRef, useEffect,
} from 'react';
import { Slider, Input, Text } from 'yw-ui';

import { getText } from '@/i18n';

import MoneyFormat from '../../bi/utils/money';

import { EInputTypes } from '@/app/bi/models/shared.ts';

import styles from './index.module.scss';

const VALUES_TYPES = {
  MIN: 'MIN',
  MAX: 'MAX',
};

const DEFAULT_STEP = 1;

const LABELS = {
  FROM: getText('components:timeSlider.from'),
  TO: getText('components:timeSlider.to'),
};

interface SliderComponentProps {
  min: number | undefined;
  max: number | undefined;
  start: number;
  end: number;
  step?: number;
  subText?: string;
  inputType?: string;
  onSlide(array: number[], number?: number): void;
  validateFn?(text?: string): boolean;
}

const SliderComponent = ({
  min = 0,
  max = 0,
  start,
  end,
  step = DEFAULT_STEP,
  subText = '',
  inputType = EInputTypes.Text,
  onSlide,
  validateFn = () => false,
}: SliderComponentProps) => {
  const prevProps = useRef({ start, end });
  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [localStart, setLocalStart] = useState(start);
  const [localEnd, setLocalEnd] = useState(end);

  useEffect(() => {
    if (prevProps.current.start !== start) {
      setLocalStart(start);
    }

    if (prevProps.current.end !== end) {
      setLocalEnd(end);
    }

    prevProps.current = {
      start,
      end,
    };
  }, [start, end]);

  const handleSlide = ({ min: cMin, max: cMax }: { min: number, max: number }) => {
    setLocalStart(cMin);
    setLocalEnd(cMax);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => onSlide([cMin, cMax]), 300);
  };

  const handleChangeInput = (value: string, type: string) => {
    const rawValue = value.replace(/[\s.,]/g, '');
    const numValue = Number(rawValue) || 0;

    if (type === VALUES_TYPES.MIN && numValue > end) {
      return handleSlide({ min: end, max: end });
    }

    if (type === VALUES_TYPES.MAX && numValue > end) {
      return handleSlide({ min: start, max: numValue });
    }

    return type === VALUES_TYPES.MIN
      ? handleSlide({ min: numValue, max: end })
      : handleSlide({ min: start, max: numValue });
  };

  const preparedMinValue = MoneyFormat.money(Math.floor(localStart));
  const preparedMaxValue = MoneyFormat.money(Math.ceil(localEnd));
  const sliderValue = { min: localStart, max: localEnd };

  return (
    <div className={ styles.wrapper }>
      <Slider
        className={ styles.slider }
        min={ min }
        max={ max }
        value={ sliderValue }
        step={ step }
        onChange={ handleSlide }
        debounceMs={ 0 }
      />
      <div className={ styles.description }>
        <div className={ styles.input_block }>
          <Text type='NORMAL_14' className={ styles.text }>
            { LABELS.FROM }
          </Text>
          <Input
            type={ inputType }
            value={ preparedMinValue }
            debounceMs={ 0 }
            className={ styles.input }
            subText={ subText }
            validateFn={ validateFn }
            withValidation
            onChange={ (value) => handleChangeInput(value, VALUES_TYPES.MIN) }
          />
        </div>
        <div className={ styles.input_block }>
          <Text type='NORMAL_14' className={ styles.text }>
            { LABELS.TO }
          </Text>
          <Input
            type={ inputType }
            value={ preparedMaxValue }
            debounceMs={ 0 }
            className={ styles.input }
            subText={ subText }
            withValidation
            onChange={ (value) => handleChangeInput(value, VALUES_TYPES.MAX) }
            validateFn={ validateFn }
          />
        </div>
      </div>
    </div>
  );
};

export { SliderComponent };
