import React, { useState } from 'react';

import { Textarea, Text, Button } from 'yw-ui';
import styles from './index.module.scss';
import { getText } from '@/i18n';
import { useConversationMutation } from '@/app/bi/api/intercomApi.ts';
import { useAppSelector } from '@/app/store/hooks/redux.ts';
import { useIntercom } from '@/app/hooks/useIntercom.ts';
import CONFIG from '@/config.ts';

const LABELS = {
  TITLE: getText('components:menu.transfer.title'),
  PLACEHOLDER: getText('components:menu.transfer.placeholder'),
  TEXT: getText('components:menu.transfer.text'),
  SEND: getText('components:menu.transfer.send'),
};
const TransferSearchMenu = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { showMessages } = useIntercom();
  const [message, setMessage] = useState('');
  const [send, { isLoading }] = useConversationMutation();

  const handleSend = async () => {
    const body = {
      text: message,
      email: user?.email,
    };

    if (CONFIG.INTERCOM.SWITCHON) {
      try {
        await send(body).unwrap();
        showMessages();
        setMessage('');
      } catch {}
    }
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.header } >
        <Text type='NORMAL_24' fontWeight={ 700 } lineHeight={ '130%' }>
          { LABELS.TITLE }
        </Text>
        <Text type='NORMAL_14' lineHeight={ '130%' }>
          { LABELS.TEXT }
        </Text>
      </div>
      <div className={ styles.content }>
        <Textarea
          noneResize
          className={ styles.message }
          placeholder={ LABELS.PLACEHOLDER }
          value={ message }
          debounceMs={ 0 }
          onChange={ setMessage }
        />
      </div>
      <div className={ styles.actions }>
        <Button
          type='secondary'
          size='small'
          disabled={ message.length < 1 }
          loading={ isLoading }
          onClick={ handleSend }
        >
          {LABELS.SEND}
        </Button>
      </div>

    </div>
  );
};

export { TransferSearchMenu };
