import React from 'react';
import { Button, Icon, Text } from 'yw-ui';

import { IconType } from 'yw-ui/components/Icon/types';

import styles from './index.module.scss';

interface EmptyDataProps {
  iconType?: IconType;
  size?: number
  title: string;
  subTitle?: string;
  buttonTitle?: string;
  onChangeButton?(): void
}

const EmptyDataPanel = ({
  iconType = 'bigSearch',
  size = 60,
  title,
  subTitle = '',
  buttonTitle = '',
  onChangeButton = () => {},
}: EmptyDataProps) => {
  const renderButton = buttonTitle && (
    <Button type='outline' onClick={ onChangeButton }>{buttonTitle}</Button>
  );

  return (
    <div className={ styles.container }>
      <Icon type={ iconType } size={ size } />
      <div className={ styles.textContainer }>
        <Text type='bold_18'>{title}</Text>
        <Text type='NORMAL_14'>{subTitle}</Text>
      </div>
      {renderButton}
    </div>
  );
};

export { EmptyDataPanel };
