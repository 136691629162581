import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemLoader, Text, TripItem } from 'yw-ui';
import { TFunction } from 'i18next';

import { useGetLatestEditTripsQuery } from '@/app/bi/api/homeApi.ts';

import { toLocalDate } from '@/app/bi/utils/formatDate';

import ROUTES from '@/app/bi/constants/routes.ts';
import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  TITLE: t('components:recentTrips.title'),
});

const RecentTrips = () => {
  const { data = [], isLoading } = useGetLatestEditTripsQuery();

  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  if (!data.length) return null;

  if (isLoading) {
    return (
      <div className={ styles.loading_trips }>
        <ItemLoader />
      </div>
    );
  }

  const renderTripsItems = () => data?.map((item) => {
    const changedDate = toLocalDate(item.changedDate);
    const currency = CURRENCY_SYMBOLS[item.currency];

    return (<TripItem
      key={ item.id }
      value={ { ...item, changedDate, currency } }
      url={ `${ROUTES.TRIP.MAIN}/${item.id}` }
    />);
  });

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_24' color='blue-2'>
        {LABELS.TITLE}
      </Text>
      <div className={ styles.content }>
        {renderTripsItems()}
      </div>
    </div>
  );
};

export { RecentTrips };
