import React from 'react';

import { Text } from 'yw-ui';

import styles from './index.module.scss';

interface FieldLabelProps {
  text: string;
  isRequired?: boolean;
}

const FieldLabel = ({ text, isRequired = false }: FieldLabelProps) => (
  <Text className={ styles.label }>
    { text }
    { isRequired && <span className={ styles.must }>*</span> }
  </Text>
);

export { FieldLabel };
