import React, { useMemo } from 'react';
import { Collapse } from 'yw-ui';
import { useTranslation } from 'react-i18next';

import type { TFunction } from 'i18next';

import { TimeSlider } from '@/app/components/TimeSlider';

import { IFilterTransferDuration } from '@/app/bi/models/airlineTypes.ts';

import styles from '../../index.module.scss';

const createLabels = (t: TFunction) => ({
  TRANSFER_DURATION: t('air:result.filters.transferDuration'),
});

interface TransferDurationFilterProps {
  transferDuration: IFilterTransferDuration,
  values: IFilterTransferDuration
  onChange(value: { min: number; max: number }): void,
}

const TransferDurationFilter = ({
  transferDuration: {
    min,
    max,
  },
  values,
  onChange,
}: TransferDurationFilterProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  if (min !== max) {
    return (
      <Collapse
        opened
        className={ styles.item }
        key={ `flight_duration_${min}_${max}}` }
        label={ LABELS.TRANSFER_DURATION }
      >
        <TimeSlider
          min={ min }
          max={ max }
          from={ values.min }
          to={ values.max }
          onSlide={ onChange }
        />
      </Collapse>
    );
  }

  return null;
};

export { TransferDurationFilter };
