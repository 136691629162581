import React, { ReactNode, useState } from 'react';
import { Button, Checkbox, Collapse, Input, RadioButton } from 'yw-ui';

import { getText } from '@/i18n';

import { MAX_COUNT_DATA_BEFORE_SCROLL } from '@/app/bi/constants/trips';
import { FILTER_DEFAULT_COUNT_ITEMS, THROTTLE_DELAY_INPUT_FILTER } from '@/app/bi/constants/common.ts';
import { EItemFilterPanel, EModeViewFilterPanel } from '@/app/bi/constants/settings.ts';

import { IFilterItem } from '@/app/bi/models/app.ts';

import styles from './index.module.scss';

const LABELS = {
  OPEN_MORE: getText('trips:openMore'),
  HIDDEN_MORE: getText('trips:hiddenMore'),
};

interface ItemFilterProps {
  data?: IFilterItem[];
  title: string;
  placeholder?: string;
  filterType?: EModeViewFilterPanel;
  itemFilterType?: EItemFilterPanel;
  onChange?(value: IFilterItem): void;
  valueInput?: string;
  onInputChange?(value: string): void;
}

const ItemFilter = ({
  data = [],
  title = '',
  placeholder = '',
  filterType = EModeViewFilterPanel.ViewList,
  itemFilterType = EItemFilterPanel.CheckBox,
  onChange = () => {},
  valueInput = '',
  onInputChange = () => {},
}:ItemFilterProps) => {
  const dataCount = data.length;
  const isActiveButton = dataCount > FILTER_DEFAULT_COUNT_ITEMS;

  const [openList, setOpenList] = useState<boolean>(false);

  const handleOpenList = () => setOpenList(!openList);

  const switchButton = (item: IFilterItem, listLength: number, index: number) => {
    const isCheckBox = itemFilterType === EItemFilterPanel.CheckBox;

    return (
      index < listLength && (isCheckBox ? (
        <Checkbox
          className={ styles.checkbox }
          key={ `direct_count__${item}_${index}` }
          value={ item.checked }
          onChange={ () => onChange(item) }
          themes='dark'
        >
          {item.label}
        </Checkbox>
      ) : (
        <RadioButton
          key={ `radio__${item}_${index}` }
          className={ styles.checkbox }
          onChange={ () => onChange(item) }
        >
          {item.label}
        </RadioButton>
      )
      )
    );
  };

  const renderItems = () => {
    const listViewLength = !openList ? FILTER_DEFAULT_COUNT_ITEMS : dataCount;

    return (
      <>
        {data.map((item, index) => (
          <React.Fragment key={ index }>
            {switchButton(item, listViewLength, index)}
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderListItems = (): ReactNode => {
    if (dataCount >= MAX_COUNT_DATA_BEFORE_SCROLL && openList) {
      return <div className={ styles.scrollContainer }>{renderItems()}</div>;
    }

    return renderItems();
  };

  const renderButton = (): ReactNode => isActiveButton && (
    <Button type='text' className={ styles.buttonMore } onClick={ handleOpenList }>
      {openList ? LABELS.HIDDEN_MORE : LABELS.OPEN_MORE}
    </Button>
  );

  const isSearchView = (): ReactNode => (
    <div className={ styles.searchContainer }>
      <Input
        isCleansing
        debounceMs={ THROTTLE_DELAY_INPUT_FILTER }
        className={ styles.search }
        value={ valueInput }
        placeholder={ placeholder }
        onChange={ onInputChange }
      />
    </div>
  );

  const switchModeView = () => {
    switch (filterType) {
      case EModeViewFilterPanel.ViewList:
        return (
          <>
            {renderListItems()}
            {renderButton()}
          </>
        );
      case EModeViewFilterPanel.SearchAndList:
        return (
          <>
            {isSearchView()}
            {renderListItems()}
            {renderButton()}
          </>
        );
      case EModeViewFilterPanel.SearchOnly:
        return isSearchView();
      default:
        return renderListItems();
    }
  };

  return (
    <Collapse label={ title } moreOpen={ openList }>
      {switchModeView()}
    </Collapse>
  );
};

export { ItemFilter };
