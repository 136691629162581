import React from 'react';
import clsx from 'clsx';

import { EmployeeInfoList } from '@/app/components/EmployeeInfoList';
import { ItemLayout } from '@/app/pages/Cart/components/ItemLayout';
import { AirlineItem } from '@/app/components/AirlineItem';
import { HotelItem } from '@/app/components/HotelItem';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import {
  ICartResponseAirlineItem,
  ICartResponseEmployee,
  ICartResponseHotelItem,
  TCartResponseItem,
} from '@/app/bi/models/cart.ts';
import { IEmployeeUniversal } from '@/app/bi/models/employees.ts';

import styles from './index.module.scss';

interface CheckoutItemProps {
  index: number;
  item: TCartResponseItem;
}

const CheckoutItem = ({
  index,
  item,
}: CheckoutItemProps) => {

  const wrapClasses = clsx([styles.wrap], {
    [styles.divider]: index !== 0,
  });

  const renderItem = () => {
    switch (item.serviceType) {
      case EServiceTypes.Airline: {
        const airlineItem = item as ICartResponseAirlineItem;

        return airlineItem.routes.map((route) => (
          <AirlineItem
            key={ route.id }
            route={ route }
          />
        ));
      }
      case EServiceTypes.Hotel: {
        return <HotelItem item={ item as ICartResponseHotelItem }/>;
      }
      default:
        return null;
    }
  };

  const renderEmployees = () => {
    if (!item.employees.length) return null;

    const transformEmployeesUniversal = (array: ICartResponseEmployee[]): IEmployeeUniversal[] => array.map(({
      id,
      company,
      firstName,
      lastName,
      document,
    }) => ({
      id,
      lastName,
      firstName,
      number: document?.number || null,
      type: document?.type || null,
      company: {
        id: company.id,
        name: company.name,
        shortName: company.shortName || '',
      },
    }));

    const arrayEmployee = transformEmployeesUniversal(item.employees);

    return (
      <EmployeeInfoList employees={ arrayEmployee } />
    );
  };

  return (
    <div className={ wrapClasses }>
      <ItemLayout
        serviceType={ item.serviceType }
        price={ item.price }
        currency={ item.currency }
      >
        {renderItem()}
        {renderEmployees()}
      </ItemLayout>
    </div>
  );
};

export { CheckoutItem };
