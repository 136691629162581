import React from 'react';
import { Text, Progress } from 'yw-ui';
import { ProgressSpeed } from 'yw-ui/components/Progress/types';
import { getText } from '@/i18n';

import styles from './index.module.scss';

const LABELS = {
  BOOKING: getText('booking:title'),
};

interface ProgressBarProps {
  progressSpeed: ProgressSpeed,
  progressValue: number,
  onClick(): void,
}
const ProgressBar = ({
  progressSpeed,
  progressValue,
  onClick,
}: ProgressBarProps) => {
  const catchAndStopEvent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={ styles.overlay }
      onClick={ catchAndStopEvent }
    >
      <div className={ styles.panel } onClick={ onClick }>
        <Progress speed={ progressSpeed } value={ progressValue } animation />
        <div className={ styles.title }>
          <Text type={ 'NORMAL_18' }>{ LABELS.BOOKING }</Text>
        </div>
      </div>
    </div>
  );
};

export { ProgressBar };
