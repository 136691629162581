import { Dayjs } from 'dayjs';
import toDecline from '@/app/bi/utils/toDecline.ts';
import { getText, getTextArray } from '@/i18n';
import { IDisplayFilters, IHotelSearchFilter } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import MoneyFormat from '@/app/bi/utils/money.ts';
import { HOTEL_TYPES } from '@/app/bi/constants/hotelsSearch.ts';
import { RATING } from '@/app/bi/constants/hotels.ts';
import { ITag } from '@/app/bi/models/shared.ts';
import { ECurrencyCode, EHotelSortedBystring } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

export const HOTELS_FILTER_TYPE = {
  PRICE_FOR_NIGHT: 'priceForNight',
  DISTANCE_TO_CENTER: 'distanceToCenter',
  ONLY_ONLINE: 'onlyOnline',
  REFUNDABLE: 'refundable',
  BREAKFAST_ENABLED: 'breakfastEnabled',
  STARS: 'stars',
  RATING: 'rating',
  HOTEL_TYPES: 'hotelTypes',
  HOTEL_NAME: 'hotelName',
};

const TAGS_LABELS = {
  PRICE_FOR_NIGHT_FROM_TO: (from: string, to: string) =>
    getText('hotels:regionResult.tags.priceFromTo', { from, to }),
  DISTANCE_TO_CENTER_FROM_TO: (from: string, to: string) =>
    getText('hotels:regionResult.tags.distanceFromCenter', { from, to }),
  ONLY_ONLINE: getText('hotels:regionResult.tags.onlyOnline'),
  FREE_CANCELLATION: getText('hotels:regionResult.tags.freeCancellation'),
  BREAKFAST_ENABLED: getText('hotels:regionResult.tags.mealIncluded'),
  STARS: (count: string) => getText('hotels:regionResult.tags.stars', { count }),
  RATING: (rating: string) => getText('hotels:regionResult.tags.rating', { rating }),
  HOTEL_NAME: (hotelName: string) => getText('hotels:regionResult.tags.hotelName', { hotelName }),
};

export const getSearchDatesDiff = (checkin: Dayjs, checkout: Dayjs) => {
  const nightsCount = checkout.endOf('day').diff(checkin, 'days');
  const label = toDecline(nightsCount, getTextArray('utils:hotel.nightDeclines'));

  return `${nightsCount} ${label}`;
};

export const changeFilter = <T>(countValueFilter: T[] | null, selectedFilter: T): T[] => {
  if (!countValueFilter) return [selectedFilter];

  const isSelectedFilter = countValueFilter.find((value) => value === selectedFilter);

  if (!isSelectedFilter) return [...countValueFilter, selectedFilter];

  return countValueFilter.filter((value) => value !== isSelectedFilter);
};

export const createTags = (filters: IDisplayFilters, filtersData: IHotelSearchFilter, currency: string) => {
  const tags = [];

  if ((filters.priceForNight.min !== filtersData.priceForNight.min
    || filters.priceForNight.max !== filtersData.priceForNight.max
  )) {
    tags.push({
      name: `${TAGS_LABELS.PRICE_FOR_NIGHT_FROM_TO(
        MoneyFormat.money(filtersData.priceForNight.min),
        MoneyFormat.money(filtersData.priceForNight.max),
      )} ${currency}`,
      key: HOTELS_FILTER_TYPE.PRICE_FOR_NIGHT,
      filter: HOTELS_FILTER_TYPE.PRICE_FOR_NIGHT,
    });
  }

  if ((filters.distanceToCenter.min !== filtersData.distanceToCenter.min
    || filters.distanceToCenter.max !== filtersData.distanceToCenter.max
  )) {
    tags.push({
      name: TAGS_LABELS.DISTANCE_TO_CENTER_FROM_TO(
        filtersData.distanceToCenter.min.toString(),
        filtersData.distanceToCenter.max.toString(),
      ),
      key: HOTELS_FILTER_TYPE.DISTANCE_TO_CENTER,
      filter: HOTELS_FILTER_TYPE.DISTANCE_TO_CENTER,
    });
  }

  if (filtersData.onlyOnline) {
    tags.push({
      name: TAGS_LABELS.ONLY_ONLINE,
      filter: HOTELS_FILTER_TYPE.ONLY_ONLINE,
      key: HOTELS_FILTER_TYPE.ONLY_ONLINE,
    });
  }

  if (filtersData.refundable) {
    tags.push({
      name: TAGS_LABELS.FREE_CANCELLATION,
      filter: HOTELS_FILTER_TYPE.REFUNDABLE,
      key: HOTELS_FILTER_TYPE.REFUNDABLE,
    });
  }

  if (filtersData.breakfastEnabled) {
    tags.push({
      name: TAGS_LABELS.BREAKFAST_ENABLED,
      filter: HOTELS_FILTER_TYPE.BREAKFAST_ENABLED,
      key: HOTELS_FILTER_TYPE.BREAKFAST_ENABLED,
    });
  }

  if (filtersData.hotelName) {
    tags.push({
      name: TAGS_LABELS.HOTEL_NAME(filtersData.hotelName),
      filter: HOTELS_FILTER_TYPE.HOTEL_NAME,
      key: HOTELS_FILTER_TYPE.HOTEL_NAME,
    });
  }

  if (filtersData.stars.length) {
    filtersData.stars.forEach((star) => {
      tags.push({
        // @ts-ignore
        name: TAGS_LABELS.STARS(star),
        filter: HOTELS_FILTER_TYPE.STARS,
        key: star,
      });
    });
  }

  if (filtersData.rating.length) {
    filtersData.rating.forEach((item) => {
      tags.push({
        name: TAGS_LABELS.RATING(RATING[item]),
        filter: HOTELS_FILTER_TYPE.RATING,
        key: item,
      });
    });
  }

  if (filtersData.hotelTypes.length) {
    filtersData.hotelTypes.forEach((hotelType) => {
      tags.push({
        name: HOTEL_TYPES[hotelType],
        filter: HOTELS_FILTER_TYPE.HOTEL_TYPES,
        key: hotelType,
      });
    });
  }

  return tags;
};

export const deleteFilter = (tag: ITag, filters: IHotelSearchFilter, displayFilters: IDisplayFilters): IHotelSearchFilter => {
  switch (tag.filter) {
    case HOTELS_FILTER_TYPE.ONLY_ONLINE:
    case HOTELS_FILTER_TYPE.BREAKFAST_ENABLED:
    case HOTELS_FILTER_TYPE.REFUNDABLE: {
      return {
        ...filters,
        [tag.filter]: false,
      };
    }
    case HOTELS_FILTER_TYPE.PRICE_FOR_NIGHT:
    case HOTELS_FILTER_TYPE.DISTANCE_TO_CENTER: {
      return {
        ...filters,
        [tag.filter]: displayFilters[tag.filter as keyof typeof displayFilters],
      };
    }
    case HOTELS_FILTER_TYPE.STARS: {
      return {
        ...filters,
        stars: filters.stars.filter((star) => star !== tag.key),
      };
    }
    case HOTELS_FILTER_TYPE.RATING: {
      return {
        ...filters,
        rating: filters.rating.filter((rating) => rating !== tag.key),
      };
    }
    case HOTELS_FILTER_TYPE.HOTEL_TYPES: {
      return {
        ...filters,
        hotelTypes: filters.hotelTypes.filter((hotelType) => hotelType !== tag.key),
      };
    }
    case HOTELS_FILTER_TYPE.HOTEL_NAME: {
      return {
        ...filters,
        hotelName: '',
      };
    }
    default: {
      return filters;
    }
  }
};

export const resetFilters = (displayFilters: IDisplayFilters): IHotelSearchFilter => ({
  searchSort: {
    sortedBy: EHotelSortedBystring.Price,
    descending: false,
  },
  currencyCode: displayFilters?.currencyCode as ECurrencyCode,
  distanceToCenter: {
    min: displayFilters?.distanceToCenter.min as number,
    max: displayFilters?.distanceToCenter.max as number,
  },
  priceForNight: {
    min: displayFilters?.priceForNight.min as number,
    max: displayFilters?.priceForNight.max as number,
  },
  stars: [],
  rating: [],
  hotelName: '',
  breakfastEnabled: false,
  travelPolicyId: null,
  refundable: false,
  onlyOnline: false,
  hotelTypes: [],
  amenities: [],
  page: 1,
});
