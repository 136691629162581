import React from 'react';
import { Text, Tooltip } from 'yw-ui';
import clsx from 'clsx';

import { TextColor } from 'yw-ui/src/components/Text/types.ts';
import { getText } from '@/i18n';

import toDecline from '../../../../bi/utils/toDecline';

import { TechnicalStopText } from '../Text';

import styles from './style.module.scss';

const LABELS = {
  WITHOUT_TRANSFERS: getText('components:airlineSearchItem.airItem.withoutTransfers'),
  TECHNICAL_STOP_DECLINES: ['техническая посадка', 'технические посадки'],
};

interface TechnicalStopTooltipProps {
  changeCount: number,
  route: object,
  className?: string,
}

const TechnicalStopTooltip = ({ changeCount, route, className = '' }: TechnicalStopTooltipProps) => {
  // @ts-ignore
  const technicalStopsCount = route.Segments[0].TechnicalStop.length;
  const mayHaveRenderTechnicalStops = !!technicalStopsCount && !changeCount;

  if (!mayHaveRenderTechnicalStops) return null;

  const renderTooltipTechnicalStop = (technicalStop: never[], textColor: TextColor) => {
    if (!technicalStop.length) return null;

    return (
      <div className={ styles.tooltip }>
        <TechnicalStopText className={ styles.text } technicalStop={ technicalStop } textColor={ textColor } />
      </div>
    );
  };

  return (
    <div className={ styles.technical_stop_wrapper }>
      <Text type='NORMAL_14' color='gray'>{ LABELS.WITHOUT_TRANSFERS }</Text>
      <Tooltip
        className={ styles.technical_stop }
        // @ts-ignore
        renderContent={ () => renderTooltipTechnicalStop(route.Segments[0].TechnicalStop, 'white') }
        position='bottom'
      >
        <Text
          className={ clsx([styles.title], { [className]: !!className.length }) }
          type='NORMAL_14'
          color='gray'
        >
          { technicalStopsCount }
          {' '}
          { toDecline(technicalStopsCount, LABELS.TECHNICAL_STOP_DECLINES) }
        </Text>
      </Tooltip>
    </div>
  );
};

export { TechnicalStopTooltip };
