import React from 'react';
import { RadioButton, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { ETypesSex } from '@/app/bi/constants/settings.ts';

import styles from './index.module.scss';

const LABELS = {
  MAN: getText('components:sexSwitcher.man'),
  WOMAN: getText('components:sexSwitcher.woman'),
};

interface SexSwitcherProps {
  sex: ETypesSex | null,
  onChange(sex: ETypesSex): void
}

const SexSwitcher = ({ sex, onChange }: SexSwitcherProps) => {
  const isCheckedMan = sex === ETypesSex.Man;
  const isCheckedWoman = sex === ETypesSex.Woman;

  const handleFocus = () => {
    if (!sex) {
      onChange(ETypesSex.Man);
    }
  };

  return (
    <div className={ styles.switcher }>
      <RadioButton
        value={ ETypesSex.Man }
        checked={ isCheckedMan }
        onChange={ () => onChange(ETypesSex.Man) }
        onFocus={ handleFocus }
        className={ styles.radio }
      >
        <Text>
          { LABELS.MAN }
        </Text>
      </RadioButton>
      <RadioButton
        value={ ETypesSex.Woman }
        checked={ isCheckedWoman }
        onChange={ () => onChange(ETypesSex.Woman) }
        className={ styles.radio }
      >
        <Text>
          { LABELS.WOMAN }
        </Text>
      </RadioButton>
    </div>
  );
};

export { SexSwitcher };
