import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Collapse } from 'yw-ui';

import { PriceSlider } from '@/app/components/PriceSlider';

import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum';

import { IFilterPrice } from '@/app/bi/models/airlineTypes.ts';

import styles from '../../index.module.scss';

const createLabels = (t: TFunction) => ({
  PRICE: t('air:result.filters.price'),
});

interface PriceFilterProps {
  price: IFilterPrice,
  values: IFilterPrice,
  currencyCode: ECurrencyCode
  onChange(value: number[]): void;
}

const PriceFilter = ({
  price: {
    min,
    max,
  },
  values,
  currencyCode,
  onChange,
}: PriceFilterProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const handleChangePrice = (array: number[]) => {
    const newArray = array.map((item) => Number(item.toFixed(2)));

    onChange(newArray);
  };

  return (
    <Collapse
      opened
      className={ styles.item }
      label={ LABELS.PRICE }
    >
      <PriceSlider
        min={ min }
        max={ max }
        start={ values.min }
        end={ values.max }
        onSlide={ handleChangePrice }
        currencyCode={ currencyCode }
      />
    </Collapse>
  );
};

export { PriceFilter };
