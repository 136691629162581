import React from 'react';
import { Price } from 'yw-ui';

import { getText } from '@/i18n';

import CardAirLineList from '../CardAirLineList';
import ColumnLayout from '../../../../Layout/ColumnLayout';

import { secondsToLabel } from '@/app/bi/utils/time';
import { formattedDateFromTo } from '@/app/bi/utils/trip';
import { formatHourFromHourTo } from '@/app/bi/utils/formatDate';

import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy.ts';

import { IAirlineRoute } from '@/app/bi/models/cart';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './index.module.scss';

const LABELS = {
  CONNECTING_FLIGHT: (countSegments: number) => getText('trip:documents.connectingFlight', { countSegments }),
  NON_STOP_FLIGHT: getText('trip:documents.nonStopFlight'),
};

const typeFlight = (segmentsCount: number) => (segmentsCount > 1
  ? LABELS.CONNECTING_FLIGHT(segmentsCount - 1)
  : LABELS.NON_STOP_FLIGHT);

interface ICardAirLineBodyProps {
  isPriceRequired: boolean;
  route: IAirlineRoute;
  price: number;
  currency: ECurrencyCode,
  rulesList: string[]
}

const CardAirLineBody = ({
  isPriceRequired,
  price,
  route,
  rulesList,
  currency,
}: ICardAirLineBodyProps) => {
  const segmentsCount = route.segments.length;

  const { departureDate, departureCity, departureAirportCityCode } = route.segments[0];
  const { arrivalDate, arrivalCity, arrivalAirportCityCode } = route.segments[segmentsCount - 1];

  const dateFromAndTo = formattedDateFromTo(departureDate, arrivalDate);
  const departure = `${departureCity}, ${departureAirportCityCode}`;
  const arrival = `${arrivalCity}, ${arrivalAirportCityCode}`;
  const formationHourDate = formatHourFromHourTo(departureDate, arrivalDate);

  return (
    <div className={ styles.card } key={ route.id }>
      <div className={ styles.content }>
        <CardAirLineList segments={ route.segments }/>
        <div className={ styles.ticket_info_container }>
          <div className={ styles.travel_wrapper }>
            <div className={ styles.travel_container }>
              <span className={ styles.travel_title }>{`${departure} — ${arrival}`}</span>
              {isPriceRequired &&
                <Price
                  value={ price }
                  color='gray-7'
                  type='bold_18'
                  typeCurrency='bold_18'
                  marginSmall
                  costType={ CURRENCY_SYMBOLS[currency] }
                />
              }
            </div>
            <div className={ styles.time_info }>
              <span className={ `${styles.travelTime} ${styles.dot}` }>
                {formationHourDate}
              </span>
              <span className={ styles.list_item }>
                {secondsToLabel(route.totalDuration)}
              </span>
              <span className={ styles.list_item }>
                {typeFlight(segmentsCount)}
              </span>
            </div>
          </div>
          <div className={ styles.route_info }>
            <span>{dateFromAndTo}</span>
          </div>
          <ColumnLayout items={ rulesList } />
        </div>
      </div>
    </div>
  );
};

export type { ICardAirLineBodyProps };
export default CardAirLineBody;
