import React from 'react';

import { FilterPanel } from '@/app/components/FilterPanel';
import { TransferAirportsFilter } from '@/app/pages/AirlineResult/components/Filters/components/TransferAirports';
import { DirectCountFilter } from '@/app/pages/AirlineResult/components/Filters/components/DirectCount';
import { FlightsNumbersFilter } from '@/app/pages/AirlineResult/components/Filters/components/FlightsNumbers';
import { PriceFilter } from '@/app/pages/AirlineResult/components/Filters/components/Price';
import { BaggageFilter } from '@/app/pages/AirlineResult/components/Filters/components/Baggage';
import { FlightDurationFilter } from '@/app/pages/AirlineResult/components/Filters/components/FlightDuration';
import { AirlineCompaniesFilter } from '@/app/pages/AirlineResult/components/Filters/components/AirlineCompanies';
import { TransferDurationFilter } from '@/app/pages/AirlineResult/components/Filters/components/TransferDuration';
import { BorderTimeFilter } from '@/app/pages/AirlineResult/components/Filters/components/BorderTime';
import { TravelPolicyFilter } from '@/app/pages/AirlineResult/components/Filters/components/TravelPolicy';

import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum';

import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { IAirlineSearchFilter, IFilterBaseMinMax, IRouteFilters } from '@/app/bi/models/airlineTypes.ts';

import styles from './index.module.scss';

interface AirlineFiltersProps {
  filters: IAirlineSearchFilter;
  filtersValues: IAirlineSearchFilter;
  listTravelPolicies: ITravelPolicy[];
  selectPolicy: { id: string, policyName: string, isActive: boolean };
  currencyCode: ECurrencyCode;
  setIsSelectPolicy(value: { id: string, policyName: string, isActive: boolean }): void;
  setFilters(filters: IAirlineSearchFilter): void;
}

const AirlineFilters = ({
  filters,
  filtersValues,
  listTravelPolicies,
  selectPolicy,
  currencyCode,
  setIsSelectPolicy,
  setFilters,
}: AirlineFiltersProps) => {
  const handleCountFilter = (transfersCount: number[]) => {
    setFilters(({ ...filtersValues, transfersCount }));
  };

  const handleFlightNumbers = (flightNumbers: string[]) => {
    setFilters(({ ...filtersValues, flightNumbers }));
  };

  const handleChangePrice = (value: number[]) => {
    const [min, max] = value;

    setFilters(({ ...filtersValues, price: { min, max } }));
  };

  const handleChangeBaggage = (value: string[]) => {
    setFilters(({ ...filtersValues, baggage: value }));
  };

  const handleFlightDuration = ({ min, max }: { min: number; max: number }) => {
    setFilters(({ ...filtersValues, flightDuration: { min, max } }));
  };

  const handleTransferDuration = ({ min, max }: IFilterBaseMinMax) => {
    setFilters(({ ...filtersValues, transferDuration: { min, max } }));
  };

  const handleBorderTimeFilter = (routeFilters: IRouteFilters[]) => {
    setFilters(({ ...filtersValues, routeFilters }));
  };

  const handleAirlineCompanies = (airlines: string[]) => {
    setFilters(({ ...filtersValues, airlines }));
  };

  const handleTransferAirports = (transferAirports: string[]) => {
    setFilters(({ ...filtersValues, transferAirports }));
  };

  const handleIsSelectTravelPolicy = (policy: ITravelPolicy) => {
    setFilters(({ ...filtersValues, travelPolicyId: null }));
    setIsSelectPolicy({ id: policy.id, policyName: policy.name, isActive: true });
  };

  const handleTravelPolicy = (policy: ITravelPolicy) => {
    setFilters(({ ...filtersValues, travelPolicyId: policy.id }));
    setIsSelectPolicy({ id: policy.id, policyName: policy.name, isActive: false });
  };

  const renderTravelPolicyFilter = () => (listTravelPolicies.length ? (
    <TravelPolicyFilter
      selectPolicyId={ filtersValues.travelPolicyId }
      listPolicies={ listTravelPolicies }
      selectPolicy={ selectPolicy }
      setIsSelectPolicy={ handleIsSelectTravelPolicy }
      onChange={ handleTravelPolicy }
    />
  ) : null);

  return (
    <div className={ styles.wrap }>
      <FilterPanel>
        {renderTravelPolicyFilter()}
        <DirectCountFilter
          transfersCount={ filters.transfersCount }
          values={ filtersValues.transfersCount }
          onChange={ handleCountFilter }
        />
        <FlightsNumbersFilter
          flightNumbers={ filters.flightNumbers }
          values={ filtersValues.flightNumbers }
          onChange={ handleFlightNumbers }
        />
        <PriceFilter
          price={ filters.price }
          values={ filtersValues.price }
          onChange={ handleChangePrice }
          currencyCode={ currencyCode }
        />
        <BaggageFilter
          baggage={ filters.baggage }
          values={ filtersValues.baggage }
          onChange={ handleChangeBaggage }
        />
        <FlightDurationFilter
          arrivalCity={ filters.arrivalCity }
          departureCity={ filters.departureCity }
          flightDuration={ filters.flightDuration }
          values={ filtersValues.flightDuration }
          onChange={ handleFlightDuration }
        />
        <TransferDurationFilter
          transferDuration={ filters.transferDuration }
          values={ filtersValues.transferDuration }
          onChange={ handleTransferDuration }
        />
        <BorderTimeFilter
          routeFilters={ filters.routeFilters }
          values={ filtersValues.routeFilters }
          onChange={ handleBorderTimeFilter }
        />
        <AirlineCompaniesFilter
          airlines={ filters.airlines }
          values={ filtersValues.airlines }
          onChange={ handleAirlineCompanies }
        />
        <TransferAirportsFilter
          airports={ filters.transferAirports }
          values={ filtersValues.transferAirports }
          onChange={ handleTransferAirports }
        />
      </FilterPanel>
    </div>
  );
};

export { AirlineFilters };
