import React, { createRef, RefObject, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import queryString from 'query-string';
import { PageLoader } from 'yw-ui';

import { useAppSelector } from '@/app/store/hooks/redux.ts';
import { useSearchHotels, useInitSearch } from './hook.ts';

import { HotelInformation } from './components/HotelInformation/index.tsx';
import { HotelSearchMenu } from '../../components/Menu/HotelSearchMenu';

import { mapStateToHotelPageSearchSettings } from '@/app/bi/utils/hotelsSearch.ts';

import ROUTES from '@/app/bi/constants/routes.ts';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  SEARCH: t('hotels:hotelResult.loader'),
});

const HotelResultPage = () => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const navigate = useNavigate();

  useInitSearch();
  const { isLoading, search } = useSearchHotels();

  const { data } = useAppSelector((state) => state.hotelSlice);
  const {
    checkin,
    checkout,
    adult,
    region,
    customCheckin,
    customCheckout,
    travellersCount,
  } = useAppSelector((state) => state.searchHotelsReducer);

  const buttonsRefs: RefObject<HTMLButtonElement>[] = [createRef(), createRef()];
  const openDatePickerFrom = useRef<((mode: string) => void) | null>(null);
  const openDatePickerTo = useRef<((mode: string) => void) | null>(null);

  const renderContent = () => {
    if (isLoading || !data) {
      return (
        <div className={ styles.loading_wrap }>
          <PageLoader text={ LABELS.SEARCH }/>
        </div>
      );
    }

    return (
      <HotelInformation
        data={ data }
        handleOpenDatePickerFrom={ handleOpenDatePickerFrom }
        handleOpenDatePickerTo={ handleOpenDatePickerTo }
        buttonsRefs={ buttonsRefs }
      />
    );
  };

  const handleSearch = () => {
    if (region?.selected?.isRegion) {
      return navigate(ROUTES.SEARCH.HOTELS_REGION);
    }

    const searchParams = mapStateToHotelPageSearchSettings({
      checkin,
      checkout,
      adult,
      regionName: region?.selected?.name as string,
      regionId: data?.searchRequest.regionId || '',
      customCheckin,
      customCheckout,
      travellersCount,
    });

    search({ ...searchParams, guests: searchParams.guestsCount });

    return navigate({
      pathname: `${ROUTES.SEARCH.HOTEL_PAGE}/${region?.selected?.id}`,
      search: `${queryString.stringify({ ...searchParams })}`,
    });
  };

  const handleSetFnOpenDatePickerFrom = (fn: (mode: string) => void) => {
    openDatePickerFrom.current = fn;
  };

  const handleSetFnOpenDatePickerTo = (fn: (mode: string) => void) => {
    openDatePickerTo.current = fn;
  };

  const handleOpenDatePickerFrom = () => {
    if (openDatePickerFrom.current) {
      openDatePickerFrom.current('time');
    }
  };

  const handleOpenDatePickerTo = () => {
    if (openDatePickerTo.current) {
      openDatePickerTo.current('time');
    }
  };

  return (
    <div className={ styles.wrap }>
      <HotelSearchMenu
        subMenu
        onSearchSubMenu={ handleSearch }
        onOpenDatePickerFrom={ handleSetFnOpenDatePickerFrom }
        onOpenDatePickerTo={ handleSetFnOpenDatePickerTo }
        buttonsRefs={ buttonsRefs }
      />
      {renderContent()}
    </div>
  );
};

export { HotelResultPage };
