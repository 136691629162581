import React from 'react';

import { getImageLogo } from '@/app/bi/utils/airline';

import { IAirlineRouteSegment } from '@/app/bi/models/cart';

import styles from './index.module.scss';

interface CardAirLineListProps {
  segments: IAirlineRouteSegment[]
}

type AirlinesList = Record<string, { operatingAirlineName: string, flightNumbers: string[] }>;

const getAirlinesList = (segments: CardAirLineListProps['segments']) => {
  const airlines: AirlinesList = {};

  segments.forEach((segment) => {
    const { operatingAirlineCode, operatingAirlineName, flightNumber } = segment;
    const title = `${operatingAirlineCode} ${flightNumber}`;

    if (operatingAirlineCode in airlines) {
      airlines[operatingAirlineCode].flightNumbers.push(title);
    } else {
      airlines[operatingAirlineCode] = { operatingAirlineName, flightNumbers: [title] };
    }
  });

  return Object
    .entries(airlines)
    .map(([code, title]) => (
      { title: `${title.operatingAirlineName} (${title.flightNumbers.join(', ')})`, code }
    ));
};

const CardAirLineList = ({ segments }: CardAirLineListProps) => {
  const airlines = getAirlinesList(segments);

  return (
    <div className={ styles.airlines_container }>
      {airlines.map(({ code, title }) => <div className={ styles.airline } key={ code }>
        <img
          className={ styles.airline_img }
          src={ getImageLogo(code) }
          title={ title }
          alt={ title }
        />
        <span className={ styles.airline_title }>{title}</span>
      </div>)}
    </div>
  );
};

export type { CardAirLineListProps };
export default CardAirLineList;
