import React, { useMemo } from 'react';
import { NoResults, Text } from 'yw-ui';
import { useTranslation } from 'react-i18next';

import type { TFunction } from 'i18next';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  CHANGE_PARAMS_OF_SEARCH: t('air:result.changeParamsOfSearch'),
  NOT_FOUND: t('air:result.notFound'),
});

const NoResultsAirline = () => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  return (
    <NoResults
      renderContent={ () => (
        <div className={ styles.no_results }>
          <Text type='bold_18'>
            { LABELS.NOT_FOUND }
          </Text>
          <Text className={ styles.subtext }>
            { LABELS.CHANGE_PARAMS_OF_SEARCH }
          </Text>
        </div>
      ) }
    />
  );
};

export { NoResultsAirline };
