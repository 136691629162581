import React, { useMemo } from 'react';
import { Icon, Text, Tooltip } from 'yw-ui';
import clsx from 'clsx';

import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { TechnicalStopTooltip } from '@/app/components/AirlineTechnicalStop/components/Tooltip';
import { AirlineItemSegment } from '../../../AirlineItemSegment';

import { dateWithoutDayjs } from '@/app/bi/utils/formatDate.ts';
import {
  segmentsArrivalCity,
  mappedSegments,
  getTerminals,
  isArrivalChanged,
  isDepartureChanged,
  getNameByFirstSegment,
  getImageLogo,
} from '@/app/bi/utils/airline.ts';
import { secondsToLabel } from '@/app/bi/utils/time.ts';
import toDecline from '../../../../bi/utils/toDecline.ts';

import { TRANSFER } from '@/app/bi/constants/airline.ts';
import { DATE_FORMATS, PATTERN } from '@/app/bi/constants/dateFormats.ts';

import { Route } from '@/app/bi/models/airlineTypes.ts';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  IN_CITY: t('components:airlineSearchItem.airItem.inCity'),
  IN_CITIES: t('components:airlineSearchItem.airItem.inCities'),
  ATTENTION: t('components:airlineSearchItem.airItem.tooltip.attention'),
  CHANGING_AIRPORT: t('components:airlineSearchItem.airItem.tooltip.changingAirport'),
  WITHOUT_TRANSFERS: t('components:airlineSearchItem.airItem.withoutTransfers'),
});

interface AirItemProps {
  route: Route,
  showDetails: boolean,
  lastChild: boolean,
  onShowSegments(): void,
}

const AirItem = ({
  route,
  showDetails,
  lastChild,
  onShowSegments,
}: AirItemProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const renderSegments = () => {
    const { segments } = route;

    const segmentsHtml = segments.map((segment, index) => {
      const { arrival, departure } = getTerminals(segment, index, segments);

      const departureChanged = isDepartureChanged(index, segment, segments);
      const arrivalChanged = isArrivalChanged(index, segment, segments);

      return (
        <AirlineItemSegment
          key={ `segment_${index}` }
          segment={ segment }
          departureChanged={ departureChanged }
          arrivalChanged={ arrivalChanged }
          arrivalTerminal={ arrival }
          departureTerminal={ departure }
          opts={ mappedSegments(segment) }
        />
      );
    });

    return (
      <div
        onClick={ onShowSegments }
        className={ styles.segments }
      >
        { segmentsHtml }
      </div>
    );
  };

  const renderTooltip = (infoCity: string[]) => {
    const text = infoCity.length === 1
      ? ` ${LABELS.IN_CITY} ${infoCity[0]}`
      : ` ${LABELS.IN_CITIES} ${infoCity.join(', ')}`;

    const tooltip = () => (
      <Text
        type='NORMAL_14_130'
        color='white'
        className={ styles.tooltip_box }
      >
        <div>{ LABELS.ATTENTION }</div>
        <div>
          { LABELS.CHANGING_AIRPORT }
          { text }
        </div>
      </Text>
    );

    return (
      <Tooltip
        className={ styles.transfer_icon }
        renderContent={ tooltip }
      >
        <Icon type='warning' />
      </Tooltip>
    );
  };

  const renderCity = (city: string, airportId: string) => (
    <Text className={ styles.city }>
      { city }
      ,
      { airportId }
    </Text>
  );

  const renderSingleIcon = ({ code, name }: { code: string, name: string }) => (
    <div className={ styles.large_img_wrap }>
      <img
        className={ styles.large_img }
        src={ getImageLogo(code) }
        title={ name }
        alt={ name }
      />
    </div>
  );

  const renderMultipleIcons = (list: { code: string, name: string }[] = []) => (
    <div className={ styles.image_list }>
      {list.map(({ code, name }) => (
        <img
          key={ code }
          className={ styles.small_img }
          src={ getImageLogo(code) }
          title={ name }
          alt={ name }
        />
      ))}
    </div>
  );

  const renderIcons = () => {
    const reducedSegmentsList =
      route.segments.reduce<{ code: string, name: string }[]>((acc, {
        marketingAirline,
      }) => {
        const { code: searchCode, name } = marketingAirline;

        if (acc.findIndex(({ code }) => searchCode === code) >= 0) {
          return acc;
        }

        return [
          ...acc,
          { code: searchCode, name },
        ];
      }, []);

    if (reducedSegmentsList.length === 1) {
      return renderSingleIcon({ ...reducedSegmentsList[0] });
    }

    return renderMultipleIcons(reducedSegmentsList);
  };

  const renderTransfer = (
    changeCount: number,
    infoCity: string[],
    sumChangeDuration: number,
  ) => {
    const questionHtml = !!infoCity.length && renderTooltip(infoCity);

    if (changeCount) {
      return (
        <div className={ styles.transfer }>
          <Text
            type='NORMAL_14'
            color={ infoCity.length ? 'red' : 'gray' }
          >
            {`${changeCount} ${toDecline(changeCount, TRANSFER)} (${secondsToLabel(sumChangeDuration)})`}
          </Text>
          { questionHtml }
        </div>
      );
    }

    return !route.segments[0].technicalStop?.length && (
      <Text type='NORMAL_14' color='gray'>
        { LABELS.WITHOUT_TRANSFERS }
      </Text>
    );
  };

  const renderTechnicalStopTooltip = () => !!route.segments[0].technicalStop?.length && (
    <TechnicalStopTooltip
      changeCount={ changeCount }
      route={ route }
      className={ styles.technical_title }
    />
  );

  const wrappedClassName = clsx([styles.wrapper], {
    [styles.last]: lastChild,
  });

  const { segments } = route;
  const firstSegment = segments[0];
  const lastSegment = segments[segments.length - 1];
  const routeDurationHtml = secondsToLabel(route.duration, true);

  const changeCount = segments.length - 1;
  const sumChangeDuration = segments
    .reduce((duration, { transferDuration }) => duration + transferDuration, 0);
  const infoCity = segmentsArrivalCity(segments);
  const detailsHtml = showDetails && renderSegments();
  const filterAirlines = segments.filter(({ marketingAirline }) =>
    marketingAirline.code === firstSegment.marketingAirline.code);

  const flightNumbers = segments.map(({ marketingAirline, flightNumber }) =>
    `${marketingAirline.code} ${flightNumber}`).join(', ');

  const airlineName = getNameByFirstSegment(firstSegment);

  const airlineNameWithNumbers = segments.length === 1 || (segments.length === 2 && filterAirlines.length === 2)
    ? `${airlineName} (${flightNumbers})`
    : flightNumbers;

  const departure = renderCity(firstSegment.departureCity, firstSegment.departureAirport.code);
  const arrival = renderCity(lastSegment.arrivalCity, lastSegment.arrivalAirport.code);

  // @ts-ignore
  const technicalStopWrappedStyles = segments[0].technicalStop?.length
    ? styles.duration_with_technical_stop
    : styles.duration;

  const departureDate = `
    ${dateWithoutDayjs(firstSegment.departureDateTime)}, ${dateWithoutDayjs(firstSegment.departureDateTime, PATTERN.WEEK)}
  `;

  return (
    <div className={ wrappedClassName }>
      <div className={ styles.icon }>
        { renderIcons() }
      </div>
      <div className={ styles.wrapper_info }>
        <div className={ styles.info }>
          <div className={ styles.col_departure }>
            <Text type='bold_22'>
              { dateWithoutDayjs(firstSegment.departureDateTime, DATE_FORMATS.TIME) }
            </Text>
            { departure }
            <Text type='NORMAL_14' color='gray'>
              { departureDate }
            </Text>
          </div>
          <div className={ styles.col_airline }>
            <Text type='NORMAL_14' color='gray'>
              { airlineNameWithNumbers }
            </Text>
            <Text type='SEMIBOLD_16' className={ technicalStopWrappedStyles }>
              { routeDurationHtml }
            </Text>
            { renderTransfer(changeCount, infoCity, sumChangeDuration) }
            { renderTechnicalStopTooltip() }
          </div>
          <div className={ styles.col_arrival }>
            <Text type='bold_22'>
              { dateWithoutDayjs(lastSegment.arrivalDateTime, DATE_FORMATS.TIME) }
            </Text>
            { arrival }
            <Text type='NORMAL_14' color='gray'>
              { dateWithoutDayjs(lastSegment.arrivalDateTime) }
              ,
              { dateWithoutDayjs(lastSegment.arrivalDateTime, PATTERN.WEEK) }
            </Text>
          </div>
        </div>
        { detailsHtml }
      </div>
    </div>
  );
};

export { AirItem };
