import { Text } from 'yw-ui';
import React from 'react';

import styles from './index.module.scss';

interface INotificationItemProps {
  locations?: string;
  date: string;
  detail: string;
}

const NotificationItem = ({
  locations = '',
  date,
  detail,
}: INotificationItemProps) => (
  <div className={ styles.wrap }>
    <Text type='NORMAL_16'>{locations}</Text>
    <Text className={ styles.details }>
      <span className={ styles.dot }>{date}</span>
      <span className={ styles.dot }>{detail}</span>
    </Text>
  </div>
);

export { NotificationItem };
