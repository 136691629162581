import React from 'react';
import { Icon, Text } from 'yw-ui';

import { isNull } from '@/app/bi/utils/isNull.ts';

import styles from './index.module.scss';

interface SortArrowOptionProps {
  item: {
    label: string,
    value: string,
    type?: number
  }
}

const SortArrowOption = ({ item }: SortArrowOptionProps) => {
  const { type, label } = item;

  const hasType = !isNull(type);
  const typeIsUp = hasType && !!type;

  const iconStyles = [styles.icon];

  if (!typeIsUp) {
    iconStyles.push(styles.down);
  }

  return (
    <Text
      type='NORMAL_16'
      className={ styles.item }
    >
      { label }
      { hasType && (
        <Icon
          type='arrowThinUp'
          className={ iconStyles.join(' ') }
        />
      ) }
    </Text>
  );
};
export { SortArrowOption };
