import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BackLink, Button, IconButton, PageLoader, Text } from 'yw-ui';
import { SuggestItem } from 'yw-ui/components/Suggest/types';

import { FormProvider } from 'react-hook-form';
import { currentLng, getText } from '@/i18n';

import {
  useAddEmployeeMutation,
  useLazyGetCitizenshipQuery,
  useLazyGetEmployeeByIdQuery,
  useUpdateEmployeeMutation,
} from '@/app/bi/api/employeeApi.ts';
import {
  addPassport,
  clearDataIdCard,
  clearDocuments,
  deletePassport,
  setCountry,
  setEmployeeData,
  setInitialState,
  setOrganization,
  setTravelPolicy,
} from '@/app/store/redusers/EmployeeSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import { useGetTravelPoliciesQuery } from '@/app/bi/api/travelPoliciesApi.ts';

import { IdCard } from '@/app/pages/Employee/components/IdCard';
import { Document } from '@/app/pages/Employee/components/Document';
import { GeneralStep } from '@/app/pages/Employee/components/GeneralStep';
import { FormContainer } from '@/app/pages/Employee/components/FormContainer';
import { TravelPolicyBlock } from '@/app/pages/Employee/components/TravelPolicyBlock';

import { notification } from '@/app/bi/utils/not.ts';
import { getEmployeeFullName } from '@/app/bi/utils/employees.ts';
import {
  formationDocumentsAddedVersion,
  formationDocumentsUpdateVersion,
  isDocumentsFilled,
  isGeneralFilled,
  isValidIdCard,
  mapCompaniesToData,
  mapTravelPoliciesToData,
  transformationAddedVersion,
  transformationDocuments,
  transformationUpdateVersion,
} from '@/app/bi/utils/employee.ts';
import { getDayjs, getKeepLocalDate } from '@/app/bi/utils/formatDate.ts';

import ROUTES from '@/app/bi/constants/routes.ts';
import {
  ENotificationActionType,
  MAX_COUNT_DOCUMENT,
} from '@/app/bi/constants/settings.ts';
import COUNTRIES from '@/app/bi/constants/countries.ts';

import {
  EEmployeeDocumentStatus,
  EEmployeeDocumentType,
  IEmployeeDocumentModified,
} from '@/app/bi/models/employees.ts';
import { IAddEmployeeRequest, IEmployeePolicyItem, IUpdateEmployeeRequest } from '@/app/bi/models/employee.ts';
import { ICitizenshipItem } from '@/app/bi/models/profile.ts';

import styles from './index.module.scss';
import AdditionallySection from './components/AdditionallySection';
import { useBonusCards } from './components/BonusCard/hook';

const LABELS = {
  DOCUMENTS: getText('settings:travelers.documents'),
  PASSWORD: getText('settings:trPassport'),
  INTERNATIONAL_PASSPORT: getText('settings:internationalPassport'),
  EMPLOYEES: getText('settings:dynamicPages.employees'),
  PERSONAL_DATA: getText('settings:personalData'),
  GENERAL_DATA: getText('settings:generalData'),
  CANCEL: getText('settings:companiesData.cancel'),
  ADD_TO_PASSWORD: getText('settings:addToPassword'),
  ADD_EMPLOYEES_PROCESS: getText('settings:addEmployeesProcess'),
  TURKEY: getText('settings:turkey'),
  SAVE: getText('settings:companiesData.save'),
  CARD_EMPLOYEE: {
    SUCCESS_CREATE: {
      TITLE: getText('settings:cardEmployee.successCreate.title'),
      MESSAGE: (fullName: string) => getText(
        'settings:cardEmployee.successCreate.message', { fullName }),
    },
    SUCCESS_UPDATE: {
      TITLE: getText('settings:cardEmployee.successUpdate.title'),
      MESSAGE: (fullName: string) => getText(
        'settings:cardEmployee.successUpdate.message', { fullName }),
    },
    ERROR_NOT_CREATE: {
      TITLE: getText('settings:cardEmployee.errorNotCreate.title'),
      MESSAGE: (fullName: string) => getText(
        'settings:cardEmployee.errorNotCreate.message', { fullName }),
    },
    ERROR_NOT_SAVE: {
      TITLE: getText('settings:cardEmployee.errorNotSave.title'),
      MESSAGE: (fullName: string) => getText(
        'settings:cardEmployee.errorNotCreate.message', { fullName }),
    },
  },
};

export const Employee = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { employeeId = '' } = useParams();

  const isCreate = !employeeId;
  const { EMPLOYEES } = ROUTES.SETTINGS;
  const {
    Archived,
    Active,
  } = EEmployeeDocumentStatus;
  const {
    generalData,
    generalData: {
      lastName,
      firstName,
      birthday,
      citizenship,
      citizenship: {
        code: citizenshipCode,
        code3: citizenshipCode3,
      },
      countryCode,
      contactData: {
        organization,
      },
      travelPolicyId: {
        value: travelPolicyId,
      },
    },
    idCard,
    idCardArchive,
    idCardArchive: {
      actualVersion: {
        status: idCardArchiveStatus,
      },
    },
    documents = [],
    lastCountryCode,
  } = useAppSelector((state) => state.employeeSlice);
  const companiesApp = useAppSelector((state) => state.appSlice.account.companies);
  const isTurkeyCountry = citizenshipCode3 === COUNTRIES.TR.CODE3;
  const isFillingButtonAddPassport = documents.length === MAX_COUNT_DOCUMENT;
  const documentTitle = isTurkeyCountry ? LABELS.PASSWORD : LABELS.INTERNATIONAL_PASSPORT;
  const employeeFullName = getEmployeeFullName({ firstName, lastName });
  const formationPageName = !employeeId ? LABELS.ADD_EMPLOYEES_PROCESS : employeeFullName;
  const isFilled = isGeneralFilled(generalData) && isDocumentsFilled(documents, idCard, !isTurkeyCountry);

  const { methods, getBonusCards } = useBonusCards();

  const [
    getEmployeeById, {
      isLoading: loadingGetEmployee,
      isSuccess: isSuccessGetEmployee,
    },
  ] = useLazyGetEmployeeByIdQuery();
  const [
    getCitizenship, {
      isLoading: loadingCitizenship,
      isSuccess: isSuccessCitizenship,
    },
  ] = useLazyGetCitizenshipQuery();
  const { data: listPolicies = [] } = useGetTravelPoliciesQuery();
  const [addEmployee] = useAddEmployeeMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();

  const [
    companies,
    setCompanies,
  ] = useState<SuggestItem[]>([]);
  const [
    listCountryCode,
    setListCountryCode,
  ] = useState<ICitizenshipItem[]>([]);
  const [
    travelPolicies,
    setTravelPolicies,
  ] = useState<IEmployeePolicyItem[]>([]);

  const getEmployeeData = async (id: string) => {
    try {
      const employees = await getEmployeeById(id).unwrap();

      dispatch(setEmployeeData(employees));
      dispatch(setOrganization(employees.companies.map(({ id: companyId }) => companyId)));
    } catch (e) {
      dispatch(setInitialState());
    }
  };

  const getCitizenshipData = async () => {
    try {
      const result = await getCitizenship(currentLng).unwrap();

      setListCountryCode(result);
    } catch (e) {
      setListCountryCode([]);
    }
  };

  useEffect(() => {
    if (companiesApp.length) {
      const arrayTransformation = mapCompaniesToData(companiesApp);

      setCompanies(arrayTransformation);
    }
  }, [companiesApp]);

  useEffect(() => {
    if (listPolicies.length) {
      const arrayTransformation = mapTravelPoliciesToData(listPolicies);

      arrayTransformation.forEach((policy) => {
        if (policy.value === travelPolicyId) {
          dispatch(setTravelPolicy(policy));
        }
      });

      setTravelPolicies(arrayTransformation);
    }
  }, [listPolicies, travelPolicyId]);

  useEffect(() => {
    if (!isCreate) {
      getEmployeeData(employeeId);
    }

    getCitizenshipData();

    return () => { dispatch(setInitialState()); };
  }, []);

  useEffect(() => {
    if (countryCode) {
      listCountryCode.forEach((item) => {
        if (item.code.toLowerCase() === countryCode.toLowerCase()) {
          dispatch(setCountry(item));
        }
      });
    }
  }, [countryCode, dispatch, listCountryCode]);

  useEffect(() => {
    if (citizenship.code3 !== lastCountryCode) {
      dispatch(clearDataIdCard());
      dispatch(clearDocuments());
    }
  }, [lastCountryCode]);

  if ((loadingGetEmployee && !isSuccessGetEmployee) || (loadingCitizenship && !isSuccessCitizenship)) {
    return <PageLoader />;
  }

  const handleAddEmployee = async () => {
    const updateIdCard: IEmployeeDocumentModified = {
      ...idCard,
      actualVersion: {
        ...idCard.actualVersion,
        countryCode: lastCountryCode || idCard.actualVersion.countryCode,
      },
    };
    const validIdCard = isValidIdCard(updateIdCard, isTurkeyCountry);
    const formationDocumentsVersions = validIdCard ? [...documents, updateIdCard] : documents;
    const requestData: IAddEmployeeRequest = {
      ...generalData,
      ...generalData.contactData,
      travelPolicyId,
      birthday: getKeepLocalDate(birthday || getDayjs()),
      countryCode: citizenshipCode,
      status: Active,
      documentVersions: transformationDocuments(formationDocumentsVersions, citizenshipCode),
      companyIds: organization,
      bonusCards: getBonusCards(),
    };

    try {
      await addEmployee(requestData).unwrap();

      notification({
        title: LABELS.CARD_EMPLOYEE.SUCCESS_CREATE.TITLE,
        content: (
          <Text type='NORMAL_16' color='gray-7'>
            {LABELS.CARD_EMPLOYEE.SUCCESS_CREATE.MESSAGE(employeeFullName)}
          </Text>
        ),
        type: ENotificationActionType.success,
      });

      navigate(EMPLOYEES, { state: { isModal: true, fullName: employeeFullName } });
    } catch (e) {
      notification({
        title: LABELS.CARD_EMPLOYEE.ERROR_NOT_CREATE.TITLE,
        content: (
          <Text type='NORMAL_16' color='gray-7'>
            {LABELS.CARD_EMPLOYEE.ERROR_NOT_CREATE.MESSAGE(employeeFullName)}
          </Text>
        ),
        type: ENotificationActionType.error,
      });
    }
  };

  const handleUpdateEmployeeInfo = async () => {
    const updateIdCard: IEmployeeDocumentModified = {
      ...idCard,
      actualVersion: {
        ...idCard.actualVersion,
        countryCode: lastCountryCode || idCard.actualVersion.countryCode,
      },
    };
    const updateIdCardArchive: IEmployeeDocumentModified = {
      ...idCardArchive,
      actualVersion: {
        ...idCardArchive.actualVersion,
        countryCode: lastCountryCode,
      },
    };
    const isIdCardCreation = updateIdCard.id === '0';
    const idCardIdEmployeeDocumentId = updateIdCard.actualVersion.employeeDocumentId.length;
    const idCardArchivedOrActive = idCardArchiveStatus === Archived ? updateIdCardArchive : updateIdCard;
    const countryCodeSwitch = lastCountryCode.length ? lastCountryCode : citizenshipCode;

    const formationAddedVersion = formationDocumentsAddedVersion(
      isIdCardCreation ? [...documents, updateIdCard] : documents,
    );

    const formationUpdateVersion = formationDocumentsUpdateVersion(
      idCardIdEmployeeDocumentId && updateIdCard.id ? [...documents, idCardArchivedOrActive] : documents,
    );

    const requestData: IUpdateEmployeeRequest = {
      ...generalData,
      ...generalData.contactData,
      travelPolicyId,
      employeeId: employeeId as string,
      birthday: getKeepLocalDate(birthday || getDayjs()),
      countryCode: citizenshipCode,
      companyIds: organization,
      addedVersions: transformationAddedVersion(formationAddedVersion, countryCodeSwitch),
      updateVersions: transformationUpdateVersion(formationUpdateVersion, countryCodeSwitch),
      bonusCards: getBonusCards(),
    };

    try {
      await updateEmployee(requestData).unwrap();

      notification({
        title: LABELS.CARD_EMPLOYEE.SUCCESS_UPDATE.TITLE,
        content: (
          <Text type='NORMAL_16' color='gray-7'>
            {LABELS.CARD_EMPLOYEE.SUCCESS_UPDATE.MESSAGE(employeeFullName)}
          </Text>
        ),
        type: ENotificationActionType.success,
      });

      navigate(-1);
    } catch (e) {
      notification({
        title: LABELS.CARD_EMPLOYEE.ERROR_NOT_SAVE.TITLE,
        content: (
          <Text type='NORMAL_16' color='gray-7'>
            {LABELS.CARD_EMPLOYEE.ERROR_NOT_SAVE.MESSAGE(employeeFullName)}
          </Text>
        ),
        type: ENotificationActionType.error,
      });
    }
  };

  const handleBackLink = () => {
    dispatch(setInitialState());

    navigate(EMPLOYEES);
  };

  const handleAddPassport = () => {
    if (documents.length < MAX_COUNT_DOCUMENT) {
      dispatch(addPassport());
    }
  };

  const handleDeleteDocument = (index: number) => dispatch(deletePassport(index));

  const renderDocuments = () => {
    if (!documents.length) return null;

    return (
      <div className={ styles.document }>
        {documents.map((doc, index) => {
          const key = isCreate ? `${doc.actualVersion.type}_${index}` : doc.id || `passport_${index}`;

          return doc.actualVersion.status !== Archived && (
            <FormContainer
              key={ key }
              subTitle={ documentTitle }
              handleDeletePassword={ () => handleDeleteDocument(index) }
              isDeleteButton
            >
              <Document
                data={ doc }
                index={ index }
                countryCode={ citizenshipCode3 }
                type={ EEmployeeDocumentType.ForeignPassport }
              />
            </FormContainer>
          );
        })}
      </div>
    );
  };

  const isCreatingOrUpdatingCard = isCreate ? handleAddEmployee : handleUpdateEmployeeInfo;

  return (
    <div className={ styles.wrap }>
      <BackLink
        textColor='blue-1'
        iconColor='blue1'
        text={ LABELS.EMPLOYEES }
        onClick={ handleBackLink }
        link={ EMPLOYEES }
      />
      <div className={ styles.title }>{formationPageName}</div>
      <div className={ styles.form_container }>
        <Text type='bold_20' className={ styles.title_documents }>
          {LABELS.GENERAL_DATA}
        </Text>
        <FormContainer subTitle={ LABELS.PERSONAL_DATA } isDeleteButton={ false }>
          <GeneralStep companies={ companies } listCountryCode={ listCountryCode }/>
        </FormContainer>
      </div>
      <div className={ styles.document_container }>
        <Text type='bold_20' className={ styles.title_documents }>
          {LABELS.DOCUMENTS}
        </Text>
        <IdCard />
        {renderDocuments()}
      </div>
      <div className={ styles.add_passport_button }>
        <IconButton
          iconType='add'
          iconColor='blue1'
          disabled={ isFillingButtonAddPassport }
          onClick={ handleAddPassport }
          className={ styles }
        >
          {LABELS.ADD_TO_PASSWORD}
        </IconButton>
      </div>
      <TravelPolicyBlock travelPolicies={ travelPolicies }/>
      <FormProvider { ...methods }>
        <AdditionallySection />
      </FormProvider>
      <div className={ styles.action_panel }>
        <div className={ styles.content }>
          <div className={ styles.actions }>
            <Button
              type='secondary'
              size='small'
              onClick={ isCreatingOrUpdatingCard }
              disabled={ !isFilled }
            >
              {LABELS.SAVE}
            </Button>
            <Button type='text' onClick={ handleBackLink }>
              {LABELS.CANCEL}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
