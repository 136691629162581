import React, { useState } from 'react';
import { IconButton, Stars, Text } from 'yw-ui';

import MoneyFormat from 'yw-ui/src/utils/money.ts';
import { getText } from '@/i18n';

import { useAppSelector } from '@/app/store/hooks/redux.ts';

import { MapDialog } from '@/app/components/Maps/MapDialog';
import { ImageGallery } from '@/app/components/ImageGallery';
import { MapProvider } from '@/app/components/Maps/GoogleMap';
import { HotelCheckInCheckOutBlock } from '@/app/components/HotelCheckInCheckOutBlock';

import { HOTEL_TYPES } from '@/app/bi/constants/hotelsSearch.ts';

import { IMapPoint } from '@/app/bi/models/maps.ts';
import { ICartResponseHotelItem } from '@/app/bi/models/cart.ts';
import { EPositionThumbnails } from '@/app/bi/models/hotelsTypes.ts';

import styles from './index.module.scss';

const SLIDE_INTERVAL = 4000;

const LABELS = {
  SHOW_ON_MAP: getText('components:hotelSidePanels.showOnMap'),
  MORE_ABOUT_OF_HOTEL: getText('components:hotelSidePanels.moreAboutOfHotel'),
  DISTANCE_TO_CENTER: (city: string, distance: string) =>
    getText('hotels:hotelResult.distanceToCenter', { city, distance }),
};

interface IHotelSidePanelsProps {
  item: ICartResponseHotelItem;
}

const HotelSidePanels = ({
  item: {
    id,
    images,
    latitude,
    longitude,
    price,
    hotelStars,
    hotelName,
    hotelType,
    distanceToCenter,
    hotelDescriptions,
    hotelAddress,
    hotelCity,
    hotelCheckoutTime,
    hotelCheckinTime,
  },
}: IHotelSidePanelsProps) => {
  const locale = useAppSelector((state) => state.appSlice.locale);

  const [openMap, setOpenMap] = useState(false);
  const [hotel, setHotel] = useState<IMapPoint>({
    lat: 0,
    lng: 0,
    price: '',
    hotelId: '',
  });

  const renderGallery = () => {
    const imgsList = images.map((img) => ({
      original: img,
      thumbnail: img,
    }));

    return (
      <ImageGallery
        positionThumbnails={ EPositionThumbnails.Bottom }
        items={ imgsList }
        slideInterval={ SLIDE_INTERVAL }
      />
    );
  };

  const handleOpenMap = () => {

    setHotel({
      lat: latitude,
      lng: longitude,
      price: MoneyFormat.moneyWithDecimal(price, true),
      hotelId: id,
    });
    setOpenMap(true);
  };

  const handleCloseMap = (value: boolean) => {
    setOpenMap(value);
  };

  const renderMap = () => {
    const center = { lat: hotel.lat, lng: hotel.lng };

    return (
      <MapDialog show={ openMap } onChange={ handleCloseMap } renderDependsOnShow>
        <MapProvider
          center={ center }
          points={ [hotel] }
        />
      </MapDialog>
    );
  };

  const renderDescription = () => {
    if (!hotelDescriptions) {
      return null;
    }

    const description = hotelDescriptions.find((itemDesc) => itemDesc.locale === locale)?.description || '';

    return (
      <div className={ styles.description }>
        <Text type='bold_24'>{LABELS.MORE_ABOUT_OF_HOTEL}</Text>
        <div
          className={ styles.text }
          dangerouslySetInnerHTML={ { __html: description } }
        />
      </div>
    );
  };

  return (
    <>
      <div className={ styles.details_wrapper }>
        <div className={ styles.headers }>
          <Text type='bold_24'>
            {HOTEL_TYPES[hotelType]} {hotelName}
          </Text>
          {hotelStars > 0 && <Stars count={ hotelStars } size={ 24 }/> }
        </div>
        <div className={ styles.subheaders }>
          <div className={ styles.info }>
            <Text type='NORMAL_14'>{hotelAddress}</Text>
            <Text type='NORMAL_14' color='gray-5'>
              {LABELS.DISTANCE_TO_CENTER(hotelCity, distanceToCenter.toString())}
            </Text>
          </div>
          <div className={ styles.actions }>
            <IconButton
              iconType='location'
              iconColor='blue1'
              size={ 24 }
              onClick={ handleOpenMap }
            >
              <Text type='NORMAL_14' className={ styles.text }>
                {LABELS.SHOW_ON_MAP}
              </Text>
            </IconButton>
          </div>
        </div>
        <div className={ styles.images_gallery }>
          {renderGallery()}
        </div>
        <HotelCheckInCheckOutBlock
          checkInTime={ hotelCheckinTime }
          checkOutTime={ hotelCheckoutTime }
        />
        {renderDescription()}
      </div>
      {renderMap()}
    </>
  );
};
export { HotelSidePanels };
