import dayjs, { Dayjs, ManipulateType } from 'dayjs';

import utc from 'dayjs/plugin/utc';
import minMax from 'dayjs/plugin/minMax';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { ELanguages } from '@/i18n/locales/consts';

import { DATE_FORMATS, PATTERN } from '../constants/dateFormats';

import parseUnix from './parseDateTime';

dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

const isCurrentYear = (value: Dayjs) => {
  const currentYear = dayjs().year();
  const dateObject = dayjs(value);

  return currentYear === dateObject.year();
};

const formatDate = (value: dayjs.ConfigType, pattern: string = PATTERN.DAY_OF_MONTH_WITH_YEAR): string => {
  const dateObject = dayjs(value);

  if (isCurrentYear(dateObject) && pattern === PATTERN.DAY_OF_MONTH_WITH_YEAR) {
    return dateObject.format(PATTERN.DAY_OF_MONTH);
  }

  return dateObject.format(pattern);
};

const getDateZeroTime = (value?: Dayjs) => (value ? value.startOf('day') : getDayjs().startOf('day'));

const formatHourFromHourTo = (dateFrom: Dayjs, dateTo: Dayjs) => {
  const hourFrom = formatDate(dateFrom, PATTERN.LOCAL_TIME_PATTERN);
  const hourTo = formatDate(dateTo, PATTERN.LOCAL_TIME_PATTERN);

  return `${hourFrom} — ${hourTo}`;
};

const getCurrentLocale = (): string => dayjs.locale() || ELanguages.Ru;

const getKeepLocalDate = (value: Dayjs): Dayjs => value.utc(true);

const dayjsObject = (value: dayjs.ConfigType): Dayjs => dayjs(value);
const dayjsObjectUTC = (value: dayjs.ConfigType): Dayjs => dayjs.utc(value);
const getDayjs = (): Dayjs => dayjs();
const isValidDateObject = (value: Dayjs): boolean => dayjs(value).isValid();

const isSameDate = (first: Dayjs | null, second: Dayjs | null): boolean => dayjs(first).isSame(dayjs(second));

const isSameOrBefore = (first: Dayjs, second: Dayjs): boolean => dayjs(first).isSameOrBefore(dayjs(second));

interface MergeOptionsType {
  locale: string,
  withTime: boolean
  withoutSpaceBetweenDates: boolean,
  fullPattern: string,
  monthPattern: string,
}

const mergeOptions = ({
  locale,
  withTime,
  fullPattern,
  monthPattern,
  withoutSpaceBetweenDates,
}: any): MergeOptionsType => ({
  locale: locale || getCurrentLocale(),
  withTime: withTime || false,
  withoutSpaceBetweenDates: withoutSpaceBetweenDates || false,
  fullPattern: fullPattern || PATTERN.DAY_OF_MONTH_WITH_YEAR,
  monthPattern: monthPattern || PATTERN.DAY_OF_MONTH,
});

const formatRangeDateWithSimplicity = (
  start: Dayjs,
  end: Dayjs,
  opts = {
    locale: getCurrentLocale(),
    withTime: false,
    fullPattern: PATTERN.DAY_OF_MONTH_WITH_YEAR,
    monthPattern: PATTERN.DAY_OF_MONTH,
    withoutSpaceBetweenDates: false,
  },
) => {
  const options = mergeOptions(opts);

  const currentYear = dayjs().year();
  const mStart = dayjs(start).locale(options.locale);
  const mEnd = dayjs(end).locale(options.locale);

  let startDateFormat = options.fullPattern;
  let endDateFormat = options.fullPattern;

  if (mStart.year() === mEnd.year()) {
    startDateFormat = options.monthPattern;
  }
  if (mEnd.year() === currentYear) {
    endDateFormat = options.monthPattern;
  }
  if (mEnd.year() === mStart.year() && mStart.month() === mEnd.month()) {
    startDateFormat = 'D';
  }
  if (options.withTime) {
    endDateFormat = `${options.monthPattern} ${DATE_FORMATS.TIME}`;
    startDateFormat = endDateFormat;
  }

  const rStart = mStart.format(startDateFormat);
  const rEnd = mEnd.format(endDateFormat);

  if (!end) {
    return rStart;
  }

  if (
    mStart
      .clone()
      .startOf('day')
      .isSame(mEnd.clone().startOf('day'))
    && !options.withTime
  ) {
    return rEnd;
  }

  return options.withoutSpaceBetweenDates ? `${rStart}–${rEnd}` : `${rStart} – ${rEnd}`;
};

const dateWithoutDayjs = (value: Dayjs | string | number, pattern = DATE_FORMATS.DATE): string => formatDate(parseUnix(value), pattern);
const dateUtcFormat = (value: Dayjs | string, pattern = PATTERN.DAY_OF_MONTH_TIME) => dayjs.utc(value).format(pattern);
const dayjsSubPeriod = (current: Dayjs, value: number, pattern: ManipulateType) => dayjsObject(current).subtract(value, pattern);
const getYear = (value: number): Dayjs => getDayjs().year(value);

const diffMinutes = (first: Dayjs, second: Dayjs): string => dayjs.duration(second.diff(first)).asMinutes().toFixed();
const diffHours = (first: Dayjs, second: Dayjs): number => Math.floor(dayjs.duration(second.diff(first)).asHours());
const diffDays = (first: Dayjs, second: Dayjs): number => dayjsObject(second).startOf('day').diff(dayjsObject(first).startOf('day'), 'days');

const toLocalDate = (date: dayjs.Dayjs) => dayjs.utc(date).local();

const countNights = (first?: dayjs.Dayjs, second?: dayjs.Dayjs) => {
  const diff = first?.diff(second) || 0;

  return Math.round(dayjs.duration(diff).asDays());
};

export {
  formatDate,
  getCurrentLocale,
  getDayjs,
  dayjsObject,
  dayjsObjectUTC,
  isSameDate,
  formatRangeDateWithSimplicity,
  isValidDateObject,
  dateWithoutDayjs,
  isSameOrBefore,
  dateUtcFormat,
  dayjsSubPeriod,
  diffMinutes,
  diffDays,
  diffHours,
  getYear,
  getDateZeroTime,
  formatHourFromHourTo,
  getKeepLocalDate,
  toLocalDate,
  countNights,
};
