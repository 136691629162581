import React from 'react';
import { Text } from 'yw-ui';

import styles from './index.module.scss';

interface ColumnLayoutProps {
  items: string[]
}

const ColumnLayout = ({ items }: ColumnLayoutProps) => {
  const columns = Math.ceil(items.length / 3);

  const renderScene = () => items.map((item, index) => (
    <div key={ `${item}_${index}` } style={ { gridColumn: (index % columns) + 1, marginRight: 16, marginBottom: 4 } }>
      <div className={ styles.containerServices }>
        <div className={ styles.pointContainer }>
          <div className={ styles.point } />
        </div>
        <Text type='NORMAL_14' color='gray-7'>
          {item}
        </Text>
      </div>
    </div>
  ));

  return (
    <div style={ { display: 'grid', gridTemplateColumns: `repeat(${columns}, 1fr)`, alignSelf: 'start' } }>
      {renderScene()}
    </div>
  );
};

export default ColumnLayout;
