import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';
import { SelectProps } from 'yw-ui/components/Select';

import { currentLng } from '@/i18n';

import { useAddHotelMutation } from '@/app/bi/api/cartApi';
import { useAppSelector } from '@/app/store/hooks/redux';

import { NotificationItem } from './components/NotificationItem';

import { getSearchDatesDiff } from '@/app/bi/utils/hotels';
import { dayjsObject, formatRangeDateWithSimplicity } from '@/app/bi/utils/formatDate';
import { notification } from '@/app/bi/utils/not';

import ANIMATION from '@/app/bi/constants/animation';
import ROUTES from '@/app/bi/constants/routes';

import { ENotificationActionType } from '@/app/bi/constants/settings';

import { SearchSettings } from '@/app/bi/models/hotelsTypes';
import { IHotelSearchRateResponse } from '@/app/bi/models/hotelSearch/hotelSearchTypes';

const createLabels = (t: TFunction) => ({
  NOTIFICATIONS_CART: {
    ADD_TO_CART_SUCCESS: t('hotels:hotelResult.notifications.toCart.success'),
    ADD_TO_CART_ERROR: t('hotels:hotelResult.notifications.toCart.error'),
    TO_CART: t('air:result.notifications.cart.toCart'),
  },
});

export const useFreeRooms = (freeRooms: number, roomCount: number) => {
  const isLastRoom = freeRooms === 1;

  const items: SelectProps['items'] = isLastRoom ?
    []
    : Array.from({ length: freeRooms }).map((_, idx): SelectProps['items'][number] => ({ label: `${idx + 1}`, value: idx + 1 }));

  const [count, setCount] = useState(isLastRoom ? 1 : roomCount);

  const onChange = (value: number) => {
    setCount(value);
  };

  return { count, onChange, items, isLastRoom };
};

export const useCart = (searchSettings: SearchSettings, rate: IHotelSearchRateResponse, count: number) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const [addHotel] = useAddHotelMutation();
  const goToCart = () => navigate(ROUTES.CART.MAIN);

  const [animatedClass, setAnimatedClass] = useState('');

  const { data } = useAppSelector((state) => state.hotelSlice);

  const onAddCart = async (e: React.MouseEvent) => {
    e.preventDefault();

    setAnimatedClass(ANIMATION.MOVE_TO_CART);
    const checkinDate = dayjs(searchSettings.checkin);
    const checkoutDate = dayjs(searchSettings.checkout);
    const dateRangeString = formatRangeDateWithSimplicity(checkinDate, checkoutDate);
    const searchDatesDiff = getSearchDatesDiff(dayjsObject(searchSettings.checkin), dayjsObject(searchSettings.checkout));

    const notificationItem = () => (
      <NotificationItem
        hotelName={ data?.item.name }
        roomType={ rate.name }
        date={ dateRangeString }
        detail={ searchDatesDiff }
      />
    );

    const bodyAddToCart = {
      bookId: rate.bookId,
      searchId: data?.id ?? '',
      roomCount: count,
      locale: currentLng,
    };

    try {
      await addHotel(bodyAddToCart).unwrap();

      notification({
        title: LABELS.NOTIFICATIONS_CART.ADD_TO_CART_SUCCESS,
        buttonName: LABELS.NOTIFICATIONS_CART.TO_CART,
        content: notificationItem(),
        onClick: goToCart,
      });
    } catch {
      notification({
        title: LABELS.NOTIFICATIONS_CART.ADD_TO_CART_ERROR,
        content: notificationItem(),
        type: ENotificationActionType.error,
        onClick: goToCart,
      });
    }
  };

  const onAnimationEnd = () => setAnimatedClass('');

  return { onAddCart, animatedClass, onAnimationEnd };
};
