import { Input } from 'yw-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { debounce } from '@/app/bi/utils/debounce.ts';

import styles from './style.module.scss';

const MAX_LENGTH_HOTEL_NAME = 100;

interface IHotelNameFilterProps {
  hotelName: string;
  onChange(value: string): void;
}

const HotelNameFilter = ({
  hotelName = '',
  onChange,
}: IHotelNameFilterProps) => {
  const { t } = useTranslation();

  const [name, setName] = useState(hotelName);

  useEffect(() => {
    if (hotelName !== name) {
      setName(hotelName);
    }
  }, [hotelName]);

  const debounceName = useCallback(
    debounce((value) => {
      onChange(value);
    }, 400),
    [],
  );

  const updateHotelName = async (value: string) => {
    setName(value);

    await debounceName(value);
  };

  return (
    <div className={ styles.hotel_name }>
      <Input
        className={ styles.input }
        isCleansing
        value={ name }
        placeholder={ t('hotels:regionResult.filters.labels.nameOfHotel') }
        max={ MAX_LENGTH_HOTEL_NAME }
        onChange={ updateHotelName }
        isSearch
      />
    </div>
  );
};

export { HotelNameFilter };
