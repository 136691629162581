import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IconButton } from 'yw-ui';

import { AIRLINE_CODE, BONUS_CARDS, NUMBER } from '../../constant';
import { SchemaType } from '../../schema';

import styles from './index.module.scss';

interface ClearButtonProps {
  fieldsCount: number;
  index: number;
  remove: (index: number) => void;
}

const ClearButton = ({ fieldsCount, remove, index }: ClearButtonProps) => {
  const { resetField, getValues } = useFormContext<SchemaType>();

  const isShow = fieldsCount > 1 ||
        !!getValues(`${BONUS_CARDS}.${index}.${AIRLINE_CODE}`).trim() ||
        !!getValues(`${BONUS_CARDS}.${index}.${NUMBER}`).trim();

  const handleClick = () => {
    if (fieldsCount > 1) {
      remove(index);
    } else {
      resetField(`${BONUS_CARDS}.0.${AIRLINE_CODE}`, { defaultValue: '' });
      resetField(`${BONUS_CARDS}.0.${NUMBER}`, { defaultValue: '' });
    }
  };

  return (
    isShow && <IconButton
      iconType='closeOff'
      size={ 16 }
      onClick={ handleClick }
      className={ styles.icon }
    />
  );
};

export default ClearButton;
