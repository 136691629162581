import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { authApi } from '@/app/bi/api/authApi.ts';

import { LoginDialogWrapper } from '../../components/LoginDialogWrapper';

import ROUTES from '@/app/bi/constants/routes.ts';

import { getValidationSetPasswordForm } from '@/app/bi/utils/login.ts';

import styles from './index.module.scss';

const LABELS = {
  CREATE_PASSWORD: getText('auth:createPassword'),
  RESET_PASSWORD: getText('auth:resetPassword'),
  PASSWORD: getText('auth:password'),
  SET_PASSWORD_ERROR: getText('auth:setPasswordError'),
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, updatePassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [validation, setValidation] = useState({
    password: '',
  });
  const [resetPassword, { isLoading }] = authApi.useResetPasswordMutation();

  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get('email') as string;
  const token = searchParams.get('token') as string;

  const passwordType = showPassword ? 'type' : 'password';

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const {
      validation: validationObj,
      isValid,
    } = getValidationSetPasswordForm(password);

    setValidation(validationObj);

    if (isValid) {
      const body = {
        email,
        password,
        token,
      };

      try {
        await resetPassword(body).unwrap();

        navigate(ROUTES.LOGIN);
      } catch (err) {
        setShowPassword(false);
        setValidation({
          password: LABELS.SET_PASSWORD_ERROR,
        });
      }
    }
  };

  const handleShowPassword = () => setShowPassword(!showPassword);

  const errorHtml = validation.password && (
    <Text type='NORMAL_12' className={ styles.error_message }>
      {validation.password}
    </Text>
  );

  const renderShowPassword = () => {
    const classes = `${styles.eyes} ${showPassword ? styles.showEyes : ''}`;

    return (<span onClick={ handleShowPassword } className={ classes } />);
  };

  const passwordErrorClass = validation.password ? styles.input_not_valid : '';

  const showPasswordHtml = renderShowPassword();

  const resetValidation = (field: string) => () => {
    setValidation({
      ...validation,
      [field]: '',
    });
  };

  const renderContent = () => (
    <LoginDialogWrapper title={ LABELS.CREATE_PASSWORD }>
      <form
        className={ styles.form }
        onSubmit={ handleSubmit }
      >
        <div className={ styles.row }>
          <div className={ styles.group }>
            <Text className={ styles.label } type='NORMAL_14'>
              { LABELS.PASSWORD }
            </Text>
            <div className={ styles.password_group }>
              <Input
                type={ passwordType }
                value={ password }
                onChange={ updatePassword }
                onFocus={ resetValidation('password') }
                className={ passwordErrorClass }
              />
              {showPasswordHtml}
              {errorHtml}
            </div>
          </div>
        </div>
        <div className={ styles.row }>
          <Button
            className={ styles.submit }
            formType='submit'
            type='primary'
            loading={ isLoading }
            disabled={ isLoading }
          >
            { LABELS.RESET_PASSWORD }
          </Button>
        </div>
      </form>
    </LoginDialogWrapper>
  );

  return renderContent();
};

export { ResetPassword };
