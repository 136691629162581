import React, { useEffect } from 'react';
import { Input, Select, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import {
  setBaggageAvia,
  setDaysBuyLimitAvia,
  setDaysBuyLimitCheckedAvia,
  setDifferenceLimitCurrencyAvia,
  setMaxPriceAvia,
  setMaxPriceCurrencyAvia,
  setMaxPriceDifferenceLimitCheckedAvia,
  setMaxPricePerNightCheckedAvia,
  setPriceDifferenceLimitAvia,
  setPriceDifferenceLimitTypeAvia,
  setRefundableAvia,
  setWeekdaysAvia,
} from '@/app/store/redusers/TravelPolicy.ts';

import { CheckBoxItem } from '@/app/pages/TravelPolicy/components/CheckBoxItem';

import MoneyFormat from '@/app/bi/utils/money.ts';

import { CURRENCY_NAME_AVIA, CURRENCY_NAME_LIST } from '@/app/bi/constants/travelPolicy.ts';

import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import { EPriceType } from '@/app/bi/models/travelPolicies.ts';

import styles from './index.module.scss';

const LABELS = {
  NOT_MORE_EXPENSIVE: getText('settings:travelPolicies.policy.notMoreExpensive'),
  ONLY_CLASS: getText('settings:travelPolicies.policy.onlyClass'),
  ONE_WAY: getText('settings:travelPolicies.policy.airline.oneWay'),
  MORE_EXPENSIVE_CHEAPEST: getText('settings:travelPolicies.policy.airline.moreExpensiveCheapest'),
  NO_LATER_THAN: getText('settings:travelPolicies.policy.airline.noLaterThan'),
  DAYS_BEFORE_DEPARTURE: getText('settings:travelPolicies.policy.airline.daysBeforeDeparture'),
  TEXT_EXCEPTION: getText('settings:travelPolicies.policy.airline.textException'),
  ONLY_REFUNDABLE_RATES: getText('settings:travelPolicies.policy.airline.onlyRefundableRates'),
  ONLY_DEPARTURE_ARRIVAL_WEEKDAYS: getText('settings:travelPolicies.policy.airline.onlyDepartureArrivalWeekdays'),
  ONLY_LUGGAGE: getText('settings:travelPolicies.policy.airline.onlyLuggage'),
};

interface AviaPoliciesProps {
  isCreate: boolean;
}

export const AviaPolicies = ({
  isCreate,
}: AviaPoliciesProps) => {
  const dispatch = useAppDispatch();
  const {
    maxPriceChecked,
    maxDayByLimitChecked,
    maxPriceDifferenceChecked,
    airlinePolicy: {
      baggage,
      daysBuyLimit,
      maxPrice,
      maxPriceCurrency,
      priceDifferenceLimit,
      priceDifferenceLimitType,
      priceDifferenceLimitCurrency,
      weekdays,
      refundable,
    },
  } = useAppSelector((state) => state.travelPolicySlice);

  const notMoreExpensiveDouble = MoneyFormat.money(maxPrice * 2, true);
  const priceDifferenceCurrencyValue = priceDifferenceLimitCurrency === ECurrencyCode.TRY
    && priceDifferenceLimitType === EPriceType.Percent ? ECurrencyCode.Percent : priceDifferenceLimitCurrency;

  useEffect(() => {
    if (!isCreate) {
      dispatch(setMaxPricePerNightCheckedAvia(maxPrice > 0));
      dispatch(setDaysBuyLimitCheckedAvia(daysBuyLimit > 0));
      dispatch(setMaxPriceDifferenceLimitCheckedAvia(priceDifferenceLimit > 0));
    }
  }, [isCreate, maxPrice, daysBuyLimit, priceDifferenceLimit]);

  const handleRefundable = () => dispatch(setRefundableAvia(!refundable));

  const handleWeekdays = () => dispatch(setWeekdaysAvia(!weekdays));

  const handleBaggage = () => dispatch(setBaggageAvia(!baggage));

  const handleMaxPriceCurrency = (currencyCod: ECurrencyCode) => {
    dispatch(setMaxPriceCurrencyAvia(currencyCod));
    dispatch(setMaxPricePerNightCheckedAvia(true));
  };

  const handleMaxPrice = (price: string) => {
    const regex = /^[0-9]*$/;

    if (!regex.test(price)) {
      return;
    }

    const newPrice = !price ? 0 : Number(price);

    dispatch(setMaxPriceAvia(newPrice));

    if (newPrice !== 0 && !maxPriceChecked) {
      dispatch(setMaxPricePerNightCheckedAvia(true));
    } else if (newPrice === 0) {
      dispatch(setMaxPricePerNightCheckedAvia(false));
    }
  };

  const handleMaxPriceActiveChange = () => {
    const newValue = !maxPriceChecked;

    dispatch(setMaxPricePerNightCheckedAvia(newValue));

    if (!newValue) {
      dispatch(setMaxPriceAvia(maxPrice));
    }
  };

  const handlePriceDifference = (price: string) => {
    const regex = /^[0-9]*$/;

    if (!regex.test(price)) {
      return;
    }

    const newPrice = !price ? 0 : Number(price);

    dispatch(setPriceDifferenceLimitAvia(newPrice));

    if (newPrice !== 0 && !maxPriceDifferenceChecked) {
      dispatch(setMaxPriceDifferenceLimitCheckedAvia(true));
    } else if (newPrice === 0) {
      dispatch(setMaxPriceDifferenceLimitCheckedAvia(false));
    }
  };

  const handlePriceDifferenceActiveChange = () => {
    const newValue = !maxPriceDifferenceChecked;

    dispatch(setMaxPriceDifferenceLimitCheckedAvia(newValue));

    if (!newValue) {
      dispatch(setPriceDifferenceLimitAvia(maxPrice));
    }
  };

  const handlePriceDifferenceLimitCurrency = (currencyCode: ECurrencyCode) => {
    if (currencyCode === ECurrencyCode.Percent) {
      dispatch(setPriceDifferenceLimitTypeAvia(EPriceType.Percent));
      dispatch(setDifferenceLimitCurrencyAvia(ECurrencyCode.TRY)); // Отправляем TRY вместо процента
    } else {
      dispatch(setPriceDifferenceLimitTypeAvia(EPriceType.Currency));
      dispatch(setDifferenceLimitCurrencyAvia(currencyCode));
    }

    if (priceDifferenceLimit !== 0) {
      dispatch(setMaxPriceDifferenceLimitCheckedAvia(true));
    }
  };

  const handleDayByLimit = (day: string) => {
    const regex = /^[0-9]*$/;

    if (!regex.test(day)) {
      return;
    }

    const dayLimit = !day ? 0 : Number(day);

    dispatch(setDaysBuyLimitAvia(dayLimit));

    if (dayLimit !== 0 && !maxDayByLimitChecked) {
      dispatch(setDaysBuyLimitCheckedAvia(true));
    } else if (dayLimit === 0) {
      dispatch(setDaysBuyLimitCheckedAvia(false));
    }
  };

  const handleDayByLimitActiveChange = () => {
    const newValue = !maxDayByLimitChecked;

    dispatch(setDaysBuyLimitCheckedAvia(newValue));

    if (!newValue) {
      dispatch(setDaysBuyLimitAvia(daysBuyLimit));
    }
  };

  return (
    <>
      <CheckBoxItem
        checked={ maxPriceChecked }
        onChange={ handleMaxPriceActiveChange }
      >
        <div className={ styles.policy_content }>
          <Text type='NORMAL_14'>
            {LABELS.NOT_MORE_EXPENSIVE}
          </Text>
          <Input
            value={ maxPrice }
            className={ styles.input }
            onChange={ handleMaxPrice }
            max={ 9 }
          />
          <Select
            className={ styles.select_currency }
            theme='open-blue-small'
            items={ CURRENCY_NAME_LIST }
            value={ maxPriceCurrency }
            onChange={ handleMaxPriceCurrency }
          />
          <Text type='NORMAL_14' className={ styles.price }>
            ({notMoreExpensiveDouble} {LABELS.ONE_WAY})
          </Text>
        </div>
      </CheckBoxItem>
      <CheckBoxItem
        checked={ maxPriceDifferenceChecked }
        onChange={ handlePriceDifferenceActiveChange }
      >
        <div className={ styles.policy_content }>
          <Text type='NORMAL_14'>
            {LABELS.MORE_EXPENSIVE_CHEAPEST}
          </Text>
          <Input
            value={ priceDifferenceLimit }
            className={ styles.input }
            onChange={ handlePriceDifference }
            max={ 9 }
          />
          <Select
            className={ styles.select_currency }
            theme='open-blue-small'
            items={ CURRENCY_NAME_AVIA }
            value={ priceDifferenceCurrencyValue }
            onChange={ (value) => handlePriceDifferenceLimitCurrency(value as ECurrencyCode) }
          />
        </div>
      </CheckBoxItem>
      <CheckBoxItem
        checked={ maxDayByLimitChecked }
        onChange={ handleDayByLimitActiveChange }
      >
        <div className={ styles.policy_content }>
          <Text type='NORMAL_14'>
            {LABELS.NO_LATER_THAN}
          </Text>
          <Input
            value={ daysBuyLimit }
            className={ styles.input }
            onChange={ handleDayByLimit }
            max={ 9 }
          />
          <Text type='NORMAL_14'>
            {LABELS.DAYS_BEFORE_DEPARTURE}
          </Text>
        </div>
      </CheckBoxItem>
      {/* // TODO Нужно, после правок бэка поправить */}
      {/* <CheckBoxItem */}
      {/*  checked={ classTicketActive } */}
      {/*  onChange={ () => {} } */}
      {/* > */}
      {/*  <div className={ styles.policy_content }> */}
      {/*    <Text type='NORMAL_14'> */}
      {/*      {LABELS.ONLY_CLASS} */}
      {/*    </Text> */}
      {/*    <Select */}
      {/*      className={ styles.select_currency } */}
      {/*      theme='open-blue-small' */}
      {/*      items={ CLASS_TICKET_LIST } */}
      {/*      value={ classTicket } */}
      {/*      onChange={ () => {} } */}
      {/*    /> */}
      {/*  </div> */}
      {/* </CheckBoxItem> */}
      <CheckBoxItem checked={ refundable } onChange={ handleRefundable }>
        <Text type='NORMAL_14'>{LABELS.ONLY_REFUNDABLE_RATES}</Text>
      </CheckBoxItem>
      <CheckBoxItem checked={ weekdays } onChange={ handleWeekdays }>
        <Text type='NORMAL_14'>{LABELS.ONLY_DEPARTURE_ARRIVAL_WEEKDAYS}</Text>
      </CheckBoxItem>
      <CheckBoxItem checked={ baggage } onChange={ handleBaggage }>
        <Text type='NORMAL_14'>{LABELS.ONLY_LUGGAGE}</Text>
      </CheckBoxItem>
    </>
  );
};
