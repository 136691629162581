import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import { Button, NoResults, Text } from 'yw-ui';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  NOT_FOUND: t('air:result.notFound'),
  CHANGE_PARAMS_OF_SEARCH: t('air:result.changeParamsOfSearch'),
  RESET_FILTERS: t('air:result.resetFilters'),
});

interface IFilterEmptyPanelProps {
  showReset?: boolean;
  onResetFilters(): void;
}

const FilterEmptyPanel = ({
  showReset = false,
  onResetFilters,
}: IFilterEmptyPanelProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const renderButtonReset = () => showReset && (
    <Button
      type='outline'
      onClick={ onResetFilters }
    >
      { LABELS.RESET_FILTERS }
    </Button>
  );

  const renderContent = () => (
    <div className={ styles.no_results }>
      <Text type='bold_18'>
        { LABELS.NOT_FOUND }
      </Text>
      <Text className={ styles.subtext }>
        { LABELS.CHANGE_PARAMS_OF_SEARCH }
      </Text>
      {renderButtonReset()}
    </div>
  );

  return <NoResults renderContent={ renderContent }/>;
};

export { FilterEmptyPanel };
