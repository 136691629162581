import React from 'react';
import { Collapse } from 'yw-ui';

import { getText } from '@/i18n';

import { DateTimeSlider } from '@/app/components/DateTimeSlider';
import { AirportsFromToFilter } from '@/app/pages/AirlineResult/components/Filters/components/AirportsFromTo';

import { getDayjs } from '@/app/bi/utils/formatDate.ts';
import parseUnix from '@/app/bi/utils/parseDateTime.ts';

import { PATTERN } from '@/app/bi/constants/dateFormats.ts';

import { IRouteFilters } from '@/app/bi/models/airlineTypes.ts';

import styles from '../../index.module.scss';

const LABELS = {
  FROM: (from: string) => getText('air:result.filters.borderTime.from', { from }),
  TO: (to: string) => getText('air:result.filters.borderTime.to', { to }),
};

interface BorderTimeFilterProps {
  routeFilters: IRouteFilters[],
  values: IRouteFilters[],
  onChange(value: IRouteFilters[]): void,
}

const BorderTimeFilter = ({
  routeFilters,
  values,
  onChange,
}: BorderTimeFilterProps) => {
  const handleChangeTimeSlider = (field: string, array: number[], id: number) => {
    const [min, max] = array;
    const newValues = values.map((item) => (item.routeId === id
      ? {
        ...item,
        [field]: {
          min: parseUnix(min).format(PATTERN.YEAR_MONTH_DAY_TIME),
          max: parseUnix(max).format(PATTERN.YEAR_MONTH_DAY_TIME),
        },
      }
      : item));
    onChange(newValues);
  };

  const renderBorderTimePart = ({
    routeId,
    departureCity,
    arrivalCity,
    departureDateTime,
    arrivalDateTime,
  }: IRouteFilters, idx: number) => (
    <Collapse
      opened
      className={ styles.item }
      key={ `border_time_${idx}` }
      label={ `${departureCity} — ${arrivalCity}, ${getDayjs().format(PATTERN.DAY_OF_MONTH)}` }
    >
      <DateTimeSlider
        label={ LABELS.FROM(departureCity) }
        min={ parseUnix(departureDateTime.min).valueOf() }
        max={ parseUnix(departureDateTime.max).valueOf() }
        from={ parseUnix(values[idx].departureDateTime.min).valueOf() }
        to={ parseUnix(values[idx].departureDateTime.max).valueOf() }
        onChange={ (value) => handleChangeTimeSlider('departureDateTime', value, routeId) }
      />
      <DateTimeSlider
        label={ LABELS.TO(arrivalCity) }
        min={ parseUnix(arrivalDateTime.min).valueOf() }
        max={ parseUnix(arrivalDateTime.max).valueOf() }
        from={ parseUnix(values[idx].arrivalDateTime.min).valueOf() }
        to={ parseUnix(values[idx].arrivalDateTime.max).valueOf() }
        onChange={ (value) => handleChangeTimeSlider('arrivalDateTime', value, routeId) }
      />
    </Collapse>
  );

  const routeFiltersHtml = routeFilters.map(renderBorderTimePart);

  return (
    <>
      {routeFiltersHtml}
      <AirportsFromToFilter
        routeFilters={ routeFilters }
        values={ values }
        onChange={ onChange }
      />
    </>
  );
};

export { BorderTimeFilter };
