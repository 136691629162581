import React from 'react';
import { Collapse, Input } from 'yw-ui';

import styles from './index.module.scss';

interface FilterEmployeeItemProps {
  title: string;
  inputValue: string;
  placeholder: string;
  onChangeInput(value: string): void;
}

const EmployeeFilterItem = ({
  title,
  inputValue,
  placeholder,
  onChangeInput,
}: FilterEmployeeItemProps) => (
  <Collapse label={ title } className={ styles.container } >
    <div className={ styles.searchContainer }>
      <Input
        isCleansing
        className={ styles.search }
        value={ inputValue }
        placeholder={ placeholder }
        onChange={ onChangeInput }
      />
    </div>
  </Collapse>
);

export { EmployeeFilterItem };
