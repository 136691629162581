import React from 'react';
import { Button, Dialog, Text } from 'yw-ui';
import { getText } from '@/i18n';

import styles from './index.module.scss';

const LABELS = {
  BACK_TO_CART: getText('booking:backToCart'),
};
interface IGeneralErrorDialog {
  show: boolean,
  title: string;
  onClose(): void,
}
const GeneralErrorDialog = ({
  show,
  title,
  onClose,
}: IGeneralErrorDialog) => {
  const handleClick = () => {
    handleClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      showClosing
      show={ show }
      className={ styles.dialog }
      onChange={ handleClose }
    >
      <div className={ styles.wrapper }>
        <div className={ styles.content }>
          <Text type='bold_20' fontWeight={ 700 } >
            {title}
          </Text>
          <div className={ styles.errors } />
        </div>
        <div className={ styles.action }>
          <Button
            type='secondary'
            onClick={ handleClick }
          >
            {LABELS.BACK_TO_CART}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { GeneralErrorDialog };
