import React, { useMemo } from 'react';
import { Checkbox, Collapse } from 'yw-ui';
import { useTranslation } from 'react-i18next';

import type { TFunction } from 'i18next';

import styles from '../../index.module.scss';

const createLabels = (t: TFunction) => ({
  LABEL: t('air:result.filters.listAirports'),
});

interface TransferAirportsFilterProps {
  airports: string[];
  values: string[];
  onChange(checkedCities: string[]): void;
}

const TransferAirportsFilter = ({
  airports,
  values,
  onChange,
}: TransferAirportsFilterProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const handleChange = (value: string) => {
    const valuesSet = new Set(values);

    if (valuesSet.has(value)) {
      valuesSet.delete(value);

      return onChange([...valuesSet]);
    }

    valuesSet.add(value);

    return onChange([...valuesSet]);
  };

  const renderAirlines = () => [...airports].sort().map((name, index) => {
    const checked = values.includes(name);

    return (
      <Checkbox
        themes='dark'
        className={ styles.checkbox }
        key={ `check_airline_${index}` }
        value={ checked }
        onChange={ () => handleChange(name) }
      >
        { name }
      </Checkbox>
    );
  });

  return (
    <Collapse
      opened
      className={ styles.item }
      label={ LABELS.LABEL }
    >
      { renderAirlines() }
    </Collapse>
  );
};

export { TransferAirportsFilter };
