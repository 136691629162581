import React, { useMemo } from 'react';
import { Collapse } from 'yw-ui';
import { useTranslation } from 'react-i18next';

import type { TFunction } from 'i18next';

import { TimeSlider } from '@/app/components/TimeSlider';

import { getDayjs } from '@/app/bi/utils/formatDate.ts';

import { PATTERN } from '@/app/bi/constants/dateFormats.ts';

import { IFlightDuration } from '@/app/bi/models/airlineTypes.ts';

import styles from '../../index.module.scss';

const createLabels = (t: TFunction) => ({
  TRAVEL_TIME: t('air:result.filters.travelTime'),
});

interface FlightDurationFilterProps {
  arrivalCity: string,
  departureCity: string,
  flightDuration: IFlightDuration,
  values: IFlightDuration,
  onChange(value: {
    min: number,
    max: number,
  }): void;
}

const FlightDurationFilter = ({
  arrivalCity,
  departureCity,
  flightDuration: {
    min,
    max,
  },
  values,
  onChange,
}: FlightDurationFilterProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  if (min !== max) {
    return (
      <Collapse
        opened
        className={ styles.item }
        key={ `flight_duration_${min}_${max}}` }
        label={ `${LABELS.TRAVEL_TIME} ${departureCity} — ${arrivalCity}, ${getDayjs().format(PATTERN.DAY_OF_MONTH)}` }
      >
        <TimeSlider
          min={ min }
          max={ max }
          from={ values.min }
          to={ values.max }
          onSlide={ onChange }
        />
      </Collapse>
    );
  }

  return null;
};

export { FlightDurationFilter };
