import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Text } from 'yw-ui';
import MediaQuery from 'react-responsive';
import { IconColor } from 'yw-ui/src/components/Icon/types.ts';

import { DEFAULT_MENU_TYPE } from '@/app/bi/constants/app.ts';
import ANIMATION from '../../../../bi/constants/animation.ts';

import styles from './index.module.scss';

interface ItemWrapProps {
  showBadge?: boolean,
  mainMenu?: boolean,
  subItem?: boolean,
  count?: number,
  href: string,
  label: string | React.JSX.Element,
  items?: never[],
  type?: string,
  className?: string,
  classNameWrapper?: string,
  onClick?(): void,
  getIcon(): void;
  iconColor?: IconColor,
  onClearSearch?(): void,
}

const ItemWrap = ({
  showBadge = false,
  count = 0,
  href,
  label,
  getIcon,
  items = [],
  type,
  mainMenu,
  subItem = false,
  className = '',
  classNameWrapper = '',
  onClick = () => {},
  iconColor = 'gray7',
}:ItemWrapProps) => {
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    setAnimationClass(ANIMATION.BOUNCEIN);
  }, [count]);

  const handleAnimatedEnd = () => setAnimationClass('');

  // const handleClearRequestsStore = (href: string) => href === ROUTES.REQUESTS && onClearSearch();

  const renderLinkContent = (isNested: boolean) => {
    const nextClassNames = !isNested ? styles.text : '';
    const textType = mainMenu ? 'NORMAL_16_140' : 'NORMAL_14';

    const labelResponsive = (
      <MediaQuery minWidth={ 920 }>
        { label }
      </MediaQuery>
    );

    return (
      <>
        <div className={ animationClass } onAnimationEnd={ handleAnimatedEnd }>
          <Icon
            showBadge={ showBadge }
            badgeCount={ count }
            className={ styles.icon }
            // @ts-ignore
            type={ getIcon(type) }
            color={ iconColor }
          />
        </div>
        <Text className={ nextClassNames } type={ textType }>{ labelResponsive }</Text>
      </>
    );
  };

  const renderLinkWrap = (isNested: boolean) => {
    const wrapClassNames = [styles.link];
    const activeClassNames: string[] = [];

    if (subItem) {
      activeClassNames.push(styles.active, styles.subitem);
    }

    if (mainMenu) {
      activeClassNames.push(styles.active);
    }

    if (className) {
      wrapClassNames.push(className);
    }

    if (isNested) {
      wrapClassNames.push(styles.item);
    }

    const content = renderLinkContent(isNested);

    if (type === DEFAULT_MENU_TYPE.SEARCH) {
      return (
        <div className={ wrapClassNames.join(' ') }>
          {content}
        </div>
      );
    }

    return (
      <NavLink
        to={ href }
        className={ ({ isActive }) =>
          (isActive ? `${wrapClassNames.join(' ')} ${activeClassNames.join(' ')}` : wrapClassNames.join(' '))
        }
      >
        {content}
      </NavLink>
    );
  };

  const renderItem = (isNested: boolean = false) => {
    const content = renderLinkWrap(isNested);

    return (
      <div
        className={ `${styles.wrapper} ${items.length ? styles.search : ''} ${classNameWrapper}` }
        onClick={ () => onClick() }
      >
        { content }
      </div>
    );
  };

  return renderItem();
};

export { ItemWrap };
