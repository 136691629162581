import React from 'react';
import clsx from 'clsx';

import styles from './index.module.scss';

export interface IImageGalleryItem {
  original: string;
  thumbnail: string;
}

interface ImageErrorEvent extends React.SyntheticEvent<HTMLImageElement, Event> {
  target: HTMLImageElement & EventTarget;
}

interface ImageThumbnailsItemProps {
  item: IImageGalleryItem;
  index: number;
  currentIndex: number;
  onMouseOver(): void;
  onMouseLeave(): void;
  onTouchStart(): void;
  onTouchEnd(): void;
  onClick(): void;
  onThumbnailError(event: ImageErrorEvent): void;
}

const ImageThumbnailsItem = ({
  item,
  index,
  currentIndex,
  onMouseOver,
  onMouseLeave,
  onTouchStart,
  onTouchEnd,
  onClick,
  onThumbnailError,
}: ImageThumbnailsItemProps) => {
  const classNameWrapper = clsx([styles.image_gallery_thumbnail], {
    [styles.image_gallery_thumbnail_active]: currentIndex === index,
  });

  return (
    <a
      onMouseOver={ onMouseOver }
      onMouseLeave={ onMouseLeave }
      key={ index }
      className={ classNameWrapper }
      onTouchStart={ onTouchStart }
      onTouchEnd={ onTouchEnd }
      onClick={ onClick }
    >
      <img
        src={ item.thumbnail }
        alt={ item.thumbnail }
        onError={ onThumbnailError }
      />
    </a>
  );
};

export { ImageThumbnailsItem };
