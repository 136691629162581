import React, { useMemo } from 'react';
import { NoResults, Text } from 'yw-ui';
import { useTranslation } from 'react-i18next';

import { TFunction } from 'i18next';

import styles from './style.module.scss';

const createLabels = (t: TFunction) => ({
  CHANGE_PARAMS_OF_FILTERS: t('hotels:regionResult.changeParamsOfFilters'),
  NOT_FOUND: t('hotels:regionResult.notFound'),
});

const EmptyFilter = () => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const renderFilterEmptyPanel = () => {
    const renderContent = () => (
      <div className={ styles.not_found_content }>
        <Text type='bold_18' color='gray' className={ styles.title }>
          {LABELS.NOT_FOUND}
        </Text>
        <Text type='NORMAL_14' color='gray' className={ styles.hint }>
          {LABELS.CHANGE_PARAMS_OF_FILTERS}
        </Text>
      </div>
    );

    return <NoResults renderContent={ renderContent } className={ styles.no_result_container } />;
  };

  return (
    <div className={ styles.no_result }>
      <div className={ styles.empty }>
        {renderFilterEmptyPanel()}
      </div>
    </div>
  );
};

export { EmptyFilter };
