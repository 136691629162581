import React, { ReactNode } from 'react';
import { Button, Text } from 'yw-ui';
import clsx from 'clsx';

import { getText } from '@/i18n';

import styles from './index.module.scss';

const LABELS = {
  DELETE_DOCUMENT: getText('settings:deleteDocuments'),
};

interface FormContainerProps {
  subTitle?: string;
  children: ReactNode;
  isDeleteButton?: boolean;
  handleDeletePassword?(): void;
}

const FormContainer = ({
  subTitle = '',
  children,
  isDeleteButton = false,
  handleDeletePassword = () => {},
}: FormContainerProps) => {
  const renderButtonDelete = () => isDeleteButton && (
    <Button type='text' onClick={ handleDeletePassword }>
      {LABELS.DELETE_DOCUMENT}
    </Button>
  );

  const renderSubTitle = () => (subTitle ? (
    <Text type='bold_18'>
      {subTitle}
    </Text>
  ) : null);

  return (
    <div className={ styles.container }>
      <div className={ styles.wrapper }>
        <div className={ styles.containerHeader }>
          {renderSubTitle()}
          {renderButtonDelete()}
        </div>
        <div
          className={ clsx({
            [styles.children_container]: isDeleteButton || !!subTitle.length,
          }) }
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export { FormContainer };
