import React from 'react';
import { Text } from 'yw-ui';

import clsx from 'clsx';
import { getText } from '@/i18n';

import guid from '../../bi/utils/guid';
import { preparedFares, hasRefundWithConditions } from '../../bi/utils/fares';

import { CHANGE_TICKET, REFUND_TICKET, VALUE_TICKET } from '../../bi/constants/airline';

import arrayWithSeparators from '../../bi/utils/arrayWithSeparators.ts';

import {
  prepareBaggage,
  prepareCarryOn,
  prepareRefundable,
  prepareRefundableAfterDeparture,
  prepareChangeableAfterDeparture, prepareChangeable,
} from './utils.ts';

import { Fares } from '@/app/bi/models/airlineTypes.ts';

import styles from './index.module.scss';

const LABELS = {
  // includes: getText('components:fareDetails.includes'),
  // included: getText('components:fareDetails.included'),
  // notIncluded: getText('components:fareDetails.notIncluded'),
  // includedWithPlaces: (places: string) => getText('components:fareDetails.includedWithPlaces', { places }),
  // charge: getText('components:fareDetails.charge'),
  // notAvailable: getText('components:fareDetails.notAvailable'),
  // withoutTaxes: getText('components:fareDetails.withoutTaxes'),
  // noPenaltyCompany: getText('components:fareDetails.noPenaltyCompany'),
  // withTax: getText('components:fareDetails.withTax'),
  // nonReturnRate: getText('components:fareDetails.nonReturnRate'),
  // restricted: getText('components:fareDetails.restricted'),
  // restrict: getText('components:fareDetails.restrict'),
  // allowed: getText('components:fareDetails.allowed'),
  FARE: getText('components:fareDetails.fare'),
  CHOOSING_SEATS_INCLUDED: getText('components:fareDetails.choosingSeatsIncluded'),
  CHOOSING_SEATS_PAID: getText('components:fareDetails.choosingSeatsPaid'),
  BUSINESS_HALL_INCLUDED: getText('components:fareDetails.businessHallIncluded'),
  BUSINESS_HALL_NOT_INCLUDED: getText('components:fareDetails.businessHallNotIncluded'),
  UPGRADE_RATE: getText('components:fareDetails.upgradeRate'),
  REFUND: getText('components:fareDetails.refund'),
  CHANGE: getText('components:fareDetails.change'),
  REFUND_AFTER_DEPARTURE: getText('components:fareDetails.refundAfterDeparture'),
  CHANGE_AFTER_DEPARTURE: getText('components:fareDetails.changeAfterDeparture'),
  BAGGAGE: getText('components:fareDetails.baggage'),
  CARRY_ON: getText('components:fareDetails.carryOn'),
  REFUND_BEFORE_DEPARTURE: getText('components:fareDetails.refundBeforeDeparture'),
  CHANGE_BEFORE_DEPARTURE: getText('components:fareDetails.changeBeforeDeparture'),
};

const renderItem = (label: string, value: string) => {
  if (value) {
    return (
      <li className={ styles.item }>
        <b>
          { label }
          :&nbsp;
        </b>
        { value }
      </li>
    );
  }

  return null;
};

const getFareDetailsList = (fare: Fares) => {
  const {
    isTicketRefundableAfterDeparture,
    isTicketChangeableAfterDeparture,
    canRegistrationSeat,
    isBusinessHallIncluded,
    canUpgradeRate,
    airlineBonusInformation,
    refundTicketWithCondition,
    changeTicketWithCondition,
  } = fare;
  const details = [];

  if (canRegistrationSeat === VALUE_TICKET.INCLUDED) {
    details.push(`${LABELS.CHOOSING_SEATS_INCLUDED}
      `);
  }

  if (canRegistrationSeat === VALUE_TICKET.CHARGE) {
    details.push(LABELS.CHOOSING_SEATS_PAID);
  }

  if (isBusinessHallIncluded === VALUE_TICKET.INCLUDED) {
    details.push(LABELS.BUSINESS_HALL_INCLUDED);
  }

  if (isBusinessHallIncluded === VALUE_TICKET.NOT_OFFERED) {
    details.push(LABELS.BUSINESS_HALL_NOT_INCLUDED);
  }

  if (canUpgradeRate === VALUE_TICKET.INCLUDED) {
    details.push(LABELS.UPGRADE_RATE);
  }

  if (airlineBonusInformation) {
    details.push(airlineBonusInformation);
  }

  if (refundTicketWithCondition) {
    Object.keys(refundTicketWithCondition).forEach((field) => {
      // @ts-ignore
      if (REFUND_TICKET[refundTicketWithCondition[field]]) {
        // @ts-ignore
        details.push(`${LABELS.REFUND} ${field}: ${REFUND_TICKET[refundTicketWithCondition[field]]}`);
      }
    });
  }

  if (changeTicketWithCondition) {
    Object.keys(changeTicketWithCondition).forEach((field) => {
      // @ts-ignore
      if (CHANGE_TICKET[changeTicketWithCondition[field]]) {
        // @ts-ignore
        details.push(`${LABELS.CHANGE} ${field}: ${CHANGE_TICKET[changeTicketWithCondition[field]]}`);
      }
    });
  }

  const refundableAfterDeparture = prepareRefundableAfterDeparture(isTicketRefundableAfterDeparture);
  const changeableAfterDeparture = prepareChangeableAfterDeparture(isTicketChangeableAfterDeparture);

  if (refundableAfterDeparture) {
    details.push(`${LABELS.REFUND_AFTER_DEPARTURE} ${refundableAfterDeparture}`);
  }

  if (changeableAfterDeparture) {
    details.push(`${LABELS.CHANGE_AFTER_DEPARTURE} ${changeableAfterDeparture}`);
  }

  return details;
};
interface FareDetailsProps {
  fare: Fares,
  title?: boolean,
  className?: string,
  separateSymbol?: { type: string, value: string } | null,
  asItem?: boolean,
  customTitle?: string,
  isCart?: boolean,
  showTicketBullits?: boolean,
}
const FareDetails = ({
  fare,
  showTicketBullits,
  title = false,
  className = '',
  separateSymbol = null,
  asItem = false,
  customTitle = '',
  isCart = false,
}: FareDetailsProps) => {
  const renderCartView = () => {
    const {
      name,
      isBaggageIncluded,
      baggagePlaces,
      isCarryonIncluded,
      carryonPlaces,
      isTicketRefundable,
      isTicketChangeable,
      refundTicketWithCondition,
    } = fare;
    let details = getFareDetailsList(fare);

    if (separateSymbol) {
      details = arrayWithSeparators(details, separateSymbol.value);
    }

    const baggageValue = prepareBaggage(isBaggageIncluded, baggagePlaces);
    const carryOnValue = prepareCarryOn(isCarryonIncluded, carryonPlaces);
    const refundableValue = prepareRefundable(isTicketRefundable);
    const changeableValue = prepareChangeable(isTicketChangeable);
    const fareNameValue = customTitle || (() => (title ? name : ''))();

    const fareClassName = clsx([styles.fare], {
      [styles.fare_as_item]: asItem,
    });

    const detailsContent = details.map((detail, index) => (
      <div
        key={ `fare_tooltip_${guid()}_${index}` }
        className={ fareClassName }
      >
        {detail}
      </div>
    ));

    const nameContent = renderItem(LABELS.FARE, fareNameValue);
    const baggageContent = renderItem(LABELS.BAGGAGE, baggageValue);
    const carryOnContent = renderItem(LABELS.CARRY_ON, carryOnValue);
    const refundableContent = !hasRefundWithConditions(refundTicketWithCondition)
      && renderItem(LABELS.REFUND_BEFORE_DEPARTURE, refundableValue);
    const changeableContent = renderItem(LABELS.CHANGE_BEFORE_DEPARTURE, changeableValue);

    return (
      <div className={ `${styles.wrap} ${styles['cart-view']}` }>
        <ul>
          { nameContent }
          { baggageContent }
          { carryOnContent }
          { refundableContent }
          { changeableContent }
        </ul>
        <div className={ styles.details }>
          { detailsContent }
        </div>
      </div>
    );
  };

  const renderDefault = () => {
    let details = preparedFares(fare);

    const wrapperClassNames = clsx([styles.wrapper], {
      [className]: className,
    });

    const titleHtml = fare.name && title && (
      <Text type='NORMAL_14' className={ styles.title }>
        {LABELS.FARE} &laquo;{ fare.name }&raquo;:
      </Text>
    );

    const customTitleHtml = title && customTitle
      && <Text type='NORMAL_14'>{ customTitle }</Text>;

    if (separateSymbol) {
      details = arrayWithSeparators(details, separateSymbol.value);
    }

    const fareClassNames = clsx({
      [styles.item]: title,
      [styles.fare_as_item]: asItem,

    });

    const fareHtml = details.map((detail, index) => (
      <div
        key={ `fare_tooltip_${guid()}_${index}` }
        className={ fareClassNames }
      >
        {detail}
      </div>
    ));

    return (
      <div className={ wrapperClassNames }>
        { customTitle ? customTitleHtml : titleHtml }
        { fareHtml }
      </div>
    );
  };

  return isCart || showTicketBullits ? renderCartView() : renderDefault();
};

export default FareDetails;
