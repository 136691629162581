import React from 'react';
import { Checkbox, Collapse } from 'yw-ui';

import { getText } from '@/i18n';

import { IRouteFilters } from '@/app/bi/models/airlineTypes.ts';

import styles from '../../index.module.scss';

const LABELS = {
  FROM: (from: string) => getText('air:result.filters.airports.from', { from }),
  TO: (to: string) => getText('air:result.filters.airports.to', { to }),
};

interface AirportsFromToFilterProps {
  routeFilters: IRouteFilters[]
  values: IRouteFilters[],
  onChange(value: IRouteFilters[]): void,
}

const AirportsFromToFilter = ({ routeFilters, values, onChange }: AirportsFromToFilterProps) => {
  const handleChange = (value: string, field: string, idx: number) => {
    // @ts-ignore
    const valuesSet = new Set(values[idx][field]);

    if (valuesSet.has(value)) {
      valuesSet.delete(value);
      const newValues = values.map((item, index) => (index === idx ? { ...item, [field]: [...valuesSet] } : item));

      return onChange(newValues);
    }

    valuesSet.add(value);
    const newValues = values.map((item, index) => (index === idx ? { ...item, [field]: [...valuesSet] } : item));

    return onChange(newValues);
  };

  const renderAirportPart = (field: string, airports: string[], city: string, label: string, ind: number) => {
    const fromCheckboxHtml = airports.map((name, index) => {
      // @ts-ignore
      const checked = values[ind][field].includes(name);

      return (
        <Checkbox
          className={ styles.checkbox }
          key={ `check_airport_${field}_${index}` }
          // @ts-ignore
          id={ `check_airport_${field}_${index}` }
          value={ checked }
          themes='dark'
          onChange={ () => handleChange(name, field, ind) }
        >
          { name }
        </Checkbox>
      );
    });

    return (
      <Collapse
        opened
        className={ styles.item }
        label={ label }
        key={ `airport_${city}_${ind}` }
      >
        { fromCheckboxHtml }
      </Collapse>
    );
  };

  const html: React.ReactNode[] = [];

  routeFilters.forEach(({
    arrivalAirports, departureAirports, departureCity, arrivalCity,
  }: IRouteFilters, ind: number) => {
    const fromAirportsHtml = renderAirportPart('departureAirports', departureAirports, departureCity, LABELS.FROM(departureCity), ind);
    const toAirportsHtml = renderAirportPart('arrivalAirports', arrivalAirports, arrivalCity, LABELS.TO(arrivalCity), ind);

    if (fromAirportsHtml !== null) {
      html.push(fromAirportsHtml);
    }
    if (toAirportsHtml !== null) {
      html.push(toAirportsHtml);
    }
  });

  return html;
};

export { AirportsFromToFilter };
