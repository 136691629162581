import React from 'react';
import { Text } from 'yw-ui';

import styles from './index.module.scss';

interface INotificationItemProps {
  hotelName?: string;
  roomType?: string;
  date: string;
  detail: string;
}

const NotificationItem = ({
  hotelName = '',
  roomType = '',
  date,
  detail,
}: INotificationItemProps) => (
  <div className={ styles.wrap }>
    <Text type='SEMIBOLD_14'>{hotelName}</Text>
    <Text type='SEMIBOLD_14'>{roomType}</Text>
    <Text className={ styles.details }>
      <span className={ styles.dot }>{date}</span>
      <span className={ styles.dot }>{detail}</span>
    </Text>
  </div>
);

export { NotificationItem };
