import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import { currentLng } from '@/i18n';

import { useLazySearchHotelsQuery } from '@/app/bi/api/searchHotelsApi';
import { searchHotelsSlice } from '@/app/store/redusers/SearchHotelsSlice.ts';
import { useAppDispatch } from '@/app/store/hooks/redux.ts';

import { IHotelSearchRequest } from '@/app/bi/models/hotelSearch/hotelSearchTypes';
import { IInitSearchParams } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

export const useSearchParams = (): IHotelSearchRequest => {
  const { guid: hotelId } = useParams();
  const location = useLocation();

  const queryParse = queryString.parse(location.search);

  const {
    checkInDate,
    checkOutDate,
    checkInTime = null,
    checkOutTime = null,
    roomCount,
    travellersCount,
    regionId,
  } = queryParse;

  return {
    checkInDate: checkInDate as string,
    checkOutDate: checkOutDate as string,
    checkInTime: checkInTime as string ?? null,
    checkOutTime: checkOutTime as string ?? null,
    guests: Number(travellersCount),
    roomCount: Number(roomCount),
    locale: currentLng,
    hotelId: hotelId as string,
    regionId: regionId as string,
  };
};

export type useSearchHotelsReturnType = {
  isLoading: boolean;
  search: (params: Omit<IHotelSearchRequest, 'locale' | 'hotelId'>) => void;
};

export const useSearchHotels = (): useSearchHotelsReturnType => {
  const [searchHotels, { isFetching, isLoading }] = useLazySearchHotelsQuery();

  const body = useSearchParams();
  const search:useSearchHotelsReturnType['search'] = async (params) => {
    searchHotels({ ...params, locale: body.locale, hotelId: body.hotelId }).unwrap();
  };

  useEffect(() => {
    search(body);
  }, []);

  return { isLoading: isLoading || isFetching, search };
};

export const useInitSearch = () => {
  const { guid: hotelId } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { initSearch, resetStore } = searchHotelsSlice.actions;
  const queryParse = queryString.parse(location.search);
  const {
    checkInDate,
    checkOutDate,
    checkInTime = null,
    checkOutTime = null,
    guestsCount,
    travellersCount,
    regionName,
    roomCount,
  } = queryParse;

  useEffect(() => {
    const initSearchParams: IInitSearchParams = {
      regionName: regionName as string,
      hotelId: hotelId as string,
      checkInDate: checkInDate as string,
      checkOutDate: checkOutDate as string,
      guestsCount: Number(guestsCount),
      travellersCount: Number(travellersCount),
      checkInTime: checkInTime as string ?? null,
      checkOutTime: checkOutTime as string ?? null,
      roomCount: Number(roomCount),
    };

    dispatch(initSearch(initSearchParams));

    return () => { dispatch(resetStore()); };
  }, []);
};
