import { IconButton } from 'yw-ui';
import React, {
  useState, useEffect, useCallback, useRef, ReactNode, CSSProperties,
} from 'react';
import * as ReactDOM from 'react-dom';

import { MAIN_MENU_WRAPPER } from '../../bi/constants/elementsIds';

import { ITag } from '@/app/bi/models/shared.ts';

import styles from './index.module.scss';

interface StickyHidingPanelProps {
  children: ReactNode,
  adjustment?: number,
  hiddenAdjustment?: number,
  fixedClass?: string,
  className?: string,
  style?: CSSProperties,
  renderOnHeader?: boolean,
  initial?: number,
  tags?: ITag[],
}
const StickyHidingPanel = ({
  children,
  fixedClass,
  className,
  renderOnHeader,
  initial,
  adjustment = 0,
  hiddenAdjustment = 0,
  style: pStyle = {},
  tags = [],
}: StickyHidingPanelProps) => {
  const [isSticky, setSticky] = useState(false);
  const [isHidden, setHidden] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => setHidden(false), [tags]);

  const getRect = useCallback(() => (ref.current ? ref.current.getBoundingClientRect() : {}), [ref]);

  // @ts-ignore
  const handleScroll = useCallback((event) => {
    const scrollY = event.target.scrollingElement.scrollTop + adjustment;
    const pageHeight = document.documentElement.scrollHeight - hiddenAdjustment;

    if (scrollY > pageHeight) {
      return null;
    }

    // @ts-ignore
    if (getRect().top < scrollY) {
      setSticky(true);
      setHidden(true);
    }

    if (adjustment >= scrollY) {
      setSticky(false);
      setHidden(false);
    }

    return null;
  }, [adjustment, hiddenAdjustment, getRect]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const classNames = [];

  const style = {
    // @ts-ignore
    left: getRect().left,
    // @ts-ignore
    width: getRect().width,
    ...pStyle,
  };

  const handleHidden = () => {
    setHidden(!isHidden);
  };

  if (isSticky) {
    classNames.push(styles.fixed, fixedClass);
  }

  if (initial) {
    style.top = initial;
  }

  if (isSticky && renderOnHeader) {
    style.zIndex = 1;
  }

  if (isSticky) {
    style.top = adjustment;
  }

  if (!isSticky && className) {
    classNames.push(className);
  }

  if (isSticky) {
    const showBody = !isHidden && children;
    // @ts-ignore
    const { height } = getRect();

    const stickyContent = (
      <div>
        <div style={ { height: `${height}px` } } />
        <div
          ref={ ref }
          className={ classNames.join(' ') }
          style={ style }
        >
          { showBody }
          <div
            className={ styles.sticky_footer }
            onClick={ () => handleHidden() }
          >
            <IconButton
              iconType='arrowThinDown'
              className={ !isHidden ? styles.arrow_up : styles.arrow_down }
            />
          </div>
        </div>
      </div>
    );

    if (renderOnHeader) {
      return ReactDOM.createPortal(stickyContent, document.getElementById(MAIN_MENU_WRAPPER) as HTMLElement);
    }

    return stickyContent;
  }

  return (
    <div
      className={ classNames.join(' ') }
      style={ style }
      ref={ ref }
    >
      { children }
    </div>
  );
};

export { StickyHidingPanel };
