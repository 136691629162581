import React, { useState, useRef, MutableRefObject, RefObject, useCallback, useEffect, useMemo } from 'react';
import { Paginate, IconButton } from 'yw-ui';
import queryString from 'query-string';
import clsx from 'clsx';

import { useAppSelector } from '@/app/store/hooks/redux.ts';

import { MapProvider } from '@/app/components/Maps/GoogleMap';
import { HotelItem } from '../HotelItem';
import { HotelMapItem } from '../HotelMaplItem';
import { EmptyFilter } from '@/app/pages/HotelsResultPage/components/HotelsResult/components/EmptyFilter';
import { MapDialog } from '@/app/components/Maps/MapDialog';

import { scrollToTop } from '@/app/bi/utils/scrollToTop.ts';
import { dayjsObject, diffDays } from '@/app/bi/utils/formatDate.ts';
import { mapStateToHotelPageSearchSettings } from '@/app/bi/utils/hotelsSearch.ts';
import { formatCurrency } from '@/app/bi/utils/money.ts';

import ROUTES from '@/app/bi/constants/routes.ts';

import { IRegionSearchResponseItem } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import { IMapPoint } from '@/app/bi/models/maps.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';

import styles from './style.module.scss';

const WRAP_HEIGHT = 240;

const createMapPoints = (
  mapItems: IRegionSearchResponseItem[],
  currencyCode: ECurrencyCode,
): IMapPoint[] => mapItems.map(({ longitude, latitude, rate, hotelId }) => {
  const price = rate.prices.find(({ currency }) => currency === currencyCode);

  return ({
    lat: latitude,
    lng: longitude,
    price: formatCurrency(price?.total as number, currencyCode) || '',
    hotelId,
  });
});

interface HotelsResultProps {
  listTravelPolicies: ITravelPolicy[];
  selectPolicy: { id: string, policyName: string, isActive: boolean };
  pageHeaderRef: RefObject<HTMLDivElement | null>;
  onChangePage(page: number): void;
}

const HotelResult = ({
  listTravelPolicies,
  selectPolicy,
  pageHeaderRef,
  onChangePage,
}: HotelsResultProps) => {
  const [openMap, setOpenMap] = useState(false);
  const [hotel, setHotel] = useState<IMapPoint>({
    lat: 0,
    lng: 0,
    price: '',
    hotelId: '',
  });
  const [mapHeight, setMapHeight] = useState(0);

  const [selectedMarker, setSelectedMarker] = useState<string | null>(null);

  const {
    isMapMode,
    items,
    paging: {
      total,
      count,
      current,
    },
    mapItems,
    regionName,
    filters,
    regionLocation,
    searchProviderRequest,
    currencyCode,
  } = useAppSelector((state) => state.hotelsSlice);
  const {
    region,
    checkin,
    checkout,
    adult,
    customCheckin,
    customCheckout,
    travellersCount,
  } = useAppSelector((state) => state.searchHotelsReducer);
  const [isHover, setIsHover] = useState(false);
  const listRef = useRef(null);
  const mapRef = useRef(null);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null >(null);

  const params = mapStateToHotelPageSearchSettings({
    checkin,
    checkout,
    adult,
    regionId: region?.selected?.id as string,
    regionName: region?.selected?.name as string,
    customCheckin,
    customCheckout,
    travellersCount,
  });

  const points = useMemo(() =>
    createMapPoints(mapItems, filters?.currencyCode as ECurrencyCode), [mapItems, filters?.currencyCode],
  );
  const searchParams = queryString.stringify({ ...params });

  const checkinDate = dayjsObject(searchProviderRequest?.checkInDate);
  const checkoutDate = dayjsObject(searchProviderRequest?.checkOutDate);
  const amountDiffDays = diffDays(checkinDate, checkoutDate);

  useEffect(() => {
    updateMapDimensions();
    window.addEventListener('resize', updateMapDimensions);

    return () => { window.removeEventListener('resize', updateMapDimensions); };
  }, []);

  const handlePageChange = (page: number) => {
    onChangePage(page);

    if (pageHeaderRef) {
      setTimeout(() =>
        window.scroll({
          top: 0,
          behavior: 'smooth',
        }), 100);
    }
  };

  const scrollToItem = (ref: MutableRefObject<any>, item: any) => {
    ref.current?.scrollToRow(item);
    scrollToTop();
  };

  const handleScrollMap = () => (!isMapMode ? scrollToTop() : scrollToItem(listRef, 0));

  const handleCloseMap = (value: boolean) => setOpenMap(value);

  const renderMap = () => {
    const center = { lat: hotel.lat, lng: hotel.lng };

    return (
      <MapDialog
        show={ openMap }
        onChange={ handleCloseMap }
        renderDependsOnShow
      >
        <MapProvider center={ center } points={ [hotel] } selectedHotelId={ selectedMarker } />
      </MapDialog>
    );
  };

  const renderIconScrollButton = () => (
    <div
      onMouseEnter={ () => setIsHover(true) }
      onMouseLeave={ () => setIsHover(false) }
    >
      <IconButton
        iconType={ !isHover ? 'arrowsUpRoundBig' : 'arrowsUpRoundBigHover' }
        circleColor='gray1'
        size={ 60 }
        onClick={ handleScrollMap }
        className={ styles.arrow }
      />
    </div>
  );

  const renderListMapView = () => (
    <div className={ styles.list_map_wrap }>
      {renderHotelMapList()}
      {renderMapView()}
    </div>
  );

  const handleUpdateSelectHotelOnMap = useCallback((hotelId: string | null) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      setSelectedMarker((prev) => (prev === hotelId ? null : hotelId));
    }, 100);
  }, []);

  const updateMapDimensions = () => setMapHeight(window.innerHeight);

  const renderMapView = () => {
    const wrapHeight = mapHeight - WRAP_HEIGHT;

    const center = {
      lat: regionLocation.lat,
      lng: regionLocation.lng,
    };

    return (
      <div className={ styles.map_wrap } style={ { height: `${wrapHeight}px` } } ref={ mapRef } >
        <MapProvider
          center={ center }
          points={ points }
          updateSelectHotel={ handleUpdateSelectHotelOnMap }
          selectedHotelId={ selectedMarker }
        />
      </div>
    );
  };

  const renderOverlay = useCallback(() => {
    const selectItem = mapItems.find(({ hotelId }) => hotelId === selectedMarker);

    const wrapClassName = clsx([styles.list_map_overlay], {
      [styles.active]: selectedMarker,
    });

    return (
      <div className={ wrapClassName }>
        <div className={ styles.content }>
          {selectItem && (
            <HotelMapItem
              key={ selectItem.hotelId }
              item={ selectItem }
              hotelUrl={ `${ROUTES.SEARCH.HOTEL_PAGE}/${selectItem.hotelId}?${searchParams}` }
              travellersCount={ searchProviderRequest?.guests as number }
              roomCount={ searchProviderRequest?.roomCount as number }
              diffDays={ amountDiffDays }
              currencyCode={ filters?.currencyCode as ECurrencyCode }
            />
          )}
        </div>
      </div>
    );
  }, [selectedMarker]);

  const renderHotelMapList = () => {
    const defaultHotelList = items.map((item) => (
      <HotelMapItem
        key={ item.hotelId }
        item={ item }
        hotelUrl={ `${ROUTES.SEARCH.HOTEL_PAGE}/${item.hotelId}?${searchParams}` }
        travellersCount={ searchProviderRequest?.guests as number }
        roomCount={ searchProviderRequest?.roomCount as number }
        diffDays={ amountDiffDays }
        currencyCode={ filters?.currencyCode as ECurrencyCode }
      />
    ));

    return (
      <div className={ styles.list }>
        {renderOverlay()}
        {defaultHotelList}
        <div className={ styles.paging }>
          <Paginate
            onChange={ handlePageChange }
            page={ current }
            itemsPerPage={ count }
            total={ total }
          />
        </div>
      </div>
    );
  };

  const handleOpenMap = ({ rate, latitude, longitude, hotelId }: IRegionSearchResponseItem) => {
    const currencyPrice = rate.prices.find(({ currency }) => currency === filters?.currencyCode);
    const price = formatCurrency(currencyPrice?.total as number, filters?.currencyCode as ECurrencyCode);

    setHotel({ lat: latitude, lng: longitude, price, hotelId });
    setOpenMap(true);
  };

  const renderHotelList = () => {
    const defaultHotelList = items.map((item) => (
      <HotelItem
        key={ item.hotelId }
        hotelUrl={ `${ROUTES.SEARCH.HOTEL_PAGE}/${item.hotelId}?${searchParams}` }
        item={ item }
        listTravelPolicies={ listTravelPolicies }
        selectPolicy={ selectPolicy }
        regionName={ regionName }
        diffDays={ amountDiffDays }
        travellersCount={ searchProviderRequest?.guests as number }
        roomCount={ searchProviderRequest?.roomCount as number }
        currencyCode={ currencyCode }
        onShowHotelOnMap={ () => {} }
        onOpenMap={ handleOpenMap }
      />
    ));

    return (
      <>
        {defaultHotelList}
        <div className={ styles.paging }>
          <Paginate
            onChange={ handlePageChange }
            page={ current }
            itemsPerPage={ count }
            total={ total }
          />
        </div>
      </>
    );
  };

  const modalMapHtml = renderMap();

  const renderContent = () => {
    const isEmptyHotels = !items.length;

    if (isEmptyHotels) return <EmptyFilter />;

    return isMapMode ? renderListMapView() : renderHotelList();
  };

  return (
    <>
      {renderContent()}
      {modalMapHtml}
      {renderIconScrollButton()}
    </>
  );
};

export { HotelResult };
