import { RefObject } from 'react';

import { ISuggestion } from '@/app/bi/models/airlineSearchTypes.ts';

import { ECurrencyCode } from './hotelSearch/hotelSearchEnum';

export enum EFareServiceStatus {
  NotDefined = 'NotDefined',
  Charge = 'Charge',
  Included = 'Included',
  NotOffered = 'NotOffered',
  NotPenalty = 'NotPenalty',
}

export enum EAirlineClass {
  Econom = 'Econom',
  Business = 'Business',
  First = 'First',
  Comfort = 'Comfort',
}

interface FareCondition {
  description: string,
  status: EFareServiceStatus,
}

interface OptsItem {
  value: string,
  items: ISuggestion[],
  placeholder: string,
  ref: RefObject<HTMLInputElement>
  loading: boolean,
  onSelect(suggest: ISuggestion): void,
  onChange(query: string): void,
}

interface OptsType {
  from: OptsItem,
  to: OptsItem,
}

export interface ISearchOptionsRoute {
  departureCode: string,
  arrivalCode: string,
  arrivalName: string,
  departureName: string,
  date: string,
}

export interface IAirlineProviderSearchRequest {
  routes: ISearchOptionsRoute[],
  class: string
  isDirect: boolean,
  travellersCount: number,
}

export interface IAirlineSearchResponse {
  searchId: string;
  items: Items[];
  searchRequest: IAirlineProviderSearchRequest,
  page: number,
  pages: number;
  pageLimit: number,
  itemsCount: number,
}

export interface ISearchResponse {
  search: IAirlineSearchResponse
  filter: IAirlineSearchFilter;
}
export interface IFilterBaseMinMax {
  min: number,
  max: number
}
export interface IFilterPrice {
  min: number,
  max: number
}

export interface IFilterTransferDuration {
  min: number,
  max: number
}

export interface IFlightDuration {
  min: number;
  max: number;
}

export interface ISearchSort {
  sortedBy: string
  descending: boolean,
}

export interface IDepartureDateTime {
  min: string;
  max: string;
}

export interface IArrivalDateTime {
  min: string;
  max: string;
}

export interface IRouteFilters {
  routeId: number;
  departureCity: string,
  arrivalCity: string,
  arrivalAirports: string[];
  departureAirports: string[];
  departureDateTime: IDepartureDateTime;
  arrivalDateTime: IArrivalDateTime;
}

export interface IAirlineSearchFilter {
  searchSort: ISearchSort,
  price: IFilterPrice;
  transferDuration: IFilterTransferDuration;
  flightDuration: IFlightDuration;
  arrivalCity: string,
  departureCity: string,
  transfersCount: number[];
  airlines: string[];
  transferAirports: string[];
  travelPolicyId: string | null;
  flightNumbers: string[];
  routeFilters: IRouteFilters[];
  baggage: string[];
  page: number;
  currencyCode: ECurrencyCode;
}

export interface Items {
  id: string,
  provider: string,
  providerOfficeId: string;
  fares: Fares[];
  routes: Route[];
  duration: number;
  transferDuration: number;
  transferAirports: string[];
  travelPolicyValidationErrors: EAirlineValidationError[];
}

export enum EAirlineValidationError {
  MaxPrice = 'MaxPrice',
  PriceDifferenceLimit = 'PriceDifferenceLimit',
  Class = 'Class',
  Refundable = 'Refundable',
  DaysBuyLimit = 'DaysBuyLimit',
  Baggage = 'Baggage',
  Weekdays = 'Weekdays',
}

export interface Fares {
  id: string;
  name: string;
  flightToken: IFlightToken;
  price: Price;
  airlineClass: EAirlineClass;
  isBaggageIncluded: EFareServiceStatus;
  baggagePlaces: string | null;
  isCarryonIncluded: EFareServiceStatus;
  carryonPlaces: string | null;
  isTicketRefundable: EFareServiceStatus;
  isTicketRefundableAfterDeparture: EFareServiceStatus;
  isTicketChangeable: EFareServiceStatus;
  isTicketChangeableAfterDeparture: EFareServiceStatus;
  isBusinessHallIncluded: EFareServiceStatus;
  haveInternetAccess: EFareServiceStatus;
  isPriorityRegistration: EFareServiceStatus;
  isPriorityBaggageReception: EFareServiceStatus;
  airlineBonusInformation: string | null;
  canRegistrationSeat: EFareServiceStatus;
  canBuyBaggage: EFareServiceStatus;
  canUpgradeRate: EFareServiceStatus;
  baggageIncludedWithCondition: FareCondition[];
  refundTicketWithCondition: FareCondition[];
  changeTicketWithCondition: FareCondition[];
  otherPrices: Price[];
}

export interface Price {
  min: number;
  max: number;
  base: number;
  tax: number;
  taxes: number;
  fee: number;
  currency: ECurrencyCode;
  totalPrice: number;
}

export interface Route {
  id: number;
  segments: Segment[];
  duration: number;
}

export interface StopDetails {
  dateQualifier: string,
  date: string,
  equipementType: string,
  locationId: string,
}

export interface Segment {
  basis: string;
  bookingClass: string;
  fareFamily: string;
  fareType: string;
  name: string;
  flightNumber: string;
  marketingAirline: MarketingAirline;
  operatingAirline: OperatingAirline;
  aircraftName: string;
  departureDateTime: string;
  departureCountryCode: string;
  departureCountry: string;
  departureCity: string;
  departureAirport: DepartureAirport;
  departureTerminal: string;
  arrivalDateTime: string;
  arrivalCountryCode: string;
  arrivalCountry: string;
  arrivalCity: string;
  arrivalAirport: ArrivalAirport;
  arrivalTerminal: string;
  availableSeats: number;
  technicalStop: StopDetails[];
  transferDuration: number;
}

export interface IFlightToken {
  routes: RouteMeta[];
  validatingAirline: string;
}

interface RouteMeta {
  id: number,
  segments: SegmentMeta[],
}

interface SegmentMeta {
  basis: string,
  bookingClass: string,
  fareFamily: string,
  fareType: string,
}

export interface MarketingAirline {
  code: string;
  name: string;
}

export interface OperatingAirline {
  code: string;
  name: string;
}

export interface DepartureAirport {
  cityCode: string;
  code: string;
  name: string;
}

export interface ArrivalAirport {
  cityCode: string;
  code: string;
  name: string;
}

export type {
  OptsType,
  OptsItem,
};

export enum EMenuType {
  SubMenu = 'SubMenu',
  MainMenu = 'MainMenu',
}
