import React from 'react';
import { Icon, Text } from 'yw-ui';
import { getText } from '@/i18n';

import styles from './index.module.scss';

const LABELS = {
  CHECK_IN_CHECK_OUT_TIME: getText('hotels:hotelResult.item.checkInCheckOutTime'),
  STANDARD_CHECK_IN_TIME: getText('hotels:hotelResult.item.standardCheckInTime'),
  STANDARD_CHECK_OUT_TIME: getText('hotels:hotelResult.item.standardCheckOutTime'),
  ADDRESS: getText('hotels:hotelResult.address'),
};

interface HotelCheckInCheckOutBlockProps {
  checkInTime: string,
  checkOutTime: string,
}
const HotelCheckInCheckOutBlock = ({
  checkInTime,
  checkOutTime,
}: HotelCheckInCheckOutBlockProps) => (
  <div className={ styles.custom_time_wrapper }>
    <div className={ styles.header }>
      <Icon type='clock' size={ 24 } />
      <Text type='bold_16' color='gray-7'>{LABELS.CHECK_IN_CHECK_OUT_TIME}</Text>
    </div>
    <div className={ styles.content }>
      <div className={ styles.custom_time_block }>
        <Text type='bold_16'>{checkInTime}</Text>
        <Text type='NORMAL_14'>{LABELS.STANDARD_CHECK_IN_TIME}</Text>
      </div>
      <div className={ styles.custom_time_block }>
        <Text type='bold_16'>{checkOutTime}</Text>
        <Text type='NORMAL_14'>{LABELS.STANDARD_CHECK_OUT_TIME}</Text>
      </div>
    </div>
  </div>
);

export { HotelCheckInCheckOutBlock };
