import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { IconButton } from 'yw-ui';

import { ImageItem } from './components/ImageItem';
import { IImageGalleryItem, ImageThumbnailsItem } from './components/ImageThumbnailsItem';

import { throttle } from '@/app/bi/utils/throttle.ts';
import { getSlideStyle, getThumbnailStyle } from '@/app/components/ImageGallery/utils.ts';

import { EPositionThumbnails } from '@/app/bi/models/hotelsTypes.ts';

import styles from './index.module.scss';

const TIMEOUT_RESIZE = 300;
const MIN_INTERVAL = 500;
const THUMBNAIL_DELAY = 300;
const GHOT_CLICK_DELAY = 600;

interface ImageErrorEvent extends React.SyntheticEvent<HTMLImageElement, Event> {
  target: HTMLImageElement & EventTarget;
}

interface IPrevState {
  galleryWidth: number;
  currentIndex: number;
  galleryHeight: number;
}

interface IPrevProps {
  showThumbnails: boolean;
}

interface ImageGalleryProps {
  items: IImageGalleryItem[];
  showNav?: boolean;
  autoPlay?: boolean;
  infinite?: boolean;
  showThumbnails?: boolean;
  positionThumbnails?: EPositionThumbnails;
  slideOnThumbnailHover?: boolean;
  disableThumbnailScroll?: boolean;
  defaultImage?: string | null;
  theme?: string;
  startIndex?: number;
  slideInterval?: number,
  originalImageStyles?: object | null;
  onSlide?(index: number): void;
  onPause?(index: number): void;
  onPlay?(index: number): void;
  onClick?(): void;
  onSlideLeft?(): void;
  onSlideRight?(): void;
  onImageLoad?(): void;
  onImageError?(): void;
  onThumbnailError?(event: ImageErrorEvent): void;
}

const ImageGallery = ({
  items = [],
  showNav = true,
  autoPlay = false,
  infinite = true,
  showThumbnails = true,
  positionThumbnails = EPositionThumbnails.Bottom,
  slideOnThumbnailHover = false,
  disableThumbnailScroll = false,
  theme = 'default',
  startIndex = 0,
  slideInterval = 3000,
  defaultImage = null,
  onSlide = () => {},
  onPause = () => {},
  onPlay = () => {},
  onClick = () => {},
  onImageLoad = () => {},
  onImageError = () => {},
  onThumbnailError = () => {},
  onSlideLeft = () => {},
  onSlideRight = () => {},
  originalImageStyles = null,
}: ImageGalleryProps) => {
  const preventGhostClick = useRef<boolean>(false);
  const intervalId = useRef<number | null>(null);
  const preventGhostClickTimer = useRef<number | null>(null);
  const thumbnailTimer = useRef<number | null>(null);
  const imageGallery = useRef<HTMLElement | null>(null);
  const thumbnails = useRef<HTMLDivElement | null>(null);
  const hovering = useRef<boolean>(false);

  const [currentIndex, setCurrentIndex] = useState(startIndex);
  const [thumbsTranslateX, setThumbsTranslateX] = useState(0);
  const [thumbsTranslateY, setThumbsTranslateY] = useState(0);
  const [offsetPercentage, setOffsetPercentage] = useState(0);
  const [galleryWidth, setGalleryWidth] = useState(0);
  const [galleryHeight, setGalleryHeight] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(0);
  const [style, setStyle] = useState({ transition: '' });

  const prevState = useRef<IPrevState>({ galleryWidth, currentIndex, galleryHeight });
  const prevProps = useRef<IPrevProps>({ showThumbnails });

  const thumbnailStyle = getThumbnailStyle(thumbsTranslateX, thumbsTranslateY, positionThumbnails);
  const classNameWrap = clsx([styles.image_gallery], {
    [styles.default]: positionThumbnails === EPositionThumbnails.Bottom,
    [styles.left]: positionThumbnails === EPositionThumbnails.Left,
  });

  const setHovering = (value: boolean) => {
    hovering.current = value;
  };

  useEffect(() => {
    window.setTimeout(() => handleResize(), TIMEOUT_RESIZE);

    if (autoPlay) {
      play();
    }

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', handleResize);

      if (intervalId.current) {
        window.clearInterval(intervalId.current);
        intervalId.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (positionThumbnails === EPositionThumbnails.Left
      && (prevState.current.galleryHeight !== galleryHeight
        || prevProps.current.showThumbnails !== showThumbnails)) {
      setThumbsTranslateY(
        -getThumbsTranslateY(
          currentIndex > 0 ? 1 : 0,
        ) * currentIndex,
      );
    }

    if (positionThumbnails === EPositionThumbnails.Bottom
      && (prevState.current.galleryWidth !== galleryWidth ||
      prevProps.current.showThumbnails !== showThumbnails)) {
      setThumbsTranslateX(
        -getThumbsTranslateX(
          currentIndex > 0 ? 1 : 0) * currentIndex);
    }

    if (prevState.current.currentIndex !== currentIndex) {
      if (onSlide) {
        onSlide(currentIndex);
      }

      if (positionThumbnails === EPositionThumbnails.Left) {
        updateThumbnailTranslateY(prevState.current.currentIndex);
      } else {
        updateThumbnailTranslateX(prevState.current.currentIndex);
      }
    }

    prevState.current = {
      currentIndex,
      galleryWidth,
      galleryHeight,
    };

    prevProps.current = {
      showThumbnails,
    };
  }, [galleryHeight, currentIndex]);

  const play = (callback = true) => {
    if (intervalId.current) {
      return;
    }

    const timeout = slideInterval > MIN_INTERVAL ? slideInterval : MIN_INTERVAL;

    intervalId.current = window.setInterval(() => {
      if (!hovering.current) {
        if (!infinite && !canSlideRight()) {
          pause();
        } else {
          slideToIndex(prevState.current.currentIndex + 1);
        }
      }
    }, timeout);

    if (onPlay && callback) {
      onPlay(currentIndex);
    }
  };

  const pause = (callback = true) => {
    if (intervalId.current) {
      window.clearInterval(intervalId.current);
      intervalId.current = null;
    }

    if (onPause && callback) {
      onPause(currentIndex);
    }
  };

  const slideToIndex = (index: number) => {
    const slideCount = items.length - 1;
    let newCurrentIndex = index;

    if (index < 0) {
      newCurrentIndex = slideCount;
    } else if (index > slideCount) {
      newCurrentIndex = 0;
    }

    setPreviousIndex(newCurrentIndex);
    setCurrentIndex(newCurrentIndex);
    setOffsetPercentage(0);
    setStyle({ transition: 'transform .2s ease-out' });
  };

  const touchEnd = () => {
    preventGhostClick.current = true;

    preventGhostClickTimer.current = window.setTimeout(() => {
      preventGhostClick.current = false;
      preventGhostClickTimer.current = null;
    }, GHOT_CLICK_DELAY);
  };

  const handleResize = () => {
    if (imageGallery.current) {
      setGalleryWidth(imageGallery.current.offsetWidth);
      setGalleryHeight(imageGallery.current.offsetHeight);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const LEFT_ARROW = 'ArrowLeft';
    const RIGHT_ARROW = 'ArrowRight';

    switch (event.key) {
      case LEFT_ARROW:
        if (canSlideLeft() && !intervalId.current) {
          throttleSlideLeft();
        }
        break;
      case RIGHT_ARROW:
        if (canSlideRight() && !intervalId.current) {
          throttleSlideRight();
        }
        break;
    }
  };

  const handleMouseOverThumbnails = (index: number) => {
    if (slideOnThumbnailHover) {
      setHovering(true);

      if (thumbnailTimer.current) {
        window.clearTimeout(thumbnailTimer.current);
        thumbnailTimer.current = null;
      }
      thumbnailTimer.current = window.setTimeout(() => {
        slideToIndex(index);
      }, THUMBNAIL_DELAY);
    }
  };

  const handleMouseLeaveThumbnails = () => {
    if (thumbnailTimer.current) {
      window.clearTimeout(thumbnailTimer.current);
      thumbnailTimer.current = null;

      if (autoPlay) {
        play(false);
      }
    }
    setHovering(false);
  };

  const handleMouseOver = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  const handleImageError = (event: ImageErrorEvent) => {
    let targetImage = event.target.src;

    if (defaultImage && targetImage.indexOf(defaultImage) === -1) {
      targetImage = defaultImage;
    }

    return null;
  };

  const canNavigate = () => items.length >= 2;

  const canSlideLeft = () => {
    if (infinite) {
      return true;
    }

    return currentIndex > 0;
  };

  const canSlideRight = () => {
    if (infinite) {
      return true;
    }

    return currentIndex < items.length - 1;
  };

  const updateThumbnailTranslateX = (prevCurrentIndex: number) => {
    if (currentIndex === 0) {
      setThumbsTranslateX(0);
    } else {
      const indexDifference = Math.abs(prevCurrentIndex - currentIndex);
      const scrollX = getThumbsTranslateX(indexDifference);

      if (scrollX > 0) {
        if (prevCurrentIndex < currentIndex) {
          setThumbsTranslateX(
            thumbsTranslateX - scrollX,
          );
        } else if (prevCurrentIndex > currentIndex) {
          setThumbsTranslateX(
            thumbsTranslateX + scrollX,
          );
        }
      }
    }
  };

  const updateThumbnailTranslateY = (prevCurrentIndex: number) => {
    if (currentIndex === 0) {
      setThumbsTranslateY(0);
    } else {
      const indexDifference = Math.abs(prevCurrentIndex - currentIndex);
      const scrollY = getThumbsTranslateY(indexDifference);

      if (scrollY > 0) {
        if (prevCurrentIndex < currentIndex) {
          setThumbsTranslateY(thumbsTranslateY - scrollY);
        } else if (prevCurrentIndex > currentIndex) {
          setThumbsTranslateY(thumbsTranslateY + scrollY);
        }
      }
    }
  };

  const getThumbsTranslateY = (indexDifference: number) => {
    if (disableThumbnailScroll) {
      return 0;
    }

    if (thumbnails.current) {
      if (thumbnails.current?.scrollHeight <= galleryHeight) {
        return 0;
      }

      const totalThumbnails = thumbnails.current.children.length;
      const totalScrollY = (thumbnails.current?.scrollHeight || 0) - galleryHeight;
      const perIndexScrollY = totalScrollY / (totalThumbnails - 1);

      return indexDifference * perIndexScrollY;
    }

    return 0;
  };

  const getThumbsTranslateX = (indexDifference: number) => {
    if (disableThumbnailScroll) {
      return 0;
    }

    if (thumbnails.current) {
      if (thumbnails.current.scrollWidth <= galleryWidth) {
        return 0;
      }

      const totalThumbnails = thumbnails.current.children.length;
      const totalScrollX = thumbnails.current.scrollWidth - galleryWidth;
      const perIndexScrollX = totalScrollX / (totalThumbnails - 1);

      return indexDifference * perIndexScrollX;
    }

    return 0;
  };

  const slideLeft = () => {
    slideToIndex(prevState.current.currentIndex - 1);

    if (onSlideLeft) {
      onSlideLeft();
    }
  };

  const slideRight = () => {
    slideToIndex(prevState.current.currentIndex + 1);

    if (onSlideRight) {
      onSlideRight();
    }
  };

  const throttleSlideLeft = useCallback(
    throttle(slideLeft, MIN_INTERVAL),
    [],
  );

  const throttleSlideRight = useCallback(
    throttle(slideRight, MIN_INTERVAL),
    [],
  );

  const renderThumbnails = (thumbStyle: any) => {
    if (!showThumbnails) return null;

    const classNameContainer = clsx(styles.image_gallery_thumbnails_container, {
      [styles.image_gallery_thumbnails_container_default]: positionThumbnails === EPositionThumbnails.Bottom,
      [styles.image_gallery_thumbnails_container_left]: positionThumbnails === EPositionThumbnails.Left,
    });

    const newOnThumbnailError = onThumbnailError || handleImageError;

    return (
      <div className={ styles.image_gallery_thumbnails }>
        <div
          ref={ thumbnails }
          className={ classNameContainer }
          style={ thumbStyle }
        >
          {items.map((item, index) => (
            <ImageThumbnailsItem
              key={ index }
              item={ item }
              index={ index }
              currentIndex={ currentIndex }
              onMouseOver={ () => handleMouseOverThumbnails(index) }
              onMouseLeave={ handleMouseLeaveThumbnails }
              onTouchStart={ () => slideToIndex(index) }
              onTouchEnd={ () => slideToIndex(index) }
              onClick={ () => slideToIndex(index) }
              onThumbnailError={ newOnThumbnailError }
            />
          ))}
        </div>
      </div>
    );
  };

  const renderSlides = () => items.map((item, index) => {
    const slideStyle = getSlideStyle({
      index,
      previousIndex,
      items,
      infinite,
      currentIndex,
      offsetPercentage,
    });

    const imageItemStyle = Object.assign(slideStyle, style);

    return (
      <ImageItem
        key={ item.original }
        item={ item }
        slideStyle={ imageItemStyle }
        theme={ theme }
        originalImageStyles={ originalImageStyles }
        onClick={ onClick }
        onTouchStart={ onClick }
        onTouchEnd={ touchEnd }
        onImageLoad={ onImageLoad }
        onImageError={ onImageError }
      />
    );
  });

  const renderNavButtons = () => {
    if (!canNavigate() || !showNav) return null;

    const smallStyles = theme === 'small' ? styles.small : '';

    return (
      <span key='navigation'>
        {canSlideLeft() && (
          <IconButton
            iconType='leftCircle'
            size={ 24 }
            iconColor='blue1'
            circleColor='blue1'
            // @ts-ignore
            onClick={ slideLeft }
            className={ `${styles.image_gallery_left_nav} ${smallStyles}` }
          />
        )}
        {canSlideRight() && (
          <IconButton
            iconType='rightCircle'
            size={ 24 }
            // @ts-ignore
            iconColor='blue1'
            circleColor='blue1'
            onClick={ slideRight }
            className={ `${styles.image_gallery_right_nav} ${smallStyles}` }
          />
        )}
      </span>
    );
  };

  return (
    <section ref={ imageGallery } className={ classNameWrap }>
      { renderThumbnails(thumbnailStyle)}
      <div
        onMouseOver={ handleMouseOver }
        onMouseLeave={ handleMouseLeave }
        className={ styles.image_gallery_content }
      >
        {renderNavButtons()}
        <div className={ styles.image_gallery_slides } key='slides'>
          {renderSlides()}
        </div>
      </div>
    </section>
  );
};

export { ImageGallery };
