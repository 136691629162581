import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Text } from 'yw-ui';
import { TFunction } from 'i18next';

import { IHotelSearchItemResponse } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './index.module.scss';

interface ServicesComponentProps {
  info: IHotelSearchItemResponse;
  className?: string;
}

const createLabels = (t: TFunction) => ({
  ABOUT_OF_HOTEL: t('hotels:hotelResult.aboutOfHotel'),
  MORE_ABOUT_OF_HOTEL: t('hotels:hotelResult.moreAboutOfHotel'),
});

const HotelServices = ({
  info: {
    description = '',
    amenitiesGroups,
  },
  className = '',
}: ServicesComponentProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const renderAmenities = () => {
    const amenitiesHtml = amenitiesGroups
      .filter((item) => item.amenities.length > 0)
      .map((item) => (
        <div key={ item.type } className={ styles.amenities_type } >
          <Text type='SEMIBOLD_16' className={ styles.amenities_type_title }>
            { item.type }
          </Text>
          <div className={ styles.amenities_type_item }>
            {item.amenities.map((amenity) => (
              <Text key={ amenity } className={ styles.amenities_type_item_text }>{amenity}</Text>
            ))}
          </div>
        </div>
      ));

    return (
      amenitiesHtml.length > 0 ? (
        <div className={ styles.amenities }>
          <Text type='SEMIBOLD_18'>{ LABELS.MORE_ABOUT_OF_HOTEL }</Text>
          <div className={ styles.amenities_content }>
            {amenitiesHtml}
          </div>
        </div>
      ) : null
    );
  };

  const descriptionHtml = !!description.length && (
    <div className={ styles.description }>
      <Text type='bold_24'>{ LABELS.ABOUT_OF_HOTEL }</Text>
      <div
        className={ styles.text }
        dangerouslySetInnerHTML={ { __html: description } }
      />
    </div>
  );

  return (
    <div className={ clsx([styles.wrapper, className]) }>
      { descriptionHtml }
      { renderAmenities() }
    </div>
  );
};

export { HotelServices };
