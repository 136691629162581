import React, { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Text } from 'yw-ui';

import SuggestField from './ui/SuggestField';
import InputField from './ui/InputField';
import ClearButton from './ui/ClearButton';
import RuleBox from './ui/RuleBox';
import AddButton from './ui/AddButton';

import { BONUS_CARDS } from './constant';

import { SchemaType } from './schema';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  TITLE: t('settings:bonusCard.title'),
});

const BonusCard = () => {
  const { control } = useFormContext<SchemaType>();
  const { fields, append, remove } = useFieldArray({ control, name: BONUS_CARDS });
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  return (
    <div className={ styles.container }>
      <Text type='bold_18' >{LABELS.TITLE}</Text>

      <RuleBox/>

      {fields.map(({ id }, index) => (
        <div className={ styles.form_container } key={ id }>
          <div className={ styles.input_container }>
            <SuggestField index={ index }/>
            <InputField index={ index }/>
          </div>
          <ClearButton index={ index } fieldsCount={ fields.length } remove={ remove }/>
        </div>
      ))}

      <AddButton fieldsCount={ fields.length } append={ append }/>
    </div>
  );
};

export default BonusCard;
