import React, { ReactNode } from 'react';

import styles from './index.module.scss';

interface TableProps {
  column: string[];
  rows(): ReactNode;
}

const Table = ({ column, rows }: TableProps) => {
  const renderColumn = column.map((col, index) => (
    <th key={ `col_${col}_${index}` }>{col}</th>
  ));

  return (
    <table className={ styles.table }>
      <thead>
        <tr>{renderColumn}</tr>
      </thead>
      <tbody>{rows()}</tbody>
    </table>
  );
};

export { Table };
