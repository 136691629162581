import React, { useCallback } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { clsx } from 'clsx';

import { IMarkerProps } from './type';

import styles from './index.module.scss';

const Marker = ({ point, selectedHotelId, updateSelectHotel, setMarkerRef }: IMarkerProps) => {
  const { price, hotelId, lat, lng } = point;

  const className = clsx(styles.price_tag, { [styles.active]: hotelId === selectedHotelId });

  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) =>
      setMarkerRef(marker, hotelId),
    [setMarkerRef, hotelId],
  );

  const handleClick = () => {
    updateSelectHotel?.(hotelId);
  };

  return (
    <AdvancedMarker
      position={ { lat, lng } }
      key={ hotelId }
      ref={ ref }
      onClick={ handleClick }
    >
      <div className={ className }>{price}</div>
    </AdvancedMarker>
  );
};

export { Marker };
