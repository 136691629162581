import React, { useState, useEffect, useRef } from 'react';
import { Icon, IconButton, IconType } from 'yw-ui';

import styles from './index.module.scss';

const MODES = {
  PREVIEW: 'preview',
  EDIT: 'edit',
};

const ENTER_KEY = 'Enter';
const ESC_KEY = 'Escape';

interface EditableTextProps {
  value: string
  placeholder?: string
  iconType?: IconType | null,
  renderPreview: () => React.JSX.Element,
  editClassName?: string,
  onChange: (value: string) => void,
  onInputChange?: (value: string) => void,
}

const EditableText = ({
  value = '',
  placeholder = '',
  iconType = null,
  renderPreview,
  editClassName,
  onChange = () => { },
  onInputChange = () => { },
}: EditableTextProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [mode, setMode] = useState<string>(MODES.PREVIEW);
  const [localValue, setLocalValue] = useState<string>(value);

  const onSave = () => {
    if (localValue !== value) {
      onChange(localValue);
    }

    setMode(MODES.PREVIEW);
  };

  const onClose = () => {
    setMode(MODES.PREVIEW);
    setLocalValue('');
    onInputChange('');
  };

  useEffect(() => {
    if (mode === MODES.EDIT) {
      setLocalValue(value);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [mode, value]);

  const editIcon = !!iconType && (<Icon className={ styles.icon } type={ iconType } />);

  const editClassNames = [styles['input-wrapper']];

  if (editClassName) {
    editClassNames.push(editClassName);
  }

  const onInputFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (event.target) {
      event.target.select();
    }
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case ENTER_KEY:
        return onSave();
      case ESC_KEY:
        return onClose();
      default:
        return null;
    }
  };

  const onChangeInputValue = ({ target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(newValue);
    onInputChange(newValue);
  };

  const onClickText = () => setMode(MODES.EDIT);

  const renderButton = () => (
    <div className={ styles.edit }>
      <div className={ editClassNames.join(' ') }>
        {editIcon}
        <input
          ref={ inputRef }
          onFocus={ onInputFocus }
          placeholder={ placeholder }
          value={ localValue }
          onKeyDown={ onInputKeyDown }
          onChange={ onChangeInputValue }
        />
      </div>
      <div className={ styles.actions }>
        <IconButton
          iconType='checkArrow'
          iconColor='blue1'
          onClick={ onSave }
          size={ 16 }
          className={ styles.icon }
        />
        <IconButton
          iconType='closeOn'
          iconColor='blue1'
          onClick={ onClose }
          size={ 16 }
          className={ styles.icon }
        />
      </div>
    </div>
  );

  const renderContent = mode === MODES.PREVIEW ? (
    <div className={ styles.preview } onClick={ onClickText }>
      {renderPreview()}
    </div>
  ) : (
    renderButton()
  );

  return (
    <div className={ styles.wrapper }>
      {renderContent}
    </div>
  );
};

export { EditableText };
