import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledWrapper, Text, Button } from 'yw-ui';

import { TFunction } from 'i18next';

import { Rate } from '../Rate';
import { Details } from '@/app/pages/HotelResult/components/Hotel/components/RoomGroup/components/Details';
import { HotelGallery } from '@/app/pages/HotelResult/components/Hotel/components/RoomGroup/components/HotelGallery';

import { preloadOrigUrlAndUrl } from '@/app/bi/utils/images.ts';
import textAbbreviation from '@/app/bi/utils/textAbbreviation';

import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import { SearchSettings } from '@/app/bi/models/hotelsTypes.ts';
import {
  IHotelSearchImageResponse,
  IHotelSearchRoomGroupResponse,
} from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  MORE_INFO: t('hotels:hotelResult.item.roomGroup.moreInfo'),
});

interface RoomGroupProps {
  room: IHotelSearchRoomGroupResponse;
  currencyCode: ECurrencyCode;
  searchSettings: SearchSettings;
  onGallerySlideLeft(): void;
  onGallerySlideRight(): void;
  onShowRoomDetails(renderFn: ()=> ReactNode): void;
  roomCount: number
}

const RoomGroup = ({
  room,
  searchSettings,
  currencyCode,
  onGallerySlideLeft,
  onGallerySlideRight,
  onShowRoomDetails,
  roomCount,
}: RoomGroupProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const [loadedImgs, setLoadedImgs] = useState<IHotelSearchImageResponse[]>([]);
  const [allImageLoadFailed, setAllImageLoadFailed] = useState(false);

  const preloadAndFilterImages = async () => {
    const { images } = room;
    if (!images?.length) {
      return;
    }

    const preloadedImages: (IHotelSearchImageResponse | null)[] = await Promise.all(images.map(preloadOrigUrlAndUrl));
    const filteredImages: IHotelSearchImageResponse[] = preloadedImages.filter(Boolean) as IHotelSearchImageResponse[];
    const newAllImageLoadFailed = !filteredImages.length;

    setLoadedImgs(filteredImages);
    setAllImageLoadFailed(newAllImageLoadFailed);
  };

  useEffect(() => {
    preloadAndFilterImages();
  }, []);

  const handleShowRoomDetails = () => onShowRoomDetails(renderDetails);

  const renderDetails = () => (
    <Details
      room={ room }
      allImageLoadFailed={ allImageLoadFailed }
      Images={ images }
      loadedImgs={ loadedImgs }
      details
      onGallerySlideLeft={ onGallerySlideLeft }
      onGallerySlideRight={ onGallerySlideRight }
      handleShowRoomDetails={ handleShowRoomDetails }
    />
  );
  const { currencyRateGroups, name, images, description } = room;
  const selectStyles = styles.title;

  const newCurrencyRateGroups = currencyRateGroups.filter((item) => item.currencyCode === currencyCode);

  if (newCurrencyRateGroups.length === 0) {
    return null;
  }

  const rateGroupsHtml = newCurrencyRateGroups.map(({ rates }) => rates.map((rate) => (
    <Rate
      key={ rate.bookId }
      rate={ rate }
      currencyCode={ currencyCode }
      searchSettings={ searchSettings }
      roomCount={ roomCount }
    />
  )));

  const descriptionStr = description
    ? textAbbreviation(description.substring(description.indexOf('<p>')), 300)
    : '';

  return (
    <StyledWrapper className={ styles.wrapper }>
      <div className={ selectStyles }>
        <Text type='SEMIBOLD_16' color='gray-8'>{ name }</Text>
        <Button onClick={ handleShowRoomDetails } type='text'>
          {LABELS.MORE_INFO}
        </Button>
      </div>
      <div className={ styles.body }>
        <div className={ styles.details_wrapper }>
          <div className={ styles.images }>
            <HotelGallery
              allImageLoadFailed={ allImageLoadFailed }
              Images={ images }
              loadedImgs={ loadedImgs }
              onGallerySlideLeft={ onGallerySlideLeft }
              onGallerySlideRight={ onGallerySlideRight }
              handleShowRoomDetails={ handleShowRoomDetails }
            />
          </div>
          <Text type='NORMAL_16_140' className={ styles.description }>
            <div dangerouslySetInnerHTML={ { __html: descriptionStr } }/>
          </Text>
        </div>
        <div className={ styles.info }>
          {rateGroupsHtml}
        </div>
      </div>
    </StyledWrapper>
  );
};

export { RoomGroup };
