import React, { useState } from 'react';
import { CircleLoading, IconButton, Text } from 'yw-ui';
import { useNavigate } from 'react-router-dom';
import { getText } from '@/i18n';

import { EmployeeSuggest } from '@/app/pages/Cart/components/CartItem/components/EmployeeSuggest';
import { EmployeePassport } from '@/app/pages/Cart/components/CartItem/components/EmployeePassport';
import { SelectCompany } from '@/app/pages/Cart/components/CartItem/components/SelectCompany';

import { getEmployeeFullName } from '@/app/bi/utils/employees.ts';
import ROUTES from '@/app/bi/constants/routes.ts';

import { IEmployeeObj } from '@/app/bi/models/employees.ts';
import { ICartResponseEmployee } from '@/app/bi/models/cart.ts';

import styles from './index.module.scss';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

const LABELS = {
  ADD_EMPLOYEE_PASSPORT: getText('components:employeeItem.addEmployeePassport'),
  ERROR_EMPTY_EMPLOYEE: getText('components:employeeItem.errorEmptyEmployee'),
};

interface EmployeeItemProps {
  item: ICartResponseEmployee | null;
  items: ICartResponseEmployee[];
  index: number;
  isError: boolean;
  serviceType: EServiceTypes;
  onAddEmployeeToCard(employeeId: string): void;
  onRemoveEmployee(employeeId: string): void;
  onUpdateDataEmployee(item: {
    employeeId: string;
    employeeDocumentId: string | null;
    companyId: number | null;
  }): void;
}

const EmployeeItem = ({
  item,
  items,
  index,
  isError = false,
  serviceType,
  onAddEmployeeToCard,
  onRemoveEmployee,
  onUpdateDataEmployee,
}: EmployeeItemProps) => {
  const navigate = useNavigate();

  const [userLoading, setUserLoading] = useState(false);

  const handleSelected = async (suggestion: IEmployeeObj) => {
    if (!suggestion) {
      return;
    }

    setUserLoading(true);

    try {
      await onAddEmployeeToCard(suggestion.actualVersion.employeeId);
    } finally {
      setUserLoading(false);
    }
  };

  const handleChangeDocument = async (documentId: string) => {
    await onUpdateDataEmployee({
      employeeId: item?.id as string,
      employeeDocumentId: documentId,
      companyId: item?.company?.id || null,
    });
  };

  const handleRemoveEmployee = async () => {
    setUserLoading(true);

    await onRemoveEmployee(item?.id as string);

    setUserLoading(false);
  };

  const handleChangeCompany = async (companyId: number | null) => onUpdateDataEmployee({
    // @ts-ignore
    employeeId: item?.id,
    employeeDocumentId: item?.document?.id || null,
    companyId,
  });

  const handleAddEmployeePassport = () => navigate(`${ROUTES.EMPLOYEE}${item?.id}`);

  const renderAddPassport = () => (
    <div className={ styles.passport }>
      <IconButton
        iconType='plus'
        iconColor='blue1'
        onClick={ handleAddEmployeePassport }
      >
        <Text type='NORMAL_14' color='light-blue-4'>
          {LABELS.ADD_EMPLOYEE_PASSPORT}
        </Text>
      </IconButton>
    </div>
  );

  const renderEmployeePassport = () => {
    if (!item || (!item?.documents.length && !item.document)) {
      return renderAddPassport();
    }

    return (
      <div className={ styles.passport }>
        <EmployeePassport
          document={ item.document }
          documents={ item.documents }
          onChangeDocument={ handleChangeDocument }
        />
      </div>
    );
  };

  const deleteIcon = (
    <IconButton
      onClick={ handleRemoveEmployee }
      iconType='closeOn'
      size={ 16 }
      iconColor='gray5'
      className={ styles.remove }
    />
  );

  const icon = userLoading
    ? <CircleLoading size={ 20 } className={ styles.loading } />
    : deleteIcon;

  const renderCompany = () => {
    if (!item?.companies || serviceType === EServiceTypes.Hotel) return null;

    const prepareCompany = item.companies
      .map((companyItem) => ({ label: companyItem.name, value: companyItem.id }));

    return (
      <div className={ styles.add_company }>
        <SelectCompany
          value={ item?.company }
          serviceType={ serviceType }
          companies={ prepareCompany }
          onChange={ handleChangeCompany }
        />
      </div>
    );
  };

  const renderItem = () => (
    <div className={ styles.add_employee }>
      <div className={ styles.employee_container }>
        <div className={ styles.employee }>
          <Text className={ styles.number } color='gray-7' type='NORMAL_14' fontWeight={ 600 }>
            {index}.
          </Text>
          <Text
            color='gray-7'
            type='NORMAL_14'
            fontWeight={ 600 }
          >
            {getEmployeeFullName(item as ICartResponseEmployee)}
          </Text>
          {icon}
        </div>
        {serviceType !== EServiceTypes.Hotel && renderEmployeePassport()}
      </div>
      {renderCompany()}
    </div>
  );

  const renderEnterEmployee = () => (
    <div className={ styles.enter_container }>
      <div className={ styles.suggest }>
        <EmployeeSuggest
          onSelect={ handleSelected }
          userLoading={ userLoading }
          item={ item }
          items={ items }
          // debounceMs={ 0 }
          isError={ isError }
        />
      </div>
      {isError && (
        <span className={ styles.error }>
          {LABELS.ERROR_EMPTY_EMPLOYEE}
        </span>
      )}
    </div>
  );

  const renderContent = () => {
    if (item) return renderItem();

    return (
      <div className={ styles.row }>
        {renderEnterEmployee()}
      </div>
    );
  };

  return (
    <div className={ styles.wrapper }>
      {renderContent()}
    </div>
  );
};

export { EmployeeItem };
