import React from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';

import { Map } from './components/Map';

import CONFIG from '@/config.ts';

import { IMapProps } from './components/Map/type';

type MapProviderProps = IMapProps;

const MapProvider = (props: MapProviderProps) => (
  <APIProvider apiKey={ CONFIG.GOOGLE_MAPS.API_KEY } >
    <Map { ...props } />
  </APIProvider>
);

export { MapProvider };
