import React, { useMemo } from 'react';
import { Checkbox, Collapse } from 'yw-ui';
import { useTranslation } from 'react-i18next';

import styles from '../../index.module.scss';

interface AirlineCompaniesFilterProps {
  airlines: string[],
  values: string[],
  onChange(value: string[]): void,
}

const AirlineCompaniesFilter = ({
  airlines = [],
  values = [],
  onChange,
}: AirlineCompaniesFilterProps) => {
  const { t } = useTranslation();
  const LABEL = useMemo(() => t('air:result.filters.airlines.label'), [t]);

  const handleChange = (value: string) => {
    const valuesSet = new Set(values);

    if (valuesSet.has(value)) {
      valuesSet.delete(value);

      return onChange([...valuesSet]);
    }

    valuesSet.add(value);

    return onChange([...valuesSet]);
  };

  const renderAirlines = () => airlines.map((name, index) => {
    const checked = values.includes(name);

    return (
      <Checkbox
        themes='dark'
        className={ styles.checkbox }
        key={ `check_airline_${index}` }
        value={ checked }
        onChange={ () => handleChange(name) }
      >
        { name }
      </Checkbox>
    );
  });

  const renderContent = () => {
    if (!airlines.length) {
      return null;
    }

    return (
      <Collapse
        opened
        className={ styles.item }
        label={ LABEL }
      >
        { renderAirlines() }
      </Collapse>
    );
  };

  return renderContent();
};

export { AirlineCompaniesFilter };
