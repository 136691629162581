import React, { ReactNode, useState } from 'react';
import { Button, Dialog, Select, Text } from 'yw-ui';
import { getText } from '@/i18n';

import { useGetTripByStatusesQuery } from '@/app/bi/api/tripApi.ts';

import { ETripStatus } from '@/app/bi/models/tripsTypes.ts';

import styles from './styles.module.scss';

type ItemType = {
  label: string,
  value: string | number
};

const TEST_BODY = {
  statuses: [
    ETripStatus.AwaitingBooking,
    ETripStatus.Booked,
  ],
};

const LABELS = {
  ADD: getText('cart:addingTripDialog.add'),
  CANCEL: getText('cart:addingTripDialog.cancel'),
  TITLE: getText('cart:addingTripDialog.title'),
  DESCRIPTION: getText('cart:addingTripDialog.description'),
  SELECT_TRIP: getText('cart:addingTripDialog.selectTrip'),
};

interface AddInTripDialogProps {
  show: boolean,
  loading: boolean
  onClose(value: boolean): void,
  onSave(value: string): void,
}

const AddInTripDialog = ({ show, loading, onClose, onSave }: AddInTripDialogProps) => {
  const {
    data: tripItems = [],
    isLoading,
  } = useGetTripByStatusesQuery(TEST_BODY);

  const [
    selectedValue,
    setSelectedValue,
  ] = useState<string>('');

  const handleClose = () => onClose(false);

  const handleSelect = (value: string) => setSelectedValue(value);

  const handleAdd = () => onSave(selectedValue);

  const renderItems = (item: ItemType): ReactNode => (
    <div className={ styles.item }>
      {item.label}
    </div>
  );

  return (
    <Dialog
      show={ show }
      showClosing
      className={ styles.dialog }
      onChange={ () => onClose(false) }
    >
      <div className={ styles.wrapper }>
        <Text type='bold_20'>
          {LABELS.TITLE}
        </Text>
        <Text type='NORMAL_16_140' className={ styles.description }>
          {LABELS.DESCRIPTION}
        </Text>
        <div className={ styles.select }>
          <Select
            theme='border'
            loading={ isLoading }
            disabled={ isLoading }
            placeholder={ LABELS.SELECT_TRIP }
            value={ selectedValue }
            // @ts-ignore
            items={ tripItems }
            renderItem={ renderItems }
            onChange={ handleSelect }
          />
        </div>
        <div className={ styles.buttons }>
          <Button
            onClick={ handleAdd }
            disabled={ selectedValue === '' }
            loading={ loading }
            type='secondary'
            size='small'
          >
            {LABELS.ADD}
          </Button>
          <Button
            type='text'
            size='small'
            onClick={ handleClose }
          >
            {LABELS.CANCEL}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { AddInTripDialog };
