import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Text,
  Stars,
  NoPhoto,
  Rating,
  Icon,
} from 'yw-ui/src';
import clsx from 'clsx';

import { getText, getTextArray } from '@/i18n';

import { ImageItem } from '@/app/components/ImageGallery/components/ImageItem';

import toDecline from '../../../../bi/utils/toDecline';
import { formatCurrency } from '@/app/bi/utils/money.ts';
import { preloadOrigUrl } from '@/app/bi/utils/images.ts';
import { calculatePriceValue } from '@/app/bi/utils/price.ts';

import { HOTEL_TYPES } from '@/app/bi/constants/hotelsSearch.ts';

import { IRegionSearchResponseItem } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import recomend from '../../../../../../public/images/recomend.svg';

import styles from './style.module.scss';

const LABELS = {
  SPECIAL_OFFER: getText('hotels:regionResult.item.specialOffer'),
  DAYS_DECLINE: getTextArray('hotels:regionResult.item.daysDecline'),
  CHOOSE_ROOM: getText('hotels:regionResult.item.chooseRoom'),
  DISTANCE_TO_POINT: (distance: string) => getText('hotels:regionResult.item.distanceToPoint', { distance }),
  NOT_MEAL: getText('hotels:regionResult.item.noMeal'),
  WITH_BREAKFAST: getText('hotels:hotelResult.item.generalList.withBreakfast'),
};

interface IHotelMapItemProps {
  item: IRegionSearchResponseItem;
  diffDays: number,
  animationClass?: string;
  travellersCount: number;
  hotelUrl: string;
  roomCount: number;
  currencyCode: ECurrencyCode;
}

const HotelMapItem = ({
  item,
  diffDays,
  currencyCode,
  hotelUrl,
  animationClass = '',
  travellersCount,
  roomCount,
}: IHotelMapItemProps) => {
  const ref = useRef(null);

  const {
    name,
    rating,
    distanceToCenter,
    thumbnailUrl,
    rate,
    type,
  } = item;
  const wrapClasses = clsx([styles.wrap], animationClass);

  const [imageValid, setImageValid] = useState(false);

  const currencyPrice = rate.prices.find(({ currency }) => currency === currencyCode);

  const handlePreloadImg = useCallback(async () => {
    const resultImage = await preloadOrigUrl(thumbnailUrl);

    if (!!ref.current && !!resultImage) {
      setImageValid(!!resultImage);
    }
  }, [thumbnailUrl, ref]);

  useEffect(() => {
    handlePreloadImg();
  }, [thumbnailUrl, handlePreloadImg, ref]);

  const renderDaysAmount = () => {
    if (diffDays === 1 && roomCount < 1) return null;

    const textDays = toDecline(diffDays, LABELS.DAYS_DECLINE);

    return (
      <Text type='NORMAL_12' color='gray' className={ styles.days_amount }>
        {`/${diffDays} ${textDays}`}
      </Text>
    );
  };

  const renderImage = () => {
    const itemImage = { original: thumbnailUrl };

    return (
      <div className={ styles.img_wrapper }>
        <ImageItem item={ itemImage }/>
      </div>
    );
  };

  const renderNoPhoto = () => (
    <div className={ styles.no_photo }>
      <NoPhoto/>
    </div>
  );

  const imageContent = imageValid ? renderImage() : renderNoPhoto();

  const ratingContent = !!rating && (
    <div className={ styles.rating }>
      <Link to={ hotelUrl } target='_blank'>
        <Rating number={ rating } />
      </Link>
    </div>
  );

  const renderHeader = () => (
    <div className={ styles.header }>
      <div className={ styles.properties_container }>
        <img className={ styles.recomend_icon } src={ recomend } alt='recomendHotel' />
      </div>
      {ratingContent}
      <Link target='_blank' to={ hotelUrl }>
        {imageContent}
      </Link>
    </div>
  );

  const price = formatCurrency(calculatePriceValue(
    currencyPrice?.total as number,
    travellersCount,
    roomCount,
  ), currencyCode as ECurrencyCode);

  const renderActions = () => (
    <div className={ styles.actions }>
      <div className={ styles.price }>
        <div className={ styles.price_amount }>
          <Text type='bold_18' color='gray-7'>
            {price}
          </Text>
        </div>
        {renderDaysAmount()}
      </div>
      <Link
        to={ hotelUrl }
        target='_blank'
      >
        <Button type='secondary' size='small'>
          <Text type='NORMAL_14' color='white'>
            {LABELS.CHOOSE_ROOM}
          </Text>
        </Button>
      </Link>
    </div>
  );

  const renderContent = () => {
    const distance = LABELS.DISTANCE_TO_POINT(distanceToCenter.toFixed(2));

    return (
      <div className={ styles.content }>
        <div className={ styles.hotel_info }>
          <div className={ styles.properties }>
            <div className={ styles.stars }>
              <Stars count={ 5 } size={ 8 } color='blue1' />
              <Text type='NORMAL_10' color='gray-7'>{HOTEL_TYPES[type]}</Text>
            </div>
            <Text type='NORMAL_12' color='gray-6' className={ styles.distance }>
              { distance }
            </Text>
          </div>
          <Link
            to={ hotelUrl }
            onClick={ () => {} }
            target='_blank'
            className={ `${styles.name_wrapper}` }
          >
            <Text type='NORMAL_16' color='gray-7' className={ styles.title }>
              {name}
            </Text>
          </Link>
        </div>
        <div className={ styles.info }>
          {renderInfo()}
        </div>
        {renderActions()}
      </div>
    );
  };

  const renderMeal = () => {
    const text = rate.breakfast ? LABELS.WITH_BREAKFAST : LABELS.NOT_MEAL;
    const color = rate.breakfast ? 'blue-1' : 'red';

    return (
      <div className={ styles.room_info }>
        <Text type='NORMAL_12' color={ color }>{text}</Text>
        {rate.breakfast && <Icon type='cup' size={ 16 }/>}
      </div>
    );
  };

  const renderInfo = () => (
    <div className={ styles.room_info_wrapper }>
      <Text type='NORMAL_14'>{ rate.name }</Text>
      { renderMeal() }
    </div>
  );

  return (
    <div className={ wrapClasses } ref={ ref }>
      <div className={ styles.item }>
        { renderHeader() }
        { renderContent() }
      </div>
    </div>
  );
};

export { HotelMapItem };
