import { createSlice } from '@reduxjs/toolkit';
import { searchHotelsApi } from '@/app/bi/api/searchHotelsApi.ts';

import { IHotelSearchResponse } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

const initialState: { data: IHotelSearchResponse | null } = {
  data: null,
};

export const hotelSlice = createSlice({
  name: 'hotelSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      searchHotelsApi.endpoints.searchHotels.matchFulfilled,
      (state, { payload }) => {
        state.data = payload;
      },
    );
    builder.addMatcher(
      searchHotelsApi.endpoints.searchHotelsBySearchId.matchFulfilled,
      (state, { payload }) => {
        state.data = payload;
      },
    );
  },
});

export default hotelSlice.reducer;
