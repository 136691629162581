import React from 'react';
import { Select } from 'yw-ui';
import clsx from 'clsx';

import { SortArrowOption } from './components/SortArrowOption';

import styles from './index.module.scss';

interface SortSelectProps {
  items: { label: string, value: string, type: number }[],
  value: string
  onChange(value: number | string): void,
  className?: string;
}

const SortSelect = ({ items, value, onChange, className }: SortSelectProps) => (
  <Select
    theme='default-border'
    items={ items }
    value={ value }
    // @ts-ignore
    renderLabel={ (item) => <SortArrowOption item={ item } /> } // TODO разобраться с типами
    // @ts-ignore
    renderItem={ (item) => <SortArrowOption item={ item } /> }
    onChange={ onChange }
    className={ clsx(styles.select, className) }
  />
);

export { SortSelect };
