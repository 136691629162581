import React, { useRef, useState, useEffect, ReactNode } from 'react';
import { Slider, LinkButton, Text } from 'yw-ui';
import { getText } from '@/i18n';

import parseUnix from '../../bi/utils/parseDateTime';

import { PATTERN } from '@/app/bi/constants/dateFormats.ts';

import styles from './index.module.scss';

const STEP = 300;

const LABELS = {
  DAY_PARTS: {
    DAY: getText('components:dateTimeSlider.day'),
    NIGHT: getText('components:dateTimeSlider.night'),
    MORNING: getText('components:dateTimeSlider.morning'),
    EVENING: getText('components:dateTimeSlider.evening'),
  },
  FROM_TO: (from: string, to: string) => getText('components:dateTimeSlider.fromTo', { from, to }),
};

const DAY_PARTS = {
  NIGHT: {
    start: 0,
    by: 25200,
  },
  MORNING: {
    start: 25200,
    by: 14400,
  },
  DAY: {
    start: 39600,
    by: 18000,
  },
  EVENING: {
    start: 57600,
    by: 28799,
  },
};

const prepareDatePart = (start: number, date: number) => parseUnix(date)
  .set('seconds', start % 60)
  .set('minutes', Math.floor(start / 60) % 60)
  .set('hours', Math.floor(start / 3600) % 24)
  .unix();

interface DateTimeSliderProps {
  label: ReactNode,
  min: number,
  max: number,
  from: number,
  to: number,
  onChange(array: number[]): void,
}

const DateTimeSlider = ({
  label,
  min,
  max,
  from,
  to,
  onChange,
}: DateTimeSliderProps) => {
  const {
    DAY,
    NIGHT,
    EVENING,
    MORNING,
  } = LABELS.DAY_PARTS;

  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [localFrom, setLocalFrom] = useState(from);
  const [localTo, setLocalTo] = useState(to);

  useEffect(() => {
    setLocalFrom(from);
    setLocalTo(to);
  }, [from, to]);

  const handleSlide = ({ min: cMin, max: cMax }: { min: number; max: number }) => {
    setLocalFrom(cMin);
    setLocalTo(cMax);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => onChange([Math.max(cMin, min), Math.min(cMax, max)]), 300);
  };

  const handleChangeDayPart = ({ start, by }: { start: number, by: number }) => {
    const leftTime = start;
    const rightTime = start + by;

    onChange([prepareDatePart(leftTime, from), prepareDatePart(rightTime, to)]);
  };

  const tripDaysIsSame = (parseUnix(min).day() === parseUnix(max).day());

  const timeFrom = parseUnix(localFrom);
  const timeTo = parseUnix(localTo);

  const getTimeHtml = (format: string) => (
    <Text
      type='NORMAL_12'
      color='blue-1'
      className={ styles.date }
    >
      { LABELS.FROM_TO(timeFrom.format(format), timeTo.format(format)) }
    </Text>
  );

  const dayTimeHtml = tripDaysIsSame && getTimeHtml(PATTERN.LOCAL_TIME_PATTERN);

  const dayPartsHtml = (
    <div className={ styles.dayparts }>
      <LinkButton
        theme='small-default'
        color='blue-1'
        onClick={ () => handleChangeDayPart(DAY_PARTS.NIGHT) }
      >
        { NIGHT }
      </LinkButton>
      <LinkButton
        theme='small-default'
        color='blue-1'
        onClick={ () => handleChangeDayPart(DAY_PARTS.MORNING) }
      >
        { MORNING }
      </LinkButton>
      <LinkButton
        theme='small-default'
        color='blue-1'
        onClick={ () => handleChangeDayPart(DAY_PARTS.DAY) }
      >
        { DAY }
      </LinkButton>
      <LinkButton
        theme='small-default'
        color='blue-1'
        onClick={ () => handleChangeDayPart(DAY_PARTS.EVENING) }
      >
        { EVENING }
      </LinkButton>
    </div>
  );

  const sliderSubContent = tripDaysIsSame ? dayPartsHtml : getTimeHtml(PATTERN.DAY_OF_MONTH_TIME);

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.description }>
        <Text type='NORMAL_12'>
          { label }
        </Text>
        { dayTimeHtml }
      </div>
      <div className={ styles.slider_wrap }>
        <Slider
          className={ styles.slider }
          min={ min }
          max={ max }
          value={ { min: localFrom, max: localTo } }
          step={ STEP }
          onChange={ handleSlide }
          debounceMs={ 0 }
        />
        { sliderSubContent }
      </div>
    </div>
  );
};

export { DateTimeSlider };
