import React, { useRef } from 'react';

import { useAppSelector } from '@/app/store/hooks/redux.ts';

import { HotelResult } from '../HotelsResult';
import { HotelsSearchFilter } from '@/app/pages/HotelsResultPage/components/Filters';
import { Header } from '@/app/pages/HotelsResultPage/components/HotelResultWrap/components/Header';

import { ITag } from '@/app/bi/models/shared.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { IHotelSearchFilter } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './styles.module.scss';

interface IHotelResultWrapProps {
  listTravelPolicies: ITravelPolicy[];
  selectPolicy: { id: string, policyName: string, isActive: boolean };
  setIsSelectPolicy(value: { id: string, policyName: string, isActive: boolean }): void;
  onUpdateFilters(valueFilter: IHotelSearchFilter): void;
  onResetFilters(): void;
  onChangePage(page: number): void;
  onDeleteTag(tag: ITag): void;
}

const HotelResultWrap = ({
  listTravelPolicies,
  selectPolicy,
  setIsSelectPolicy,
  onUpdateFilters,
  onResetFilters,
  onDeleteTag,
  onChangePage,
}: IHotelResultWrapProps) => {
  const pageHeaderRef = useRef(null);

  const {
    items,
    filters,
  } = useAppSelector((state) => state.hotelsSlice);

  const renderFilter = () => {
    if (!filters) return null;

    return (
      <HotelsSearchFilter
        listTravelPolicies={ listTravelPolicies }
        selectPolicy={ selectPolicy }
        setIsSelectPolicy={ setIsSelectPolicy }
        onUpdateFilters={ onUpdateFilters }
        onResetFilters={ onResetFilters }
      />
    );
  };

  const renderHeader = () => {
    if (!items.length && !filters) return null;

    return (
      <Header
        // onUpdateFilters={ onUpdateFilters }
        pageHeaderRef={ pageHeaderRef }
        onResetFilters={ onResetFilters }
        onDeleteTag={ onDeleteTag }
      />
    );
  };

  return (
    <div className={ styles.result }>
      {renderFilter()}
      <div className={ styles.items }>
        {renderHeader()}
        <div className={ styles.list }>
          <HotelResult
            listTravelPolicies={ listTravelPolicies }
            selectPolicy={ selectPolicy }
            onChangePage={ onChangePage }
            pageHeaderRef={ pageHeaderRef }
          />
        </div>
      </div>
    </div>
  );
};

export { HotelResultWrap };
