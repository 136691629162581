import React from 'react';
import { Map as GoogleMap } from '@vis.gl/react-google-maps';

import { useMarkerRef } from './hook';

import { Marker } from '../Marker';

import { DEFAULT_ZOOM } from './constant';
import CONFIG from '@/config.ts';

import { IMapProps } from './type';

const Map = ({ center, points, selectedHotelId, updateSelectHotel }: IMapProps) => {
  const setMarkerRef = useMarkerRef();

  return (
    <GoogleMap
      defaultCenter={ center }
      defaultZoom={ DEFAULT_ZOOM }
      mapId={ CONFIG.GOOGLE_MAPS.MAP_ID }
    >
      {points.map((point) => (
        <Marker
          key={ point.hotelId }
          point={ point }
          selectedHotelId={ selectedHotelId }
          updateSelectHotel={ updateSelectHotel }
          setMarkerRef={ setMarkerRef }
        />
      ))}
    </GoogleMap>
  );
};

export { Map };
