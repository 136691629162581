import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { LinkButton, Text } from 'yw-ui';

import { useAppSelector } from '@/app/store/hooks/redux.ts';

import MoneyFormat from '@/app/bi/utils/money.ts';

import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy';

const createLabels = (t: TFunction) => ({
  AVAILABLE: t('components:header.balance.available'),
});

const Balance = () => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const {
    headers: {
      balance: {
        available,
        currency,
      },
    },
  } = useAppSelector((state) => state.appSlice);

  const availableHtml = `${LABELS.AVAILABLE} ${MoneyFormat.moneyWithDecimal(available)} ${CURRENCY_SYMBOLS[currency]}`;

  return (
    <LinkButton>
      <Text type='NORMAL_14'>
        {availableHtml}
      </Text>
    </LinkButton>
  );

};

export { Balance };
