import React from 'react';
import { Button } from 'yw-ui';

import { getText } from '@/i18n';

import { FilterPanel } from '@/app/components/FilterPanel';
import { FilterServicesItem } from '@/app/pages/Trips/components/TripsFilters/components/FilterServicesItem';
import { FilterCreateOnlyMeItem } from '@/app/pages/Trips/components/TripsFilters/components/FilterCreateOnlyMeItem';
import { FilterStatusItem } from '@/app/pages/Trips/components/TripsFilters/components/FilterStatusItem';
import { FilterTravelPeriodItem } from '@/app/pages/Trips/components/TripsFilters/components/FilterTravelPeriodItem';
import { FilterEmployeeItem } from '@/app/pages/Trips/components/TripsFilters/components/FilterEmployeeItem';
import { FilterCitiesItem } from '@/app/pages/Trips/components/TripsFilters/components/FilterCitiesItem';

import { getDateRange } from '@/app/bi/utils/trip.ts';

import {
  DEFAULT_DATE_STATE,
  SERVICES_NAME_ARRAY,
  STATUS_NAME_ARRAY,
} from '@/app/bi/constants/trips.ts';

import {
  ETripStatusFilter,
  ITripDates,
  ITripsFilter,
} from '@/app/bi/models/tripsTypes.ts';
import { IEmployee } from '@/app/bi/models/cart.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

import styles from './index.module.scss';

const LABELS = {
  NAME_OF_THE_CITY: getText('trips:nameOfTheCity'),
  CITIES: getText('trips:cities'),
  EMPLOYEES: getText('trips:employees'),
  FULL_NAME_WORKER: getText('trips:fullNameWorker'),
  MINE_CHECKBOX: getText('trips:mineCheckbox'),
  SERVICES: getText('trips:services'),
  STATUS: getText('trips:status'),
  TRAVEL_PERIOD: getText('trips:travelPeriod'),
  RESET_FILTERS: getText('trips:resetFilters'),
};

interface TripsFiltersProps {
  filtersValues: ITripsFilter;
  employeeAutocomplete: IEmployee[];
  citiesAutocomplete: string[];
  inputValueEmployee: string;
  inputValueCities: string;
  setFilters(value: ITripsFilter): void;
  setInputEmployee(value: string): void;
  setInputCities(value: string): void;
  onClearFilter(): void;
  setIsChangeDate(value: boolean): void
}

const TripsFilters = ({
  filtersValues,
  employeeAutocomplete,
  citiesAutocomplete,
  inputValueEmployee,
  inputValueCities,
  setFilters,
  setInputEmployee,
  setInputCities,
  onClearFilter,
  setIsChangeDate,
}: TripsFiltersProps) => {
  // const [
  //   anyDate,
  //   setAnyDate,
  // ] = useState<boolean>(true);
  const formingTravelPeriodDate = filtersValues.tripDates || DEFAULT_DATE_STATE;

  const handleInputCitiesChange = (value: string) => setInputCities(value);

  const handleInputEmployeeChange = (value: string) => setInputEmployee(value);

  const handleChangeFilter = (valueFilter: ITripsFilter) => {
    setFilters({ ...valueFilter, page: 1 });
  };

  const handleChangeCreatedOnlyByMe = (value: boolean) => {
    handleChangeFilter({ ...filtersValues, createdOnlyByMe: !value });
  };

  const handleChangeTravelPeriod = (value: ITripDates) => {
    setIsChangeDate(true);
    // setAnyDate(false);

    const newValue = { ...value };

    if (newValue.min > newValue.max) {
      newValue.max = newValue.min;
    }

    const reformatDate = getDateRange({ min: newValue.min, max: newValue.max });

    handleChangeFilter({ ...filtersValues, tripDates: reformatDate });
  };

  // const handlerAnyDate = () => {
  //   setAnyDate(true);

  //   handleChangeFilter({ ...filtersValues, tripDates: DEFAULT_DATE_STATE });
  // };

  const handleChangeCities = (value: string[]) => {
    handleChangeFilter({ ...filtersValues, cities: value });
  };

  const handleChangeEmployee = (value: IEmployee) => {
    const employeeIndex = filtersValues.employees.findIndex((
      employee,
    ) => employee.id === value.id);
    let newEmployees;

    if (employeeIndex !== -1) {
      newEmployees = filtersValues.employees.filter((_, index) => index !== employeeIndex);
    } else {
      newEmployees = [...filtersValues.employees, value];
    }

    handleChangeFilter({ ...filtersValues, employees: newEmployees });
  };

  const handleChangeServices = (value: EServiceTypes[]) => {
    handleChangeFilter({ ...filtersValues, serviceTypes: value });
  };

  const handleChangeStatus = (value: ETripStatusFilter[]) => {
    handleChangeFilter({ ...filtersValues, tripStatuses: value });
  };

  const handleResetFilterDate = () => {
    // setAnyDate(true);

    handleChangeFilter({ ...filtersValues, tripDates: DEFAULT_DATE_STATE });
  };

  return (
    <div className={ styles.wrap }>
      <FilterPanel>
        <FilterCitiesItem
          title={ LABELS.CITIES }
          data={ citiesAutocomplete }
          citiesValues={ filtersValues.cities }
          inputValue={ inputValueCities }
          placeholder={ LABELS.NAME_OF_THE_CITY }
          onChange={ handleChangeCities }
          onChangeInput={ handleInputCitiesChange }
        />
        <FilterEmployeeItem
          title={ LABELS.EMPLOYEES }
          employeeAutocomplete={ employeeAutocomplete }
          employeesValues={ filtersValues.employees }
          inputValue={ inputValueEmployee }
          placeholder={ LABELS.FULL_NAME_WORKER }
          onChange={ handleChangeEmployee }
          onChangeInput={ handleInputEmployeeChange }
        />
        <FilterCreateOnlyMeItem
          title={ LABELS.MINE_CHECKBOX }
          value={ filtersValues.createdOnlyByMe }
          onChange={ handleChangeCreatedOnlyByMe }
        />
        <FilterServicesItem
          title={ LABELS.SERVICES }
          data={ Object.keys(SERVICES_NAME_ARRAY) as EServiceTypes[] }
          serviceValues={ filtersValues.serviceTypes }
          onChange={ handleChangeServices }
        />
        <FilterStatusItem
          title={ LABELS.STATUS }
          data={ Object.keys(STATUS_NAME_ARRAY) as ETripStatusFilter[] }
          tripStatusesValues={ filtersValues.tripStatuses }
          onChange={ handleChangeStatus }
        />
        <FilterTravelPeriodItem
          title={ LABELS.TRAVEL_PERIOD }
          tripDatesValues={ formingTravelPeriodDate }
          onChangeDate={ handleChangeTravelPeriod }
          onResetFilter={ handleResetFilterDate }
        />
        <div className={ styles.button_more_container }>
          <Button onClick={ onClearFilter } type='text' className={ styles.button_more }>
            {LABELS.RESET_FILTERS}
          </Button>
        </div>
      </FilterPanel>
    </div>
  );
};

export { TripsFilters };
