import { getText } from '@/i18n';

import { IPaging } from '@/app/bi/models/shared.ts';
import { EHotelTypes } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

const CHECKIN = 'checkin';
const CHECKOUT = 'checkout';

const HOTEL_TYPES: { [key in EHotelTypes]: string } = {
  [EHotelTypes.Undefined]: getText('constants:hotelSearch.types.undefined'),
  [EHotelTypes.Property]: getText('constants:hotelSearch.types.property'),
  [EHotelTypes.Hotel]: getText('constants:hotelSearch.types.hotel'),
  [EHotelTypes.Motel]: getText('constants:hotelSearch.types.motel'),
  [EHotelTypes.Resort]: getText('constants:hotelSearch.types.resort'),
  [EHotelTypes.Inn]: getText('constants:hotelSearch.types.inn'),
  [EHotelTypes.BedAndBreakfast]: getText('constants:hotelSearch.types.bedAndBreakfast'),
  [EHotelTypes.Guesthouse]: getText('constants:hotelSearch.types.guesthouse'),
  [EHotelTypes.Condo]: getText('constants:hotelSearch.types.condo'),
  [EHotelTypes.AllInclusiveProperty]: getText('constants:hotelSearch.types.allInclusiveProperty'),
  [EHotelTypes.Cabin]: getText('constants:hotelSearch.types.cabin'),
  [EHotelTypes.Chalet]: getText('constants:hotelSearch.types.chalet'),
  [EHotelTypes.Cottage]: getText('constants:hotelSearch.types.cottage'),
  [EHotelTypes.HostelOrBackpackerAccommodation]: getText('constants:hotelSearch.types.hostelOrBackpackerAccommodation'),
  [EHotelTypes.Ranch]: getText('constants:hotelSearch.types.ranch'),
  [EHotelTypes.Villa]: getText('constants:hotelSearch.types.villa'),
  [EHotelTypes.Lodge]: getText('constants:hotelSearch.types.lodge'),
  [EHotelTypes.Apartment]: getText('constants:hotelSearch.types.apartment'),
  [EHotelTypes.PrivateVacationHome]: getText('constants:hotelSearch.types.privateVacationHome'),
  [EHotelTypes.Houseboat]: getText('constants:hotelSearch.types.houseboat'),
  [EHotelTypes.Ryokan]: getText('constants:hotelSearch.types.ryokan'),
  [EHotelTypes.TreeHouseProperty]: getText('constants:hotelSearch.types.treeHouseProperty'),
  [EHotelTypes.Aparthotel]: getText('constants:hotelSearch.types.aparthotel'),
  [EHotelTypes.CondominiumResort]: getText('constants:hotelSearch.types.condominiumResort'),
  [EHotelTypes.Campsite]: getText('constants:hotelSearch.types.campsite'),
  [EHotelTypes.Riad]: getText('constants:hotelSearch.types.riad'),
  [EHotelTypes.Hostal]: getText('constants:hotelSearch.types.hostal'),
  [EHotelTypes.CountryHouse]: getText('constants:hotelSearch.types.countryHouse'),
  [EHotelTypes.Pension]: getText('constants:hotelSearch.types.pension'),
  [EHotelTypes.PousadaPortugal]: getText('constants:hotelSearch.types.pousadaPortugal'),
  [EHotelTypes.PousadaBrazil]: getText('constants:hotelSearch.types.pousadaBrazil'),
  [EHotelTypes.Residence]: getText('constants:hotelSearch.types.residence'),
  [EHotelTypes.Affittacamere]: getText('constants:hotelSearch.types.affittacamere'),
  [EHotelTypes.Castle]: getText('constants:hotelSearch.types.castle'),
  [EHotelTypes.SafariOrTentalow]: getText('constants:hotelSearch.types.safariOrTentalow'),
  [EHotelTypes.Palace]: getText('constants:hotelSearch.types.palace'),
  [EHotelTypes.AgritourismProperty]: getText('constants:hotelSearch.types.agritourismProperty'),
  [EHotelTypes.Cruise]: getText('constants:hotelSearch.types.cruise'),
  [EHotelTypes.HolidayPark]: getText('constants:hotelSearch.types.holidayPark'),
  [EHotelTypes.CapsuleHotel]: getText('constants:hotelSearch.types.capsuleHotel'),
  [EHotelTypes.MobileHome]: getText('constants:hotelSearch.types.mobileHome'),
};

interface TravellerItem {
  label: string;
  value: number;
  roomCount: number;
}

const SELECT_TRAVELLERS: { [key: number]: TravellerItem[] } = {
  1: [
    { label: 'default', value: 1, roomCount: 1 },
  ],
  2: [
    { label: getText('constants:hotelSearch.selectTravellers.2.one'), value: 1, roomCount: 2 },
    { label: getText('constants:hotelSearch.selectTravellers.2.two'), value: 2, roomCount: 1 },
  ],
  3: [
    { label: getText('constants:hotelSearch.selectTravellers.3.one'), value: 1, roomCount: 3 },
    { label: getText('constants:hotelSearch.selectTravellers.3.two'), value: 2, roomCount: 2 },
  ],
  4: [
    { label: getText('constants:hotelSearch.selectTravellers.4.one'), value: 1, roomCount: 4 },
    { label: getText('constants:hotelSearch.selectTravellers.4.two'), value: 2, roomCount: 2 },
  ],
  5: [
    { label: getText('constants:hotelSearch.selectTravellers.5.one'), value: 1, roomCount: 5 },
    { label: getText('constants:hotelSearch.selectTravellers.5.two'), value: 2, roomCount: 3 },
  ],
  6: [
    { label: getText('constants:hotelSearch.selectTravellers.6.one'), value: 1, roomCount: 6 },
    { label: getText('constants:hotelSearch.selectTravellers.6.two'), value: 2, roomCount: 3 },
  ],
};

const DEFAULT_TIME_VALUE = 'default';

const CUSTOM_CHECKIN_VALUES = [
  { label: getText('components:menu.hotel.withoutEarlyInOrLateOutOption'), value: DEFAULT_TIME_VALUE },
  { label: '00:00', value: 0 },
  { label: '01:00', value: 1 },
  { label: '02:00', value: 2 },
  { label: '03:00', value: 3 },
  { label: '04:00', value: 4 },
  { label: '05:00', value: 5 },
  { label: '06:00', value: 6 },
  { label: '07:00', value: 7 },
  { label: '08:00', value: 8 },
  { label: '09:00', value: 9 },
  { label: '10:00', value: 10 },
  { label: '11:00', value: 11 },
  { label: '12:00', value: 12 },
  { label: '13:00', value: 13 },
];

const CUSTOM_CHECKOUT_VALUES = [
  { label: getText('components:menu.hotel.withoutEarlyInOrLateOutOption'), value: DEFAULT_TIME_VALUE },
  { label: '13:00', value: 13 },
  { label: '14:00', value: 14 },
  { label: '15:00', value: 15 },
  { label: '16:00', value: 16 },
  { label: '17:00', value: 17 },
  { label: '18:00', value: 18 },
  { label: '19:00', value: 19 },
  { label: '20:00', value: 20 },
  { label: '21:00', value: 21 },
  { label: '22:00', value: 22 },
  { label: '23:00', value: 23 },
];

const THROTTLE_DELAY_AUTOCOMPLETE = 200;

const DEFAULT_PAGING_OBJ: IPaging = {
  total: 0,
  current: 1,
  count: 15,
};

export {
  SELECT_TRAVELLERS,
  CHECKOUT,
  CHECKIN,
  THROTTLE_DELAY_AUTOCOMPLETE,
  CUSTOM_CHECKIN_VALUES,
  CUSTOM_CHECKOUT_VALUES,
  DEFAULT_TIME_VALUE,
  DEFAULT_PAGING_OBJ,
  HOTEL_TYPES,
};
