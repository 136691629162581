import React from 'react';
import { Icon, Text } from 'yw-ui';

import { getText } from '@/i18n';

import styles from './index.module.scss';

const LABELS = {
  WITH_BREAKFAST: getText('hotels:hotelResult.item.generalList.withBreakfast'),
  WITHOUT_BREAKFAST: getText('hotels:hotelResult.item.generalList.withoutBreakfast'),
  WITH_BREAKFAST_NAME: getText('hotels:hotelResult.item.generalList.withBreakfastName'),
  WITHOUT_BREAKFAST_NAME: getText('hotels:hotelResult.item.generalList.withoutBreakfastName'),
  NO_MONEY_BACK: getText('hotels:hotelResult.item.generalList.noMoneyBack'),
  FREE_CANCELLATION: (date: string) => getText('hotels:hotelResult.item.generalList.freeCancellation', { date }),
  // FREE_CANCELLATION_FOREIGN: (date: string) => getText('hotels:hotelResult.item.generalList.freeCancellationForeign', { date }),
  ATTENTION_FREE_CANCELLATION: getText('hotels:hotelResult.item.generalList.attentionFreeCancellation'),
};

interface GeneralInfoListProps {
  refundable: boolean,
  meal: { included: boolean, name: string },
  date: string,
}

const GeneralInfoList = ({
  refundable, meal, date,
}: GeneralInfoListProps) => {
  const { included, name } = meal;

  const mealBreakfast = name ? `${LABELS.WITH_BREAKFAST_NAME}: ${name}` : LABELS.WITH_BREAKFAST;
  const noMealBreakfast = name ? LABELS.WITHOUT_BREAKFAST_NAME : LABELS.WITHOUT_BREAKFAST;

  // TODO переделать
  // const cancellationByCountry = countryCode !== '' ? (
  //   <div className={ styles.active }>
  //     <Text
  //       type='NORMAL_16_140'
  //     >
  //       { LABELS.FREE_CANCELLATION_FOREIGN(date) }
  //       <Tooltip
  //         className={ styles.icon }
  //         bodyClassName={ styles.tooltip }
  //         renderContent={ () => (
  //           <Text type='NORMAL_14_130' className={ styles.tooltip_content } color='white'>
  //             { LABELS.ATTENTION_FREE_CANCELLATION }
  //           </Text>
  //         ) }
  //       >
  //         <Icon type='search' />
  //       </Tooltip>
  //     </Text>
  //   </div>
  // ) : (
  //   <Text
  //     type='NORMAL_16_140'
  //   >
  //     { LABELS.FREE_CANCELLATION(date) }
  //   </Text>
  // );

  const mealContent = included ? (
    <div className={ styles.active }>
      <Text
        type='NORMAL_14'
        color='light-blue-4'
        className={ `${styles.list_item} ${styles.list_item_blue}` }
      >
        { mealBreakfast }
      </Text>
      <Icon type='cup' color='LB4' size={ 20 } className={ styles.icon } />
    </div>
  ) : (
    <Text type='NORMAL_14' color='gray-7' className={ styles.list_item }>
      { noMealBreakfast }
    </Text>
  );

  const cancellationContent = refundable
    ? (
      <Text type='NORMAL_14' className={ styles.list_item }>
        { LABELS.FREE_CANCELLATION(date) }
      </Text>
    )
    : (
      <div className={ styles.active }>
        <Text type='NORMAL_14' color='gray-7' className={ styles.list_item }>
          { LABELS.NO_MONEY_BACK }
        </Text>
      </div>
    );

  return (
    <div className={ styles.wrapper }>
      { cancellationContent }
      { mealContent }
    </div>
  );
};

export { GeneralInfoList };
