import React, { forwardRef, ReactNode, useState } from 'react';
import clsx from 'clsx';
import { Icon } from 'yw-ui';

import { IconType } from 'yw-ui/components/Icon/types';

import styles from './index.module.scss';

interface ItemPanelCollapseProps {
  opened?: boolean,
  renderHeader(): ReactNode,
  renderActions?(): ReactNode,
  headerClass?: string,
  children?: ReactNode;
}

const ItemPanelCollapse = forwardRef<HTMLDivElement, ItemPanelCollapseProps>(({
  opened = true,
  renderHeader,
  renderActions = () => null,
  headerClass = '',
  children = null,
}, ref) => {
  const [open, setOpen] = useState<boolean>(opened);

  const headerClasses = clsx([styles.header], {
    [headerClass]: headerClass,
  });

  const renderIcon = () => {
    const iconType: IconType = open ? 'up' : 'down';

    return <Icon className={ styles.icon } color='gray7' type={ iconType } />;
  };

  const showInfo = () => {
    setOpen(!open);
  };

  const handleShowInfo = () => showInfo();

  const renderContent = () => {
    if (!open) {
      return null;
    }

    return (
      <div className={ styles.content }>
        {children}
      </div>
    );
  };

  return (
    <div className={ styles.wrapper } ref={ ref }>
      <div className={ headerClasses }>
        <div className={ styles.content_header }>
          <div className={ styles.main } onClick={ handleShowInfo }>
            <div className={ styles.test }>
              {renderHeader()}
            </div>
            {renderIcon()}
          </div>
          {renderActions()}
        </div>
      </div>
      {renderContent()}
    </div>

  );
});

export { ItemPanelCollapse };
export type { ItemPanelCollapseProps };
