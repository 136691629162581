import React, { ReactNode } from 'react';
import { StyledWrapper, Text } from 'yw-ui';

import { MainHeaderLogo } from '@/app/components/Header/components/MainHeaderLogo';

import logoCompany from '../../../svg/logo/yolwise.svg';

import styles from './index.module.scss';

const NAME_LOGO = 'Yolwise';

interface LoginDialogProps {
  title: string,
  children: ReactNode,
}
const LoginDialogWrapper = ({
  title,
  children,
}: LoginDialogProps) => (
  <div className={ styles.wrap }>
    <StyledWrapper className={ styles.wrapper }>
      <div className={ styles.logo }>
        <img src={ logoCompany } alt={ NAME_LOGO } title={ NAME_LOGO }/>
      </div>
      <Text className={ styles.label } type='bold_20'>
        {title}
      </Text>
      {children}
    </StyledWrapper>
    <footer className={ styles.footer }>
      <div className={ styles.footer_container }>
        <div className={ styles.item }>
          <MainHeaderLogo
            name={ NAME_LOGO }
            img={ logoCompany }
          />
          <Text type='SEMIBOLD_14'>
            YOLWISE YAZILIM BİLİŞİM TURİZM ACENTESİ TİCARET VE SANAYİ LİMİTED ŞİRKETİ
          </Text>
        </div>
        <div className={ styles.item }>
          <Text type='SEMIBOLD_14'>
            E-posta
          </Text>
          <a href='mailto:support@yolwise.com.tr'>
            support@yolwise.com.tr
          </a>
        </div>
        <div className={ styles.item }>
          <Text type='SEMIBOLD_14'>
            Telefon numarası
          </Text>
          <a href='tel:+902166062070'>+902166062070</a>
        </div>
        <div className={ styles.item }>
          <Text type='SEMIBOLD_14'>
            Adres
          </Text>
          <address>
            BARBAROS MAH. ŞEBBOY SOKAK HALKBANK NO:4/1 İÇ KAPI NO: 84 ATAŞEHİR İSTANBUL
          </address>
        </div>
      </div>
    </footer>
  </div>
);

export { LoginDialogWrapper };
