import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCartErrors } from '@/app/bi/utils/cart.ts';
import { getShowPrice } from '@/app/store/utils/cart.ts';

import { ICartErrors, ICartWorkPlace, ICartItemGroupByTrips } from '@/app/bi/models/cart.ts';
import {
  BOOKING_STEP_VALUE,
  EBookProgressStatus,
  IBookingProgress, IBookResult, ICheckoutData,
  EProgressSpeed,
} from '@/app/bi/models/booking.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum';

import { checkoutApi } from '@/app/bi/api/checkoutApi.ts';
import { prepareTripItemsByTripId } from '@/app/bi/utils/checkout.ts';

interface CheckoutState {
  airlinesPrice: number;
  hotelsPrice: number;
  totalPrice: number;
  currency: ECurrencyCode | null;
  progressStatus: EBookProgressStatus;
  progressValue: number;
  progressSpeed: EProgressSpeed;
  showErrorDialog: boolean;
  showGeneralErrorDialog: boolean,
  showProgressBar: boolean;
  bookId: string | null;
  checkoutData: ICheckoutData;
  bookResult: IBookResult;
  bookingErrors: ICartErrors;
  itemsGroupByTrips: ICartItemGroupByTrips[],
  showAirlinesPrice: boolean,
  showHotelsPrice: boolean,
}

const initialState: CheckoutState = {
  airlinesPrice: 0,
  hotelsPrice: 0,
  totalPrice: 0,
  currency: ECurrencyCode.USD,
  progressStatus: EBookProgressStatus.CartValidation,
  progressValue: 0,
  progressSpeed: EProgressSpeed.Slow,
  showErrorDialog: false,
  showGeneralErrorDialog: false,
  showProgressBar: false,
  bookId: null,
  checkoutData: {
    canBook: true,
    errors: [],
  },
  bookResult: {
    hasErrors: false,
    errors: [],
    cart: {} as ICartWorkPlace,
  },
  bookingErrors: {
    hasErrors: false,
    bookingErrors: [],
    cartItemErrors: [],
  },
  itemsGroupByTrips: [],
  showAirlinesPrice: false,
  showHotelsPrice: false,
};

const getProgress = (status: EBookProgressStatus, prevValue: number) => {
  const rawProgressValue = BOOKING_STEP_VALUE[status];
  const progressValue = rawProgressValue < prevValue ? prevValue : rawProgressValue;
  const progressSpeed = progressValue <= BOOKING_STEP_VALUE.Reservation
    ? EProgressSpeed.Slow
    : EProgressSpeed.Medium;

  return {
    progressValue,
    progressSpeed,
  };
};

export const checkoutSlice = createSlice({
  name: 'checkoutSlice',
  initialState,
  reducers: {
    updateBookingProgress(state, { payload }: PayloadAction<IBookingProgress>) {
      const { bookId, bookResult, progressStatus } = payload;

      const {
        progressValue,
        progressSpeed,
      } = getProgress(progressStatus, state.progressValue);

      state.progressValue = progressValue;
      state.progressSpeed = progressSpeed;

      state.progressStatus = progressStatus;
      state.bookId = bookId;

      if (progressStatus === EBookProgressStatus.GeneralError) {
        state.showGeneralErrorDialog = true;
        state.showProgressBar = false;

        return;
      }

      if (progressStatus === EBookProgressStatus.Failed) {
        state.bookResult = bookResult;
        state.showErrorDialog = true;
        state.showProgressBar = false;
        // @ts-ignore
        state.bookingErrors = getCartErrors(bookResult) as ICartErrors;

        return;
      }

      if (progressStatus === EBookProgressStatus.Booked) {
        state.showProgressBar = false;
      }
    },
    setShowErrorDialog(state, { payload }: PayloadAction<boolean>) {
      state.showErrorDialog = payload;
    },
    setShowProgressBar(state, { payload }: PayloadAction<boolean>) {
      state.showProgressBar = payload;
    },
    setProgressValue(state, { payload }: PayloadAction<number>) {
      state.progressValue = payload;
    },
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      checkoutApi.endpoints.getCheckoutCart.matchFulfilled,
      (state, { payload }) => {
        // state.checkoutData = checkBookingAvailability(payload);

        const {
          airlinesPrice,
          hotelsPrice,
          totalPrice,
          items,
        } = prepareTripItemsByTripId(payload);
        state.airlinesPrice = airlinesPrice;
        state.hotelsPrice = hotelsPrice;
        state.totalPrice = totalPrice;
        state.currency = payload.currency;
        state.itemsGroupByTrips = items;

        const { showHotelsPrice, showAirlinesPrice } = getShowPrice(
          items.flatMap((item) => item.items),
        );

        state.showHotelsPrice = showHotelsPrice;
        state.showAirlinesPrice = showAirlinesPrice;
      },
    );
    // builder.addMatcher(
    //   bookingApi.endpoints.getBookingAccessStatus.matchFulfilled,
    //   (state, { payload }) => {
    //     // state.checkoutData = checkBookingAvailability(payload);
    //   },
    // );
  },
});

export default checkoutSlice.reducer;
