import React, { useMemo } from 'react';
import { Text } from 'yw-ui';
import MoneyFormat from 'yw-ui/src/utils/money.ts';
import { useTranslation } from 'react-i18next';

import type { TFunction } from 'i18next';

import toDecline from '@/app/bi/utils/toDecline.ts';
import { getPriceByCurrency } from '../../utils';

import { TICKETS } from '@/app/bi/constants/airline.ts';
import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum';

import { Fares } from '@/app/bi/models/airlineTypes.ts';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  BY: t('components:airlineSearchItem.travellersAmount.by'),
});

interface TravellersAmountProps {
  travellers?: number;
  currentFareId?: string;
  fares?: Fares[];
  currencyCode: ECurrencyCode;
}

const TravellersAmount = ({
  currencyCode,
  travellers = 0,
  currentFareId = '',
  fares = [],
}: TravellersAmountProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const fare = fares.find(({ id }) => currentFareId === id);

  if (!fare) {
    return null;
  }

  const price = getPriceByCurrency(fare, currencyCode);

  if (!price) {
    return null;
  }

  const pricePerPerson = price.totalPrice / travellers;

  const priceFormat = `${MoneyFormat.moneyWithDecimal(Number(pricePerPerson))} ${CURRENCY_SYMBOLS[currencyCode]}`;

  return (
    <div className={ styles.wrap_price }>
      <Text
        type='NORMAL_14'
        color='gray-7'
        className={ styles.text }
      >
        {`${travellers} ${toDecline(travellers, TICKETS)} ${LABELS.BY} ${priceFormat}`}
      </Text>
    </div>
  );
};

export { TravellersAmount };
