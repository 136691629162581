import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Input, Slider, Text } from 'yw-ui';

import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import MoneyFormat from '../../bi/utils/money';

import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy.ts';

import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './index.module.scss';

const INPUT_DEBOUNCE = 0;

const VALUES_TYPES = {
  MIN: 'MIN',
  MAX: 'MAX',
};

const DEFAULT_STEP = 1;

const createLabels = (t: TFunction) => ({
  FROM: t('components:timeSlider.from'),
  TO: t('components:timeSlider.to'),
});

interface PriceSliderProps {
  min: number,
  max: number,
  start: number,
  end: number,
  step?: number,
  onSlide(array: number[], number?: number): void,
  currencyCode: ECurrencyCode,
}

const PriceSlider = ({
  min,
  max,
  start,
  end,
  step = DEFAULT_STEP,
  onSlide,
  currencyCode,
}: PriceSliderProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const prevProps = useRef({ start, end });
  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [localStart, setLocalStart] = useState(start);
  const [localEnd, setLocalEnd] = useState(end);

  useEffect(() => {
    if (prevProps.current.start !== start) {
      setLocalStart(start);
    }

    if (prevProps.current.end !== end) {
      setLocalEnd(end);
    }

    prevProps.current = {
      start,
      end,
    };
  }, [start, end]);

  const handleSlide = ({ min: cMin, max: cMax }: { min: number, max: number }) => {
    setLocalStart(cMin);
    setLocalEnd(cMax);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => onSlide([cMin, cMax]), 300);
  };

  const handleChangeInput = (value: string, type: string) => {
    const rawValue = value.replace(/[\s.,]/g, '');
    const numValue = Number(rawValue) || 0;

    if (type === VALUES_TYPES.MIN && numValue > end) {
      return handleSlide({ min: end, max: end });
    }

    if (type === VALUES_TYPES.MAX && numValue > end) {
      return handleSlide({ min: start, max: end });
    }

    return type === VALUES_TYPES.MIN
      ? handleSlide({ min: numValue, max: end })
      : handleSlide({ min: start, max: numValue });
  };

  const preparedMinValue = MoneyFormat.money(Math.floor(localStart));
  const preparedMaxValue = MoneyFormat.money(Math.ceil(localEnd));
  const currency = CURRENCY_SYMBOLS[currencyCode];

  return (
    <div className={ styles.wrapper }>
      <Slider
        className={ styles.slider }
        min={ min }
        max={ max }
        value={ { min: localStart, max: localEnd } }
        step={ step }
        onChange={ handleSlide }
        debounceMs={ INPUT_DEBOUNCE }
      />

      <div className={ styles.description }>
        <div className={ styles.input }>
          <Text
            type='NORMAL_14'
            className={ styles.text }
          >
            { LABELS.FROM }
          </Text>
          <Input
            value={ preparedMinValue }
            debounceMs={ INPUT_DEBOUNCE }
            className={ styles.side_padding_fix }
            subText={ currency }
            onChange={ (value) => handleChangeInput(value, VALUES_TYPES.MIN) }
          />
        </div>
        <div className={ styles.input }>
          <Text
            type='NORMAL_14'
            className={ styles.text }
          >
            { LABELS.TO }
          </Text>
          <Input
            value={ preparedMaxValue }
            debounceMs={ INPUT_DEBOUNCE }
            className={ styles.side_padding_fix }
            subText={ currency }
            onChange={ (value) => handleChangeInput(value, VALUES_TYPES.MAX) }
          />
        </div>
      </div>
    </div>
  );
};

export { PriceSlider };
