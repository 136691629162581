import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Button, Text } from 'yw-ui';

import { TFunction } from 'i18next';

import { useCart, useFreeRooms } from './hook';

import { GeneralInfoList } from './components/GeneralInfoList';

import { formatCurrency } from '@/app/bi/utils/money.ts';
import trimTimezone from '@/app/bi/utils/trimTimezone.ts';
import { prepareHotelPrice } from '@/app/bi/utils/hotel.ts';
import { countNights, dateUtcFormat } from '@/app/bi/utils/formatDate.ts';

import { PATTERN } from '@/app/bi/constants/dateFormats.ts';

import { SearchSettings } from '@/app/bi/models/hotelsTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import { IHotelSearchRateResponse } from '@/app/bi/models/hotelSearch/hotelSearchTypes';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  TEXT_TO_CART: t('hotels:hotelResult.item.rate.toCart'),
  TEXT_ADDED: t('hotels:hotelResult.item.rate.added'),
  ROOMS: t('hotels:hotelResult.item.rate.rooms'),
  LAST_ROOM: t('hotels:hotelResult.item.rate.lastRoom'),
  PRICE_DESCRIPTION: {
    ROOMS: (count: number) => t('hotels:hotelResult.item.rate.priceDescription.rooms', { count }),
    NIGHTS: (count: number) => t('hotels:hotelResult.item.rate.priceDescription.nights', { count }),
  },
});

interface RateProps {
  rate: IHotelSearchRateResponse;
  searchSettings: SearchSettings;
  currencyCode: ECurrencyCode;
  roomCount: number;
}

const Rate = ({
  rate,
  searchSettings,
  currencyCode,
  roomCount,
}: RateProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const {
    deadLine,
    refundable,
    meal,
    total,
  } = rate;

  const { count, onChange, items } = useFreeRooms(rate.freeRooms, roomCount);

  const handleSelect = (value: number) => onChange(value);
  const { onAddCart, onAnimationEnd, animatedClass } = useCart(searchSettings, rate, count);
  const newDeadLine = deadLine ? dateUtcFormat(trimTimezone(deadLine), PATTERN.DAY_OF_MONTH_TIME) : '';

  const newPrice = prepareHotelPrice(count as number, total);

  const [nights] = useState(
    () => countNights(searchSettings.checkout, searchSettings.checkin),
  );

  const renderAction = () => {
    const selectHtml = items.length > 1 && (
      <div className={ styles.rooms }>
        <Text type='NORMAL_14' color='gray-7'>{ LABELS.ROOMS }</Text>
        <Select
          theme='default-border'
          className={ styles.select }
          items={ items }
          value={ count }
          onChange={ handleSelect }
        />
      </div>
    );

    return (
      <div className={ styles.book }>
        <div className={ styles.book_content }>
          { selectHtml }
          <Button
            type='secondary'
            size='small'
            onClick={ onAddCart }
            disabled={ items.length === 1 }
            className={ styles.button }
          >
            { LABELS.TEXT_TO_CART }
          </Button>
        </div>
        { !selectHtml && <Text type='SEMIBOLD_14' color='red' >{ LABELS.LAST_ROOM }</Text> }
      </div>
    );
  };

  const actionHtml = renderAction();

  const priceHtml = () => {
    const isShow = !!count && !!nights;

    return (
      <>
        {isShow && (
          <Text className={ styles.addendums } type='NORMAL_12' color='gray-6'>
            {LABELS.PRICE_DESCRIPTION.ROOMS(count)} {LABELS.PRICE_DESCRIPTION.NIGHTS(nights)}
          </Text>
        )}
        <div className={ styles.total }>
          <Text type='bold_24'>
            {formatCurrency(Number(newPrice), currencyCode)}
          </Text>
        </div>
      </>
    );
  };

  return (
    <div className={ `${styles.wrapper} ${animatedClass}` } onAnimationEnd={ onAnimationEnd }>
      <div className={ styles.rate_info }>
        <GeneralInfoList refundable={ refundable } meal={ meal } date={ newDeadLine }/>
      </div>
      <div className={ styles.actions }>
        <div className={ styles.price }>
          {priceHtml()}
        </div>
        { actionHtml }
      </div>
    </div>
  );
};

export { Rate };
