import React, {
  useRef, useState, useEffect, ReactNode, useMemo,
} from 'react';
import { Slider, Text } from 'yw-ui';
import { useTranslation } from 'react-i18next';

import type { TFunction } from 'i18next';

import { secondsToLabel } from '../../bi/utils/time';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  from: t('components:timeSlider.from'),
  to: t('components:timeSlider.to'),
});

interface TimeSliderProps {
  children?: ReactNode | null,
  min: number,
  max: number,
  to: number,
  from: number,
  onSlide(value: { min: number; max: number }): void,
}

const TimeSlider = ({
  children = null,
  min,
  max,
  to,
  from,
  onSlide,
}: TimeSliderProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null >(null);

  const [localFrom, setLocalFrom] = useState(from);
  const [localTo, setLocalTo] = useState(to);

  useEffect(() => {
    setLocalFrom(from);
    setLocalTo(to);
  }, [to, from]);

  const handleSlide = (values: { min: number; max: number }) => {
    const { min: cMin, max: cMax } = values;

    setLocalFrom(cMin);
    setLocalTo(cMax);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => onSlide(values), 300);
  };

  const step = max - min >= 1800 ? 300 : 60;
  const minTime = secondsToLabel(localFrom);
  const maxTime = secondsToLabel(localTo);

  return (
    <div className={ styles.time_slide }>
      <div className={ styles.label }>
        { children }
      </div>
      <div className={ styles.wrap }>
        <Slider
          min={ min }
          max={ max }
          value={ { min: localFrom, max: localTo } }
          step={ step }
          debounceMs={ 0 }
          onChange={ handleSlide }
        />
        <Text type='NORMAL_12' color='gray' className={ styles.time }>
          { LABELS.from }
          {' '}
          { minTime }
          {' '}
          { LABELS.to }
          {' '}
          { maxTime }
        </Text>
      </div>
    </div>
  );
};

export { TimeSlider };
