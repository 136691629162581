import { SELECT_TRAVELLERS } from '../constants/hotelsSearch.ts';

const prepareHotelPrice = (count: number, totalPrice: number) => {
  const selectCount = count || 1;
  const price = `${selectCount * totalPrice}`;

  return price;
};

const roomCountFinder = (travellersCount: number, adult: number) =>
  SELECT_TRAVELLERS[travellersCount].find(({ value }) => value === adult)?.roomCount || 1;

export {
  prepareHotelPrice,
  roomCountFinder,
};
