import React from 'react';
import { Outlet } from 'react-router-dom';

import { getText } from '@/i18n';

import { Tabs } from '@/app/pages/Settings/components/Tabs';

import styles from './index.module.scss';

const LABELS = {
  TITLE: getText('settings:title'),
};

const SettingsPage = () => (
  <div className={ styles.wrapper }>
    <h1 className={ styles.titlePage }>{LABELS.TITLE}</h1>
    <div className={ styles.tabsContainer }>
      <Tabs />
    </div>
    <Outlet />
  </div>
);

export { SettingsPage };
