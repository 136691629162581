import React from 'react';

import logoCompany from '../../../svg/logo/yolwise.svg';

import { MainHeaderLogo } from './components/MainHeaderLogo';
import { LeftHeaderItems } from './components/LeftHeaderItems';
import { RightHeaderItems } from './components/RghtHeaderOtems';

import styles from './index.module.scss';

const MainHeader = () => (
  <div className={ styles.wrapper }>
    <div className={ styles.header }>
      <div className={ styles.wrap }>
        <MainHeaderLogo
          name='yolwise'
          img={ logoCompany }
        />
        <div className={ styles.menu }>
          <div className={ styles.links }>
            <LeftHeaderItems/>
            <RightHeaderItems/>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export { MainHeader };
