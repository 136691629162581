import React from 'react';
import { Icon, Price, Text } from 'yw-ui';

import { NEW_SERVICES_ICON } from '@/app/bi/constants/serviceType.ts';
import { CURRENCY_SYMBOLS } from '@/app/bi/constants/travelPolicy.ts';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from '@/app/pages/SuccessBooking/index.module.scss';

interface IHeaderCollapseProps {
  tripName: string | null;
  amount: number;
  serviceTypes: EServiceTypes[];
  currency: ECurrencyCode;
}

export const HeaderCollapse = ({
  tripName,
  amount,
  serviceTypes,
  currency,
}:IHeaderCollapseProps) => {
  if (!currency) {
    return null;
  }

  return (
    <>
      <div className={ styles.title } >
        <Text type='bold_22'>{ tripName }</Text>
        <div className={ styles.icons } >
          {serviceTypes.map((serviceType) => (
            <Icon key={ serviceType } type={ NEW_SERVICES_ICON[serviceType] } circleColor='white'/>
          ))}
        </div>
      </div>
      <div className={ styles.actions }>
        <Price
          value={ amount }
          type='bold_22'
          typeCurrency='bold_22'
          costType={ CURRENCY_SYMBOLS[currency] }
          marginSmall
        />
      </div>
    </>
  );
};
