import React, { useState } from 'react';
import { Button, Checkbox, Collapse, Input } from 'yw-ui';
import { getText } from '@/i18n';

import { formationObjectFilterEmployee } from '@/app/bi/utils/trip.ts';

import { FILTER_DEFAULT_COUNT_ITEMS } from '@/app/bi/constants/common.ts';

import { IEmployee } from '@/app/bi/models/cart.ts';

import styles from './index.module.scss';

const LABELS = {
  OPEN_MORE: getText('trips:openMore'),
  HIDDEN_MORE: getText('trips:hiddenMore'),
};

interface FilterEmployeeItemProps {
  title: string;
  employeeAutocomplete: IEmployee[];
  employeesValues: IEmployee[];
  inputValue: string;
  placeholder: string;
  onChange(employee: IEmployee): void;
  onChangeInput(value: string): void;
}

const FilterEmployeeItem = ({
  title,
  employeeAutocomplete,
  employeesValues,
  inputValue,
  placeholder,
  onChange,
  onChangeInput,
}: FilterEmployeeItemProps) => {
  const [
    openList,
    setOpenList,
  ] = useState<boolean>(false);

  const isActiveButton = employeeAutocomplete.length > FILTER_DEFAULT_COUNT_ITEMS;

  const handleOpenList = () => setOpenList(!openList);

  const renderItem = (
    employee: IEmployee,
    listLength: number,
    index: number,
    checked: boolean,
  ) => index < listLength && (
    <Checkbox
      className={ styles.checkbox }
      key={ employee.id }
      value={ checked }
      onChange={ () => onChange(employee) }
      themes='dark'
    >
      {formationObjectFilterEmployee({
        firstName: employee.actualVersion.firstName,
        lastName: employee.actualVersion.lastName,
      })}
    </Checkbox>
  );

  const renderItems = () => {
    const uniqueEmployees = new Map();

    employeesValues.forEach((employee) => uniqueEmployees.set(employee.id, employee));

    employeeAutocomplete.forEach((employee) => {
      if (!uniqueEmployees.has(employee.id)) {
        uniqueEmployees.set(employee.id, employee);
      }
    });

    return [...uniqueEmployees.values()].map((employee, index) => {
      const checked = employeesValues.includes(employee);

      return renderItem(employee, uniqueEmployees.size, index, checked);
    });
  };

  const renderButtonMore = () => isActiveButton && (
    <Button type='text' className={ styles.button_more } onClick={ handleOpenList }>
      {openList ? LABELS.HIDDEN_MORE : LABELS.OPEN_MORE}
    </Button>
  );

  return (
    <Collapse label={ title } moreOpen={ openList } className={ styles.container } >
      <div className={ styles.search_container }>
        <Input
          isCleansing
          className={ styles.search }
          value={ inputValue }
          placeholder={ placeholder }
          onChange={ onChangeInput }
        />
      </div>
      {renderItems()}
      {renderButtonMore()}
    </Collapse>
  );
};

export { FilterEmployeeItem };
