import React from 'react';
import { Checkbox, Collapse } from 'yw-ui';

import { STATUS_NAME_ARRAY } from '@/app/bi/constants/trips.ts';

import { ETripStatusFilter } from '@/app/bi/models/tripsTypes.ts';

import styles from './index.module.scss';

interface FilterStatusItemProps {
  title: string;
  data: ETripStatusFilter[]
  tripStatusesValues: ETripStatusFilter[],
  onChange(array: string[]): void,
}

const FilterStatusItem = ({
  data,
  tripStatusesValues,
  title,
  onChange,
}: FilterStatusItemProps) => {
  const handleChange = (value: ETripStatusFilter) => {
    const valuesSet = new Set(tripStatusesValues);

    if (valuesSet.has(value)) {
      valuesSet.delete(value);
    } else {
      valuesSet.add(value);
    }

    onChange([...valuesSet]);
  };

  const renderArrayItems = () => data.map((item, index) => {
    const checked = tripStatusesValues.includes(ETripStatusFilter[item]);

    return (
      <Checkbox
        className={ styles.checkbox }
        key={ `checkbox_count__${item}_${index}` }
        value={ checked }
        onChange={ () => handleChange(item) }
        themes='dark'
      >
        {STATUS_NAME_ARRAY[item]}
      </Checkbox>
    );
  });

  return (
    <Collapse label={ title }>
      {renderArrayItems()}
    </Collapse>
  );
};

export { FilterStatusItem };
