import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Input,
  LinkButton,
  Text,
} from 'yw-ui';

import { getText } from '@/i18n';

import { useLoginMutation } from '@/app/bi/api/authApi.ts';
import { authSlice } from '@/app/store/redusers/AuthSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';

import { LoginDialogWrapper } from '../../components/LoginDialogWrapper';

import { getValidationLoginForm } from '@/app/bi/utils/login.ts';

import ROUTES from '@/app/bi/constants/routes.ts';

import styles from './index.module.scss';

const LABELS = {
  ENTRY: getText('auth:entry'),
  EMAIL: getText('auth:email'),
  PASSWORD: getText('auth:password'),
  SING_IN: getText('auth:signIn'),
  FORGOT_PASSWORD: getText('auth:forgotPassword'),
  REQUEST_EMPTY_ERROR: getText('auth:requestEmptyError'),
};

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accessToken } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (accessToken) navigate(ROUTES.HOMEPAGE);
  }, []);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [validation, setValidation] = useState({
    email: '',
    password: '',
  });

  const [login, { isLoading }] = useLoginMutation();

  const passwordType = showPassword ? 'type' : 'password';

  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleChangeUsername = (value: string) => {
    const email = value.replace(/\s/g, '');
    setUsername(email);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const {
      validation: validationObj,
      isValid,
    } = getValidationLoginForm(username, password);
    const { setCredentials } = authSlice.actions;

    setValidation(validationObj);

    if (isValid) {
      try {
        const res = await login({
          email: username,
          password,
        }).unwrap();

        dispatch(setCredentials(res.token));

        navigate(ROUTES.HOMEPAGE);
      } catch (err) {
        setValidation({
          ...validationObj,
          password: LABELS.REQUEST_EMPTY_ERROR,
        });
      }
    }
  };

  const renderShowPassword = () => {
    const classes = `${styles.eyes} ${showPassword ? styles.showEyes : ''}`;

    return (<span onClick={ handleShowPassword } className={ classes } />);
  };

  const showPasswordHtml = renderShowPassword();

  const errorEmailHtml = validation.email && (
    <Text type='NORMAL_12' className={ styles.error_message }>
      {validation.email}
    </Text>
  );

  const errorPasswordHtml = validation.password && (
    <Text type='NORMAL_12' className={ styles.error_message }>
      {validation.password}
    </Text>
  );

  const resetValidation = (field: string) => () => {
    setValidation({
      ...validation,
      [field]: '',
    });
  };

  const goToRestorePassword = () => navigate('/restore-password');

  const emailErrorClass = validation.email ? styles.input_not_valid : '';
  const passwordErrorClass = validation.password ? styles.input_not_valid : '';

  return (
    <LoginDialogWrapper title={ LABELS.ENTRY }>
      <form
        className={ styles.form }
        onSubmit={ handleSubmit }
      >
        <div className={ styles.row }>
          <div className={ styles.group }>
            <Text className={ styles.label } type='NORMAL_14'>
              { LABELS.EMAIL }
            </Text>
            <Input
              type='email'
              className={ emailErrorClass }
              value={ username }
              placeholder={ LABELS.EMAIL }
              onChange={ (value) => handleChangeUsername(value) }
              onFocus={ resetValidation('email') }
            />
            {errorEmailHtml}
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.group }>
            <Text className={ styles.label } type='NORMAL_14'>
              { LABELS.PASSWORD }
            </Text>
            <div className={ styles.password_group }>
              <Input
                type={ passwordType }
                value={ password }
                placeholder={ LABELS.PASSWORD }
                onChange={ (value) => setPassword(value) }
                onFocus={ resetValidation('password') }
                className={ passwordErrorClass }
              />
              {showPasswordHtml}
              {errorPasswordHtml}
            </div>
          </div>
        </div>
        <div className={ styles.row }>
          <Button
            qaAttr='sign_in'
            className={ styles.submit }
            formType='submit'
            type='primary'
            loading={ isLoading }
            disabled={ isLoading }
          >
            { LABELS.SING_IN }
          </Button>
          <div className={ styles.restore_password }>
            <LinkButton color='light-blue-4' type='NORMAL_14' onClick={ goToRestorePassword } >
              { LABELS.FORGOT_PASSWORD }
            </LinkButton>
          </div>
        </div>
      </form>
    </LoginDialogWrapper>
  );
};

export { LoginPage };
