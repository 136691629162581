import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Rating, Text } from 'yw-ui';
import { useTranslation } from 'react-i18next';

import type { TFunction } from 'i18next';

import toDecline from '@/app/bi/utils/toDecline.ts';
import { formatCurrency } from '@/app/bi/utils/money.ts';
import { calculatePriceValue } from '@/app/bi/utils/price.ts';

import { RATING_TYPES } from '@/app/bi/constants/hotels.ts';

import { ECurrencyCode, ERatingType } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import { IRegionSearchResponseRate } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './styles.module.scss';

const createLabels = (t: TFunction) => ({
  DAYS_DECLINE: t('hotels:regionResult.item.daysDecline'),
  ROOM_DECLINE: t('hotels:regionResult.item.roomDecline'),
  ON: t('hotels:regionResult.item.on'),
  PER: t('hotels:regionResult.item.per'),
  CHOOSE_ROOM: t('hotels:regionResult.item.chooseRoom'),
});

interface IHotelInfoActionProps {
  rating: number;
  diffDays: number;
  hotelUrl: string;
  roomCount: number;
  ratingType: ERatingType;
  travellersCount: number;
  currencyCode: ECurrencyCode;
  rate: IRegionSearchResponseRate;
}

export const HotelInfoAction = ({
  rate,
  rating,
  diffDays,
  hotelUrl,
  roomCount,
  ratingType,
  currencyCode,
  travellersCount,
}: IHotelInfoActionProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const currencyPrice = rate.prices.find(({ currency }) => currency === currencyCode);

  const renderRating = () => {
    if (!rating) return null;

    const ratingText = RATING_TYPES[ratingType] || '';

    return (
      <div className={ styles.rating }>
        <Rating text={ ratingText } fullSize number={ rating }/>
      </div>
    );
  };

  const renderPrice = () => {
    const calculatePrice = calculatePriceValue(currencyPrice?.total as number, travellersCount, roomCount);

    return formatCurrency(calculatePrice, currencyCode as ECurrencyCode);
  };

  const renderDaysAmount = () => {
    if (diffDays === 1 && roomCount < 1) return null;

    const textDays = toDecline(diffDays, LABELS.DAYS_DECLINE);
    const textRoomCount = toDecline(roomCount, LABELS.ROOM_DECLINE);

    return (
      <Text type='NORMAL_12' color='gray-6'>
        {LABELS.PER} {roomCount} {textRoomCount} {LABELS.ON} {diffDays} {textDays}
      </Text>
    );
  };

  return (
    <div className={ styles.right_block }>
      {renderRating()}
      <div className={ styles.price_wrapper }>
        <div className={ styles.price }>
          <div className={ styles.days_amount }>
            {renderDaysAmount()}
          </div>
          <div className={ styles.price_amount }>
            <Text type='bold_24' color='default'>
              {renderPrice()}
            </Text>
          </div>
        </div>
        <Link to={ hotelUrl } target='_blank'>
          <Button type='secondary' className={ styles.button_choose_rom }>
            {LABELS.CHOOSE_ROOM}
          </Button>
        </Link>
      </div>
    </div>
  );
};
