import React, { ReactNode } from 'react';
import { Text } from 'yw-ui';

import styles from './index.module.scss';

interface WrapperPoliciesProps {
  name: string;
  children: ReactNode;
}

export const WrapperPolicies = ({
  name,
  children,
}: WrapperPoliciesProps) => (
  <div className={ styles.wrap }>
    <Text type='bold_22' color='gray-7' className={ styles.name }>
      {name}
    </Text>
    {children}
    {/* <div className={ styles.exception }> */}
    {/*  <Button type='text' className={ styles.button_exception } onClick={ onClickException }> */}
    {/*    {LABELS.BUTTON_EXCEPTION} */}
    {/*  </Button> */}
    {/*  <Text type='NORMAL_14' color='gray-7'> */}
    {/*    {textException} */}
    {/*  </Text> */}
    {/* </div> */}
  </div>
);
