import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Select, Text } from 'yw-ui';

import { TFunction } from 'i18next';

import { useFilterHotels, useFilters } from './hook';

import { CURRENCY_CODE_ITEMS } from '@/i18n/locales/consts';

import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  ONLINE: t('hotels:regionResult.filters.onlyOnline'),
  BREAKFAST: t('hotels:regionResult.filters.mealIncluded'),
  HAS_CANCELLATION: t('hotels:regionResult.filters.freeCancellation'),
});

export interface FiltersProps {
  currencyCode: ECurrencyCode,
  updateCurrencyCode(value: ECurrencyCode): void;
}

const Filters = ({ updateCurrencyCode, currencyCode }: FiltersProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const { breakfastEnabled, onlyOnline, refundable, ...handlers } = useFilters();

  useFilterHotels({ breakfastEnabled, onlyOnline, refundable });

  const renderOnline = () => (
    <div className={ styles.block }>
      <Checkbox value={ onlyOnline } onChange={ handlers.toggleOnlyOnline }>
        <Text type='NORMAL_14'>
          { LABELS.ONLINE }
        </Text>
      </Checkbox>
    </div>
  );

  const renderBreakfast = () => (
    <div className={ styles.block }>
      <Checkbox value={ breakfastEnabled } onChange={ handlers.toggleBreakfastEnabled }>
        <Text type='NORMAL_14'>
          {LABELS.BREAKFAST}
        </Text>
      </Checkbox>
    </div>
  );

  const renderRefundability = () => (
    <div className={ styles.block }>
      <Checkbox value={ refundable } onChange={ handlers.toggleRefundable }>
        <Text type='NORMAL_14_130'>
          {LABELS.HAS_CANCELLATION}
        </Text>
      </Checkbox>
    </div>
  );

  return (
    <div className={ styles.wrapper }>
      {renderOnline()}
      {renderBreakfast()}
      {renderRefundability()}
      <div>
        <Select
          theme='default-border'
          items={ CURRENCY_CODE_ITEMS }
          value={ currencyCode }
          onChange={ updateCurrencyCode }
        />
      </div>
    </div>
  );
};

export default Filters;
