import React from 'react';
import { Text } from 'yw-ui';

import { getText } from '@/i18n';

import { AirItem } from '@/app/components/AirItem';

import { IAirlineRoute } from '@/app/bi/models/cart.ts';
import { EAirlineClass } from '@/app/bi/models/airlineTypes';

import styles from './index.module.scss';

const LABELS = {
  FLIGHT_DETAILS: getText('components:airlineSidePanel.title'),
  RATE: getText('components:airlineSidePanel.rate'),
  [EAirlineClass.Comfort]: getText('constants:airline.flyClass.comfort'),
  [EAirlineClass.Econom]: getText('constants:airline.flyClass.econom'),
  [EAirlineClass.Business]: getText('constants:airline.flyClass.business'),
  [EAirlineClass.First]: getText('constants:airline.flyClass.first'),

};

interface AirlineSidePanelProps {
  routes: IAirlineRoute[];
  airlineClass: EAirlineClass;
  rulesList: string[];
}

const SidePanel = ({ routes, rulesList, airlineClass }: AirlineSidePanelProps) => {
  const renderAirItem = () => routes.map((route) => (
    <div key={ route.id } className={ styles.air_item }>
      <AirItem
        route={ route }
        duration={ route.totalDuration }
      />
      {renderDetails()}
    </div>
  ));

  const renderDetails = () => (
    <div className={ styles.details_list } >
      <Text type='NORMAL_14' fontWeight={ 600 } line-height={ '105%' }>
        {LABELS.RATE}&nbsp; {LABELS[airlineClass]}
      </Text>
      <div className={ styles.details_content } >
        <div className={ styles.column }>
          {rulesList.map((detail, index) => (
            <Text key={ index } className={ styles.row } type='NORMAL_14'>
              {detail}
            </Text>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className={ styles.details_wrapper }>
      <div className={ styles.header }>
        <Text type='bold_24'>{LABELS.FLIGHT_DETAILS}</Text>
      </div>
      <div className={ styles.content } >
        {renderAirItem()}
      </div>
    </div>
  );
};

export default SidePanel;
