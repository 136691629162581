import React, { useMemo } from 'react';
import { Button, LinkButton, PageLoader, Text } from 'yw-ui';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { TFunction } from 'i18next';

import { useGetBookingByIdQuery } from '@/app/bi/api/bookingApi.ts';

import { CardHotel } from '@/app/pages/TripsDetail/components/Cards/CardHotel';
import { CardAirLine } from '@/app/pages/TripsDetail/components/Cards/CardAirLine';
import { ItemPanelCollapse } from '@/app/pages/Checkout/components/ItemPanelCollapse';
import { HeaderCollapse } from '@/app/pages/SuccessBooking/components/HeaderCollapse';

import { getServiceTypes } from '@/app/bi/utils/cart.ts';

import ROUTES from '@/app/bi/constants/routes.ts';

import { IAirlineTripItems, IHotelTripItems } from '@/app/bi/models/tripsTypes.ts';

// import {
//   useDownloadMutation,
//   useLazyGetTripAirlineIdQuery,
//   useSendEmailAirlineMutation,
// } from '@/app/bi/api/documentApi.ts';
// import { EDocumentType, EPrefixFileName } from '@/app/bi/models/documents.ts';
// import { useAppSelector } from '@/app/store/hooks/redux.ts';

import styles from './index.module.scss';

const createLabels = (t: TFunction) => ({
  TITLE: t('checkout:successBooking.tripsPayed'),
  BACK_TO_MAIN_PAGE: t('checkout:successBooking.backToMainPage'),
  GO_TO_TRIPS: t('checkout:successBooking.backToTripsPage'),
  SEND_TO_EMAIL: t('checkout:successBooking.sendToEmail'),
  DOWNLOAD: t('checkout:successBooking.download'),
});

const SuccessBooking = () => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const navigate = useNavigate();
  const { bookId } = useParams();
  // const locale = useAppSelector((state) => state.appSlice.locale);
  //
  // const [showModal, setShowModal] = useState(false);
  // const [selectId, setSelectId] = useState('');

  const {
    data: trips = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetBookingByIdQuery(bookId as string);

  // const [sendEmail, { isLoading: isLoadingEmail }] =
  //   useSendEmailAirlineMutation();
  // const [downloadDocument] =
  //   useDownloadMutation();
  // const [getTripDocumentId] = useLazyGetTripAirlineIdQuery();

  if (isLoading && !isSuccess) return <PageLoader />;

  if (isError) return <Navigate to='/cart' />;

  const goToMainPage = () => navigate(ROUTES.HOMEPAGE);
  const goToTripsPage = () => navigate(ROUTES.TRIPS.MAIN);

  const renderActionsPanel = () => (
    <div className={ styles.action_panel }>
      <div className={ styles.content }>
        <div className={ styles.actions }>
          <Button
            type='secondary'
            size='small'
            onClick={ goToMainPage }
          >
            { LABELS.BACK_TO_MAIN_PAGE }
          </Button>
          <LinkButton
            theme='blue-without-border'
            onClick={ goToTripsPage }
          >
            <Text color='light-blue-4' type='NORMAL_14'>
              { LABELS.GO_TO_TRIPS }
            </Text>
          </LinkButton>
        </div>
      </div>
    </div>
  );

  // TODO Вернуть и привязать запрос на груповое скачивание (задача запланирована)
  // const handleCloseDialog = () => {
  //   setShowModal(false);
  // };

  // const handleOpenDialog = (id: string) => {
  //   setSelectId(id);
  //   setShowModal(!showModal);
  // };

  // const handleSendEmail = (email: string) => {
  //   const body = {
  //     itemId: selectId,
  //     email,
  //     locale,
  //     fileType: EDocumentType.Pdf,
  //   };
  //
  //   sendEmail(body);
  // };

  // const handleDownload = async (id: string) => {
  //   const documentId = await getTripDocumentId({
  //     itemId: id,
  //     locale,
  //     fileType: EDocumentType.Pdf,
  //   }).unwrap();
  //
  //   const responseFile = await downloadDocument(documentId).unwrap();
  //
  //   await downloadFile({
  //     fileBlob: responseFile,
  //     fileType: EDocumentType.Pdf,
  //     filenamePrefix: EPrefixFileName.Trip,
  //     identifier: 'downloadFile',
  //   });
  // };

  // const renderHeaderActions = (id: string) => (
  //   <div className={ styles.actions }>
  //     <Button
  //       type='text'
  //       onClick={ () => handleOpenDialog(id) }
  //       className={ styles.button_header }
  //     >
  //       {LABELS.SEND_TO_EMAIL}
  //     </Button>
  //
  //     <Button
  //       type='text'
  //       className={ styles.button_download }
  //       onClick={ () => handleDownload(id) }
  //     >
  //       {LABELS.DOWNLOAD}
  //       {/* {isResponseId ? ( */}
  //       {/*   <div className={ styles.loading_container }><ItemLoader /></div> */}
  //       {/* ) : LABELS.DOWNLOAD} */}
  //     </Button>
  //   </div>
  // );

  // const renderDialog = () => (
  //   <SendToEmailDialog
  //     show={ showModal }
  //     isLoading={ isLoadingEmail }
  //     onClose={ handleCloseDialog }
  //     onSend={ handleSendEmail }
  //   />
  // );

  const renderAirlineTrip = (airlineTripItems: IAirlineTripItems[]) => airlineTripItems?.map((item) => (
    <CardAirLine key={ item.id } data={ item } />
  ),
    // (
    //   <CheckoutItem
    //     index={ index }
    //     key={ actualVersion.id }
    //     // @ts-ignore
    //     item={ actualVersion }
    //     serviceType={ EServiceTypes.Airline }
    //   />
    // ),
  );

  const renderHotelTrip = (hotelTripItems: IHotelTripItems[]) => hotelTripItems?.map((item) => (
    <CardHotel key={ item.id } data={ item } isCard />
  ));

  const renderTrips = () => trips.map(({
    id,
    name,
    airlineTripItems,
    hotelTripItems,
    amount,
    currency,
  }) => {
    const serviceTypes = getServiceTypes(airlineTripItems, hotelTripItems);

    return (
      <ItemPanelCollapse
        key={ id }
        renderHeader={ () => (
          <HeaderCollapse
            tripName={ name }
            amount={ amount }
            serviceTypes={ serviceTypes }
            currency={ currency }
          />
        ) }
        // renderActions={ () => renderHeaderActions(id) }
      >
        {renderAirlineTrip(airlineTripItems)}
        {renderHotelTrip(hotelTripItems)}
      </ItemPanelCollapse>
    );
  });

  const renderContent = () => (
    <>
      <div className={ styles.header }>
        <Text type='bold_32' fontWeight={ 700 } lineHeight={ '110%' }>
          {LABELS.TITLE}
        </Text>
      </div>
      <div className={ styles.content }>
        <div className={ styles.items_wrapper }>
          { renderTrips()}
        </div>
      </div>
    </>
  );

  return (
    <div className={ styles.wrapper }>
      { renderContent() }
      { renderActionsPanel() }
      {/* { renderDialog() } */}
    </div>
  );
};

export { SuccessBooking };
