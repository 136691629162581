import React from 'react';
import { Link } from 'react-router-dom';

import ROUTES from '../../../../bi/constants/routes.ts';

import styles from './index.module.scss';

interface MainHeaderLogoProps {
  img: string;
  name: string;
}

const MainHeaderLogo = ({
  img,
  name,
}: MainHeaderLogoProps) => (
  <div className={ styles.logo_container }>
    <Link to={ ROUTES.HOMEPAGE }>
      <img
        src={ img }
        className={ styles.logo }
        alt={ name }
        title={ name }
      />
    </Link>
  </div>
);

export { MainHeaderLogo };
