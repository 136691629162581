import React, { ReactNode } from 'react';
import { Dialog } from 'yw-ui';

import styles from './index.module.scss';

interface MapDialogProps {
  children: ReactNode,
  show: boolean,
  onChange(value: boolean): void,
  renderDependsOnShow?: boolean,
}

const MapDialog = ({
  show,
  children,
  onChange,
  renderDependsOnShow = false,
}: MapDialogProps) => {
  const content = renderDependsOnShow ? show && children : children;

  return (
    <Dialog
      show={ show }
      onChange={ onChange }
      className={ styles.wrapper }
    >
      { content }
    </Dialog>
  );
};

export { MapDialog };
