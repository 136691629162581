import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

enum ELanguages {
  Tr = 'Tr',
  En = 'En',
  Ru = 'Ru',
}

const commonLanguageItems = [
  { label: ELanguages.Tr.toUpperCase(), value: ELanguages.Tr },
  { label: ELanguages.En.toUpperCase(), value: ELanguages.En },
];

const devLanguagesItems = [
  ...commonLanguageItems,
  { label: ELanguages.Ru.toUpperCase(), value: ELanguages.Ru },
];

const prodLanguagesItems = [
  ...commonLanguageItems,
];

const languagesItems = [
  ...(process.env.NODE_ENV === 'production' ? prodLanguagesItems : devLanguagesItems),
];

const CURRENCY_CODE_ITEMS = [
  { label: `${ECurrencyCode.USD} ($)`, value: ECurrencyCode.USD },
  { label: `${ECurrencyCode.TRY} (₺)`, value: ECurrencyCode.TRY },
  { label: `${ECurrencyCode.EUR} (€)`, value: ECurrencyCode.EUR },
];

const storageKeys = {
  userLanguage: 'userLanguage',
};

const namespaces = {
  common: 'common',
  components: 'components',
  booking: 'booking',
  constants: 'constants',
  hotels: 'hotels',
  air: 'air',
  auth: 'auth',
  reports: 'reports',
  cart: 'cart',
  checkout: 'checkout',
  settings: 'settings',
  trip: 'trip',
  trips: 'trips',
  utils: 'utils',
  homePage: 'homePage',
  services: 'services',
} as const;

export {
  namespaces,
  storageKeys,
  languagesItems,
  ELanguages,
  CURRENCY_CODE_ITEMS,
};
