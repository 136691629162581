import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import queryString from 'query-string';
import { PageLoader } from 'yw-ui';
import { useTranslation } from 'react-i18next';

import { DATE_FORMATS } from 'yw-ui/src/constants/formatDate.ts';
import { TFunction } from 'i18next';
import { currentLng } from '@/i18n';

import {
  useLazyGetSearchRegionBySearchIdQuery,
  useLazySearchRegionQuery,
  useSearchRegionBySearchIdQuery,
} from '@/app/bi/api/searchHotelsApi.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import { searchHotelsSlice } from '@/app/store/redusers/SearchHotelsSlice.ts';
import { useGetTravelPoliciesQuery } from '@/app/bi/api/travelPoliciesApi.ts';
import { hotelsSlice } from '@/app/store/redusers/HotelsSlice.ts';

import { HotelSearchMenu } from '../../components/Menu/HotelSearchMenu';
import { HotelResultWrap } from '@/app/pages/HotelsResultPage/components/HotelResultWrap';

import { formatDate } from '@/app/bi/utils/formatDate.ts';
import { mapStateToHotelPageSearchSettings } from '@/app/bi/utils/hotelsSearch.ts';
import { roomCountFinder } from '@/app/bi/utils/hotel.ts';

import { PATTERN } from '@/app/bi/constants/dateFormats.ts';
import ROUTES from '@/app/bi/constants/routes.ts';

import { ITag } from '@/app/bi/models/shared.ts';
import { IHotelSearchFilter, IHotelSearchRegionRequest } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

const createLabels = (t: TFunction) => ({
  LOADING_MESSAGE: t('common:loadingMessage'),
});

const HotelsResultPage = () => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const navigate = useNavigate();
  const { guid } = useParams();
  const [loading, setLoading] = useState(true);

  const {
    travellersCount,
    adult,
    checkin,
    checkout,
    customCheckin,
    customCheckout,
    region,
  } = useAppSelector((state) => state.searchHotelsReducer);

  const {
    searchId,
    filters,
    isEditFilter,
  } = useAppSelector((state) => state.hotelsSlice);
  const currency = useAppSelector((state) => state.appSlice).headers.balance.currency;

  const [
    selectPolicy,
    setSelectPolicy,
  ] = useState({
    id: '',
    policyName: '',
    isActive: true,
  });

  const { data: listTravelPolicies = [] } = useGetTravelPoliciesQuery();

  const dispatch = useAppDispatch();
  const { resetStore: resetSearchStore } = searchHotelsSlice.actions;
  const {
    resetStore: resetHotelsStore,
    updateFilters,
    resetFilters,
    updateIsEditFilter,
    deleteTag,
  } = hotelsSlice.actions;

  const resetStore = () => {
    dispatch(resetSearchStore());
    dispatch(resetHotelsStore());
  };

  useEffect(() => {
    if (!guid && !region?.selected?.id) {
      return navigate(ROUTES.SEARCH.HOTEL);
    }

    if (!guid) {
      initSearch();
    } else {
      initSearchBySearchId();
    }

    return () => { resetStore(); };
  }, []);

  const handleUpdateFilters = async (valueFilter: IHotelSearchFilter) => {
    dispatch(updateFilters({ ...valueFilter, page: 1 }));
  };

  const handleChangePage = async (page: number) => {
    dispatch(updateFilters({ ...filters, page } as IHotelSearchFilter));
  };

  const handleResetFilters = async () => dispatch(resetFilters(currency));

  const handleDeleteTag = (tag: ITag) => dispatch(deleteTag(tag));

  const handleSearchSubMenu = () => {
    if (region?.selected?.isRegion) {
      dispatch(updateIsEditFilter(false));

      return initSearch();
    }

    const searchParams = mapStateToHotelPageSearchSettings({
      checkin,
      checkout,
      adult,
      regionName: region?.selected?.name as string,
      regionId: region?.selected?.id as string,
      customCheckin,
      customCheckout,
      travellersCount,
    });

    return navigate({
      pathname: `${ROUTES.SEARCH.HOTEL_PAGE}/${region?.selected?.id}`,
      search: `${queryString.stringify({ ...searchParams })}`,
    });
  };

  const [search] = useLazySearchRegionQuery();
  const [searchRegionBySearchId] = useLazyGetSearchRegionBySearchIdQuery();

  const filterBody = {
    searchId,
    locale: currentLng,
    body: filters as IHotelSearchFilter,
  };

  useSearchRegionBySearchIdQuery(filterBody, { skip: !filters || !isEditFilter });

  const initSearch = async () => {
    const body: IHotelSearchRegionRequest = {
      checkInDate: formatDate(checkin as Dayjs, PATTERN.YEAR_MONTH_DAY),
      checkOutDate: formatDate(checkout as Dayjs, PATTERN.YEAR_MONTH_DAY),
      checkInTime: customCheckin ? formatDate(customCheckin, DATE_FORMATS.TIME) : null,
      checkOutTime: customCheckout ? formatDate(customCheckout, DATE_FORMATS.TIME) : null,
      guests: travellersCount,
      roomCount: roomCountFinder(travellersCount, adult),
      locale: currentLng,
      regionId: region?.selected?.id as string,
    };

    setLoading(true);

    try {
      await search(body).unwrap();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const initSearchBySearchId = async () => {
    const body = { searchId: guid as string, locale: currentLng };

    setLoading(true);

    try {
      await searchRegionBySearchId(body).unwrap();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const renderLoading = () => <PageLoader text={ LABELS.LOADING_MESSAGE }/>;

  const renderContent = () => {
    if (loading) return renderLoading();

    return (
      <HotelResultWrap
        listTravelPolicies={ listTravelPolicies }
        setIsSelectPolicy={ setSelectPolicy }
        selectPolicy={ selectPolicy }
        onUpdateFilters={ handleUpdateFilters }
        onChangePage={ handleChangePage }
        onResetFilters={ handleResetFilters }
        onDeleteTag={ handleDeleteTag }
      />
    );
  };

  return (
    <>
      <HotelSearchMenu subMenu onSearchSubMenu={ handleSearchSubMenu }/>
      {renderContent()}
    </>
  );
};

export { HotelsResultPage };
