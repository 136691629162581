import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { IconButton } from 'yw-ui';

import styles from './index.module.scss';

interface ShowOnMapButtonProps {
  onClick(): void,
}

const createLabels = (t: TFunction) => ({
  TITLE: t('components:showOnMapButton.title'),
});

const ShowOnMapButton = ({
  onClick,
}: ShowOnMapButtonProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  return (
    <div
      className={ styles.wrapper }
      onClick={ onClick }
    >
      <IconButton iconType='location' iconColor='LB4' size={ 18 } textColor='light-blue-4'>
        { LABELS.TITLE }
      </IconButton>
    </div>
  );
};

export { ShowOnMapButton };
